import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../../../environments/environment';

@Injectable()
export class VersionCheckService {
  constructor(private http: HttpClient) {}

  /**
   * Checks in every set frequency the version of frontend application
   * @param url
   * @param {number} frequency - in milliseconds, defaults to 5 minutes
   */
  public initVersionCheck(url: any, frequency = 1000 * 60 * 5) {
    setInterval(() => {
      console.log('Version Check');
      this.checkVersion(url);
    }, frequency);
  }

  /**
   * Will do the call and check if the hash has changed or not
   * @param url
   */
  private checkVersion(url: any) {
    if (environment.version != 'local') {
      //timestamp these requests to invalidate caches
      this.http.get(url + '?t=' + new Date().getTime()).subscribe(
        (response: any) => {
          const version = response.version;
          const hasVersionChanged = this.hasVersionChanged(
            environment.version,
            version
          );

          // If new version, do something
          console.log(hasVersionChanged);
          if (hasVersionChanged) {
            // ENTER YOUR CODE TO DO SOMETHING UPON VERSION CHANGE
            //location.reload();
          }
          // store the new hash so we wouldn't trigger versionChange again
          // only necessary in case you did not force refresh
          environment.version = version;
        },
        (err) => {
          console.error(err, 'Could not get version');
        }
      );
    }
    console.log(url);
  }

  private hasVersionChanged(currentVersion: any, newVersion: any) {
    console.log(currentVersion);
    if (!currentVersion || currentVersion == 'local') {
      return false;
    }

    return currentVersion !== newVersion;
  }
}
