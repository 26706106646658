import { Component, Input } from "@angular/core";

@Component({
  selector: "app-labor-icons",
  templateUrl: "./labor-icons.component.html",
  styleUrls: ["./labor-icons.component.scss"],
})
export class LaborIconsComponent {
  @Input() public icon: string = "";
}
