// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.lightbox {
  position: fixed;
  z-index: 9999;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.8);
  color: white;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}

.lightbox-content {
  width: auto;
  padding: 20px;
  text-align: center;
  max-width: 800px;
}

.lightbox-content img {
  max-width: 100%;
  max-height: 100%;
}

.close-btn {
  position: absolute;
  top: 5%;
  right: 5%;
  transform: translate(-70%, -22%);
  background: none;
  border: none;
  color: white;
  font-size: 24px;
  cursor: pointer;
}`, "",{"version":3,"sources":["webpack://./src/app/modules/shared/image-lightbox/image-lightbox.component.scss"],"names":[],"mappings":"AAAA;EACE,eAAA;EACA,aAAA;EACA,MAAA;EACA,OAAA;EACA,WAAA;EACA,YAAA;EACA,oCAAA;EACA,YAAA;EACA,aAAA;EACA,mBAAA;EACA,uBAAA;EACA,eAAA;AACF;;AAEA;EACE,WAAA;EACA,aAAA;EACA,kBAAA;EACA,gBAAA;AACF;;AAEA;EACE,eAAA;EACA,gBAAA;AACF;;AAEA;EACE,kBAAA;EACA,OAAA;EACA,SAAA;EACA,gCAAA;EACA,gBAAA;EACA,YAAA;EACA,YAAA;EACA,eAAA;EACA,eAAA;AACF","sourcesContent":[".lightbox {\n  position: fixed;\n  z-index: 9999;\n  top: 0;\n  left: 0;\n  width: 100%;\n  height: 100%;\n  background-color: rgba(0, 0, 0, 0.8);\n  color: white;\n  display: flex;\n  align-items: center;\n  justify-content: center;\n  cursor: pointer;\n}\n\n.lightbox-content {\n  width: auto;\n  padding: 20px;\n  text-align: center;\n  max-width: 800px;\n}\n\n.lightbox-content img {\n  max-width: 100%;\n  max-height: 100%;\n}\n\n.close-btn {\n  position: absolute;\n  top: 5%;\n  right: 5%;\n  transform: translate(-70%, -22%);\n  background: none;\n  border: none;\n  color: white;\n  font-size: 24px;\n  cursor: pointer;\n}\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
