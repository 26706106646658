import { Component, EventEmitter, Input, OnInit, Output } from "@angular/core";

@Component({
  selector: "app-video-player",
  templateUrl: "./video-player.component.html",
  styleUrls: ["./video-player.component.scss"],
})
export class VideoPlayerComponent implements OnInit {
  @Input() public videoSource = "";
  @Output() closeEvent = new EventEmitter();

  constructor() {}

  ngOnInit(): void {}

  public close(): void {
    this.closeEvent.emit();
  }
}
