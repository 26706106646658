import { Injectable } from "@angular/core";
import { PredicateModel } from "@syncfusion/ej2-angular-grids";
import { Predicate } from "@syncfusion/ej2-data";

@Injectable({
  providedIn: "root",
})
//custom service for working with syncfusion predicates
export class PredicateService {
  constructor() {}

  //helper for either creating a new predicate if it doesnt exist yet or either doing an AND or OR onto the existing predicate
  createOrAddToPredicate(
    predicate: Predicate | null,
    field: string,
    operator: string,
    value: string | number | boolean | Date,
    predicateJoinOperator: "and" | "or",
    ignoreCase: boolean = true
  ): Predicate {
    if (predicate == null) {
      let predicate: Predicate = new Predicate(
        field,
        operator,
        value,
        ignoreCase
      );

      return predicate;
    } else {
      if (predicateJoinOperator == "and") {
        return predicate.and(field, operator, value, ignoreCase);
      } else if (predicateJoinOperator == "or") {
        return predicate.or(field, operator, value, ignoreCase);
      } else {
        return predicate;
      }
    }
  }

  //this is a helper for creating a simple PredicateModel that can be used in the grid.filterSettings.columns to specify a default filter
  createPredicateModel(
    field: string,
    operator: string,
    value: string | number | boolean | Date
  ): PredicateModel {
    var model: PredicateModel = {
      field: field,
      operator: operator,
      value: value,
    };
    return model;
  }
}
