 
   
   import { Injectable } from '@angular/core';
   import { HttpClient, HttpHeaders, HttpErrorResponse } from '@angular/common/http';

   import { Observable, of, throwError  } from 'rxjs';

   import { environment } from '../../environments/environment';
   //import { LoggerService } from '../../modules/core/logger.service'
   import { GenericSearchResultV1Model } from '../../app/models/GenericSearchResultV1Model';
   import { PageableListV1Model } from '../../app/models/PageableListV1Model';

   const httpOptions = {
     headers: new HttpHeaders({ 'Content-Type': 'application/json' }),
     withCredentials: true
   };

   const httpOptions_Handle400InComponent = {
     headers: new HttpHeaders({ 'Content-Type': 'application/json', 'I400Local': '' }),
     withCredentials: true
   }
    
   @Injectable({
     providedIn: 'root'
   })
   export class HazardsService {

   
        private classVersion = "1.0";
        
         
      constructor(private http: HttpClient) { }
      
        
          
              getAll( handle400Locally: boolean = false) : Observable<HazardsV1Model[]>  { 
              let version = this.classVersion;
              return this.http.get<any>(environment.apiRoot + `api/v${version}/hazards/GetAll`, handle400Locally ? httpOptions_Handle400InComponent : httpOptions )       
            };
          
      

            


                  
        
          
              getHazardsByID(hazardId: number,  handle400Locally: boolean = false) : Observable<HazardsV1Model>  { 
              let version = this.classVersion;
              return this.http.post<any>(environment.apiRoot + `api/v${version}/hazards/GetById`,hazardId, handle400Locally ? httpOptions_Handle400InComponent : httpOptions ) //p => p.Attributes.Count > 0 ----only adds FromBody     
            };
          
      
        
          
              search(model: HazardsSearchV1Model,  handle400Locally: boolean = false) : Observable<HazardsV1Model[]>  { 
              let version = this.classVersion;
              return this.http.post<any>(environment.apiRoot + `api/v${version}/hazards/Search`,model, handle400Locally ? httpOptions_Handle400InComponent : httpOptions ) //p => p.Attributes.Count > 0 ----only adds FromBody     
            };
          
      
        
          
              stepSearch(model: HazardsSearchV1Model,  handle400Locally: boolean = false) : Observable<HazardsV1Model[]>  { 
              let version = this.classVersion;
              return this.http.post<any>(environment.apiRoot + `api/v${version}/hazards/StepSearch`,model, handle400Locally ? httpOptions_Handle400InComponent : httpOptions ) //p => p.Attributes.Count > 0 ----only adds FromBody     
            };
          
      
        
          
              scopingHazardSearch(model: ScopingHazardsSearchV1Model,  handle400Locally: boolean = false) : Observable<HazardsV1Model[]>  { 
              let version = this.classVersion;
              return this.http.post<any>(environment.apiRoot + `api/v${version}/hazards/ScopingHazardSearch`,model, handle400Locally ? httpOptions_Handle400InComponent : httpOptions ) //p => p.Attributes.Count > 0 ----only adds FromBody     
            };
          
      
        
          
              insert(model: HazardsInsertV1Model,  handle400Locally: boolean = false) : Observable<InsertResponseV1Model>  { 
              let version = this.classVersion;
              return this.http.post<any>(environment.apiRoot + `api/v${version}/hazards/Insert`,model, handle400Locally ? httpOptions_Handle400InComponent : httpOptions ) //p => p.Attributes.Count > 0 ----only adds FromBody     
            };
          
      
        
          
              update(model: HazardsUpdateV1Model,  handle400Locally: boolean = false) : Observable<UpdateResponseV1Model>  { 
              let version = this.classVersion;
              return this.http.post<any>(environment.apiRoot + `api/v${version}/hazards/Update`,model, handle400Locally ? httpOptions_Handle400InComponent : httpOptions ) //p => p.Attributes.Count > 0 ----only adds FromBody     
            };
          
      
        
          
              delete(hazardId: number,  handle400Locally: boolean = false) : Observable<CommonResponseV1Model>  { 
              let version = this.classVersion;
              return this.http.post<any>(environment.apiRoot + `api/v${version}/hazards/Delete`,hazardId, handle400Locally ? httpOptions_Handle400InComponent : httpOptions ) //p => p.Attributes.Count > 0 ----only adds FromBody     
            };
          
      
        
          
              getRevisionHazards(procedureRevisionId: number,  handle400Locally: boolean = false) : Observable<HazardsV1Model[]>  { 
              let version = this.classVersion;
              return this.http.post<any>(environment.apiRoot + `api/v${version}/hazards/GetRevisionHazards`,procedureRevisionId, handle400Locally ? httpOptions_Handle400InComponent : httpOptions ) //p => p.Attributes.Count > 0 ----only adds FromBody     
            };
          
      
        
          
              getRevisionStepHazards(procedureRevisionId: number,  handle400Locally: boolean = false) : Observable<HazardsV1Model[]>  { 
              let version = this.classVersion;
              return this.http.post<any>(environment.apiRoot + `api/v${version}/hazards/GetRevisionStepHazards`,procedureRevisionId, handle400Locally ? httpOptions_Handle400InComponent : httpOptions ) //p => p.Attributes.Count > 0 ----only adds FromBody     
            };
          
      
        
          
              getProcedureStepHazards(procedureTaskStepId: number,  handle400Locally: boolean = false) : Observable<HazardsV1Model[]>  { 
              let version = this.classVersion;
              return this.http.post<any>(environment.apiRoot + `api/v${version}/hazards/GetProcedureStepHazards`,procedureTaskStepId, handle400Locally ? httpOptions_Handle400InComponent : httpOptions ) //p => p.Attributes.Count > 0 ----only adds FromBody     
            };
          
      
        
          
              getScopingHazards(scopeDocumentId: number,  handle400Locally: boolean = false) : Observable<HazardsV1Model[]>  { 
              let version = this.classVersion;
              return this.http.post<any>(environment.apiRoot + `api/v${version}/hazards/GetScopingHazards`,scopeDocumentId, handle400Locally ? httpOptions_Handle400InComponent : httpOptions ) //p => p.Attributes.Count > 0 ----only adds FromBody     
            };
          
      
        
          
              searchProcedureStepScopeHazards(model: ScopingHazardsSearchV1Model,  handle400Locally: boolean = false) : Observable<HazardsV1Model[]>  { 
              let version = this.classVersion;
              return this.http.post<any>(environment.apiRoot + `api/v${version}/hazards/SearchProcedureStepScopeHazards`,model, handle400Locally ? httpOptions_Handle400InComponent : httpOptions ) //p => p.Attributes.Count > 0 ----only adds FromBody     
            };
          
      
        
          
              getRevisionScopeHazards(procedureId: number, procedureRevisionId: number,  handle400Locally: boolean = false) : Observable<HazardsV1Model[]>  { 
              let version = this.classVersion;
              return this.http.post<any>(environment.apiRoot + `api/v${version}/hazards/GetRevisionScopeHazards?procedureRevisionId=${procedureRevisionId}`,procedureId, handle400Locally ? httpOptions_Handle400InComponent : httpOptions ) //p => p.Attributes.Count > 0 ----only adds FromBody     
            };
          
      
        
          
              hazardInactive(hazardId: number,  handle400Locally: boolean = false) : Observable<UpdateResponseV1Model>  { 
              let version = this.classVersion;
              return this.http.post<any>(environment.apiRoot + `api/v${version}/hazards/HazardInactive`,hazardId, handle400Locally ? httpOptions_Handle400InComponent : httpOptions ) //p => p.Attributes.Count > 0 ----only adds FromBody     
            };
          
      

                        

                  
}

export class HazardsV1Model {
    constructor(  
        // Inherited properties 
        
      
        //Properties
        
        public hazardId: number | null = null,   
        public hazardClass: string | null = null,   
        public description: string | null = null,   
        public isActive: boolean | null = null,   
        public createdByUserId: number | null = null,   
        public createdDate: Date | null = null,   
        public updatedByUserId: number | null = null,   
        public updatedDate: Date | null = null,   
        public createdByUserName: string | null = null,   
        public updatedByUserName: string | null = null,   
        public accountId: number | null = null,   
        public hazardIcon: string | null = null,   
        public precautionCount: string | null = null,   
        public readPrecautions: boolean | null = null,   
        public hazardPrecautions: PrecautionsV1Model[] | null = null,   
        public stepPrecautions: ProcedureStepPrecautionV1Model[] | null = null,   
        public scopingPrecautions: ScopingPrecautionV1Model[] | null = null,   
      ) { }
}

export class PrecautionsV1Model {
    constructor(  
        // Inherited properties 
        
      
        //Properties
        
        public precautionId: number | null = null,   
        public description: string | null = null,   
        public createdByUserId: number | null = null,   
        public createdDate: Date | null = null,   
        public updatedByUserId: number | null = null,   
        public updatedDate: Date | null = null,   
        public createdByUserName: string | null = null,   
        public updatedByUserName: string | null = null,   
        public accountId: number | null = null,   
        public hazardId: number | null = null,   
        public hazardClass: string | null = null,   
        public hazardIcon: string | null = null,   
        public precautionLevel: string | null = null,   
        public isActive: boolean | null = null,   
        public precautionColor: string | null = null,   
      ) { }
}

export class ProcedureStepPrecautionV1Model {
    constructor(  
        // Inherited properties 
        
      
        //Properties
        
        public procedureTaskStepId: number | null = null,   
        public procedureTaskStepPrecautionId: number | null = null,   
        public precautionId: number | null = null,   
        public description: string | null = null,   
        public createdByUserId: number | null = null,   
        public createdDate: Date | null = null,   
        public updatedByUserId: number | null = null,   
        public updatedDate: Date | null = null,   
        public createdByUserName: string | null = null,   
        public updatedByUserName: string | null = null,   
        public accountId: number | null = null,   
        public hazardId: number | null = null,   
        public hazardClass: string | null = null,   
        public hazardIcon: string | null = null,   
        public precautionLevel: string | null = null,   
        public precautionColor: string | null = null,   
      ) { }
}

export class ScopingPrecautionV1Model {
    constructor(  
        // Inherited properties 
        
      
        //Properties
        
        public scopeDocumentId: number | null = null,   
        public scopeDocumentPrecautionId: number | null = null,   
        public precautionId: number | null = null,   
        public description: string | null = null,   
        public createdByUserId: number | null = null,   
        public createdDate: Date | null = null,   
        public updatedByUserId: number | null = null,   
        public updatedDate: Date | null = null,   
        public createdByUserName: string | null = null,   
        public updatedByUserName: string | null = null,   
        public accountId: number | null = null,   
        public hazardId: number | null = null,   
        public hazardClass: string | null = null,   
        public hazardIcon: string | null = null,   
        public precautionLevel: string | null = null,   
        public precautionColor: string | null = null,   
      ) { }
}

export class HazardsSearchV1Model {
    constructor(  
        // Inherited properties 
        
      
        //Properties
        
        public searchTerm: string | null = null,   
        public procedureRevisionId: number | null = null,   
        public procedureTaskStepId: number | null = null,   
      ) { }
}

export class ScopingHazardsSearchV1Model {
    constructor(  
        // Inherited properties 
        
      
        //Properties
        
        public searchTerm: string | null = null,   
        public scopeDocumentId: number | null = null,   
        public procedureTaskStepId: number | null = null,   
      ) { }
}

export class InsertResponseV1Model  {
    constructor(  
        // Inherited properties 
        
        public success: boolean | null = null,
        public message: string | null = null,
      
        //Properties
        
        public identityId: number | null = null,   
        public identityIds: number[] | null = null,   
      ) { }
}

export class HazardsInsertV1Model {
    constructor(  
        // Inherited properties 
        
      
        //Properties
        
        public hazardClass: string | null = null,   
        public description: string | null = null,   
        public hazardIcon: string | null = null,   
        public isActive: boolean | null = null,   
      ) { }
}

export class UpdateResponseV1Model {
    constructor(  
        // Inherited properties 
        
      
        //Properties
        
        public totalRowsUpdated: number | null = null,   
      ) { }
}

export class HazardsUpdateV1Model {
    constructor(  
        // Inherited properties 
        
      
        //Properties
        
        public hazardId: number | null = null,   
        public hazardClass: string | null = null,   
        public description: string | null = null,   
        public hazardIcon: string | null = null,   
        public isActive: boolean | null = null,   
      ) { }
}

export class CommonResponseV1Model {
    constructor(  
        // Inherited properties 
        
      
        //Properties
        
        public success: boolean | null = null,   
        public message: string | null = null,   
      ) { }
}





/* 
     Debug Info:
     PrintDebugInfo - put a dollar sign in front of Print to enable this 
*/

