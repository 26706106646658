// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.radio-button-group {
  display: flex; /* Display the radio buttons in a row */
  gap: 10px; /* Adjust the spacing between radio buttons as needed */
}

.radio-button {
  display: flex;
  align-items: center; /* Vertically align the radio button and label */
}`, "",{"version":3,"sources":["webpack://./src/app/modules/shared/new-equipment-modal/new-equipment-modal.component.scss"],"names":[],"mappings":"AAAA;EACI,aAAA,EAAA,uCAAA;EACA,SAAA,EAAA,uDAAA;AACJ;;AAEE;EACE,aAAA;EACA,mBAAA,EAAA,gDAAA;AACJ","sourcesContent":[".radio-button-group {\n    display: flex; /* Display the radio buttons in a row */\n    gap: 10px; /* Adjust the spacing between radio buttons as needed */\n  }\n  \n  .radio-button {\n    display: flex;\n    align-items: center; /* Vertically align the radio button and label */\n  }"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
