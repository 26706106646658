import {
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
  Output,
  ViewChild,
} from "@angular/core";
import { NgbActiveModal } from "@ng-bootstrap/ng-bootstrap";
import {
  BarcodeGeneratorComponent,
  DisplayTextModel,
} from "@syncfusion/ej2-angular-barcode-generator";
import { ToastrService } from "ngx-toastr";
import { QRCodeV1Model, QRCodesService } from "src/app/api/QRCodes.service";
import { AuthService } from "src/app/modules/core/services/auth.service";
import { FormRepoService } from "src/app/modules/shared/services/form-repo.service";
import { environment } from "src/environments/environment";

@Component({
  selector: "app-qr-code-view-modal",
  templateUrl: "./qr-code-view-modal.component.html",
  styleUrls: ["./qr-code-view-modal.component.scss"],
})
export class QrCodeViewModalComponent {
  @Input() public qrCode: QRCodeV1Model = new QRCodeV1Model();
  @Output() public submitCompletedEvent = new EventEmitter();

  @ViewChild("barcode")
  public barcode: BarcodeGeneratorComponent | null = null;
  @ViewChild("displayText")
  public displayText?: DisplayTextModel;
  client: string = environment.clientRoot;
  newQRCodeForm = this.formRepoService.newQRCodeForm();
  public data: string = "";
  public filename = "Procedure QR Code";
  public guid: string | null = null;
  constructor(
    public activeModal: NgbActiveModal,
    private authService: AuthService,
    private formRepoService: FormRepoService,
    private cdr: ChangeDetectorRef
  ) {}

  ngOnInit(): void {
    //Setting value for qr code route
    this.guid =
      this.client +
      this.authService.getCurrentGuid() +
      "/QRCode/" +
      this.qrCode.codeGuid;
    this.displayText = {
      text: this.qrCode.name!,
    };
    this.newQRCodeForm.patchValue(this.qrCode);
    this.newQRCodeForm.disable();
    this.cdr.detectChanges();
  }

  onSubmit(): void {
    this.barcode!.exportAsBase64Image("PNG").then((base64) => {
      const newWindow = window.open();
      if (newWindow) {
        newWindow.document.write(
          `<html>          
          <head>
            <style>
              body {
                display: flex;
                justify-content: center;
                align-items: center;
                height: 100vh;
                margin: 0;
              }
              img {
                width: 500px;
                height: 460px;
              }
            </style>
          </head>
          <body>
            <img src="${base64}" />
          </body>
        </html>`
        );
      } else {
        alert(
          "Your browser blocked opening a new window. Please check your browser settings."
        );
      }
    });
  }

  onCancel(): void {
    this.activeModal.close();
  }
}
