import { Component, EventEmitter, Input, OnInit, Output, ViewChild } from "@angular/core";
import { FormBuilder, Validators } from "@angular/forms";
import { NgbActiveModal, NgbModal } from "@ng-bootstrap/ng-bootstrap";
import { NgxFileDropEntry, FileSystemFileEntry } from "ngx-file-drop";
import { DomSanitizer } from "@angular/platform-browser";
import { ToastrService } from "ngx-toastr";
import { FileUploadService } from "../../core/services/file-upload.service";
import { DocumentV1Model } from "src/app/api/Document.service";
import { TagsInsertV1Model, TagsService, TagsV1Model } from "src/app/api/Tags.service";
import { MultiSelectComponent } from "@syncfusion/ej2-angular-dropdowns";
import { cloneDeep, update } from "lodash";
import { TagsGridComponent } from "../tags-grid/tags-grid.component";
import { AccountV1Model } from "src/app/api/Account.service";
import { environment } from "src/environments/environment";
import { HttpClient } from "@angular/common/http";
import { VideoUpdateV1Model, VideoV1Model, VideosService } from "src/app/api/Videos.service";
import { Observable } from "rxjs";

@Component({
  selector: "app-video-upload-ngx2",
  templateUrl: "./video-upload-ngx2.component.html",
  styleUrls: ["./video-upload-ngx2.component.scss"],
})
export class VideoUploadNGX2Component implements OnInit {
  @Input() video: VideoV1Model | null = null;
  @Input() allowMultipleUploads: boolean = false;
  @Input() hideForm: boolean = false;
  @Input() modalTitle: string = "Upload File";
  @Output() public uploadingCompleteEvent = new EventEmitter<number>();
  @Input() isLibrary: boolean = true;
  apiRoot: string = environment.apiRoot;
  videos: NgxFileDropEntry[] = [];
  file: DocumentV1Model = new DocumentV1Model();

  isLoading: boolean = false;
  editMode: boolean = false;
  uploadForm = this.formBuilder.group({
    displayName: [""],
    isActive: ["true"],
  });
  fileObject: File | undefined;

  //Multiselect
  public selectedTagId: number[] = [];
  hideSelector: boolean = true;
  tagOptions: TagsV1Model[] = [];
  selectedTags: TagsV1Model[] = [];
  selectedTagIds: number[] = [];
  tagsPlaceholder = "Select tags from list...";
  @ViewChild("tagsDropdown")
  public accountDropdown!: MultiSelectComponent;
  public accountDropdownFields: Object = {
    text: "tagName",
    value: "tagId",
  };

  //Upload path
  previewUrl: any;
  public path: Object = {
    saveUrl: environment.apiRoot + "api/v1/videos/uploadFiles",
    removeUrl: "",
  };

  constructor(
    private toastService: ToastrService,
    private tagsService: TagsService,
    private fileUploadService: FileUploadService,
    private formBuilder: FormBuilder,
    private activeModal: NgbActiveModal,
    private sanitizer: DomSanitizer,
    private modalService: NgbModal,
    private videosService: VideosService,
    private http: HttpClient
  ) {}

  ngOnInit(): void {
    //Edit file upload
    if (this.video?.videoId != null) {
      this.editMode = true;
      this.uploadForm.controls.displayName.setValue(this.video.displayName);
      this.uploadForm.controls.isActive.setValue(this.video.isActive!.toString());
      this.loadVideoTags();
    }

    //New file
    else {
      this.loadAccountTags();
    }
  }

  loadAccountTags(): void {
    this.tagsService.getAccountTags().subscribe((data) => {
      this.tagOptions = data;
      this.hideSelector = false;
    });
  }

  loadVideoTags(): void {
    this.tagsService.getVideoTags(this.video?.videoId!).subscribe((data) => {
      this.selectedTags = data;

      data.forEach((tag) => {
        this.selectedTagIds.push(tag.tagId!);
      });

      this.loadAccountTags();
    });
  }

  public dropped(files: NgxFileDropEntry[]): void {
    if (files.length > 1) {
      this.toastService.error("Upload one video at a time");
      return;
    } else {
      for (const droppedFile of files) {
        if (droppedFile.fileEntry.isFile) {
          const fileEntry = droppedFile.fileEntry as FileSystemFileEntry;
          fileEntry.file((file: File) => {
            if (file.type.startsWith("video/")) {
              const fileSizeMB = file.size / (1024 * 1024);
              this.fileObject = file;
              if (fileSizeMB > 50) {
                this.toastService.error("File exceeds 50Mb limit");
                return;
              }

              this.videos = files;

              if (this.uploadForm.controls.displayName.value == "") {
                this.uploadForm.controls.displayName.setValue(file.name);
              }
            } else {
              this.toastService.error(file.type + " invalid file type");
            }
          });
        }
      }
    }
  }

  onSubmit(): void {
    if (this.uploadForm.invalid) {
      this.toastService.error("Please enter file name");
      return;
    }

    //Handles updates
    else if (this.editMode == true) {
      let updateModel = new VideoUpdateV1Model();

      updateModel.videoId = this.video!.videoId;
      updateModel.videoGuid = this.video!.videoGuid;
      updateModel.displayName = this.uploadForm.controls.displayName.value!;
      updateModel.fileName = this.video!.fileName;
      updateModel.contentType = this.video!.contentType;
      updateModel.tags = this.selectedTags;
      if (this.uploadForm.controls["isActive"].value == "false") {
        updateModel.isActive = false;
      } else {
        updateModel.isActive = true;
      }

      this.videosService.update(updateModel).subscribe(
        (data) => {
          this.toastService.success(this.uploadForm.controls.displayName.value + " updated");
          this.uploadingCompleteEvent.emit();
          this.activeModal.close();
        },
        (error) => {
          this.toastService.error("Error updating video. Please try again");
          this.isLoading = false;
        }
      );
    }

    //Inserts new file
    else {
      if (this.allowMultipleUploads == false && this.videos.length > 1) {
        this.toastService.error("Only one file is allowed to be uploaded.");
        return;
      }
      this.isLoading = true;

      if (this.fileObject) {
        const fileReader = new FileReader();

        fileReader.onload = (event) => {
          const arrayBuffer = event.target?.result as ArrayBuffer;
          const byteArray = new Uint8Array(arrayBuffer);

          const formData = new FormData();
          const blob = new Blob([byteArray]);
          formData.append("uploadFiles", blob, this.fileObject!.name);
          formData.append("displayName", this.uploadForm.controls.displayName.value!);
          formData.append("tagsJson", JSON.stringify(this.selectedTags));
          formData.append("isActive", this.uploadForm.controls.isActive.value!);

          this.http.post<any>(environment.apiRoot + "api/v1/videos/uploadFiles", formData).subscribe(
            (data) => {
              this.toastService.success(this.uploadForm.controls.displayName.value + " uploaded successfully");
              this.uploadingCompleteEvent.emit(data.identityId);
              this.activeModal.close();
            },
            (error) => {
              this.toastService.error("Error uploading video. Please try again");
              this.isLoading = false;
            }
          );
        };

        fileReader.readAsArrayBuffer(this.fileObject);
      } else {
        this.toastService.error("Invalid File");
      }
    }
  }

  onDeleteClicked(): void {
    this.videos = [];
    this.editMode = false;
    if (this.video != null) {
      this.uploadForm.controls.displayName.setValue(this.video.displayName);
    }
  }

  //************ Tag stuff *******************

  onManageTagsClicked(): void {
    const modalRef = this.modalService.open(TagsGridComponent, {
      size: "xl",
    });
    modalRef.componentInstance.deleteCompletedEvent.subscribe(() => {
      this.selectedTagIds = [];
      this.selectedTags = [];
      this.loadAccountTags();
    });
    modalRef.closed.subscribe(() => {
      this.loadAccountTags();
    });
  }

  onSelectedTag(args: any): void {
    //Adding tag that exists
    if (this.tagOptions.length > 0) {
      if (args.itemData != null && args.itemData != undefined) {
        const selectedTag = new TagsV1Model();
        Object.assign(selectedTag, args.itemData);
        this.selectedTags.push(selectedTag);
      }
    }
    //Adding new one when no tags exist to prevent errors
    else {
      let newTag = new TagsInsertV1Model();
      newTag.tagName = args.itemData.tagName;
      this.tagsService.insert(newTag).subscribe((data) => {
        console.log("Tag created");
        const selectedTag = new TagsV1Model();
        selectedTag.tagName = newTag.tagName;
        selectedTag.tagId = data.identityId;
        this.selectedTags.push(selectedTag);
        this.loadAccountTags();
      });
    }
  }

  onRemoveTag(args: any): void {
    const tagToRemove = cloneDeep(args.itemData);
    this.selectedTags = this.selectedTags.filter((tag) => tag.tagId !== tagToRemove.tagId);
  }
}
