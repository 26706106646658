import { Pipe, PipeTransform } from "@angular/core";

@Pipe({
  name: "timeFormat",
})
export class TimeFormatPipe implements PipeTransform {
  transform(minutes: number): string {
    const hours = Math.floor(minutes / 60);
    const remainingMinutes = minutes % 60;
    const roundedMinutes = Number(remainingMinutes.toFixed(1));

    //More than an hour
    if (hours > 1) {
      return `${hours} hours ${Math.ceil(remainingMinutes)} minutes`;
    }
    //One hour
    else if (hours == 1) {
      return `${hours} hour ${roundedMinutes} minutes`;
    }
    //One minute
    else if (roundedMinutes == 1) {
      return `${roundedMinutes} minute`;
    }
    //Less than one minute
    else if (roundedMinutes < 1 && roundedMinutes != 0) {
      return `< 1 minute`;
    }
    //More than a minute
    else {
      // return `${Math.ceil(remainingMinutes)} minutes`;
      return `${roundedMinutes} minutes`;
    }
  }
}
