// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.image-thumbnail {
  cursor: pointer;
}

.attached-file-thumbnail {
  max-width: 80%;
}

.grid-image-thumbnail {
  max-height: 50px;
  max-width: 50px;
}`, "",{"version":3,"sources":["webpack://./src/app/modules/shared/image-thumbnail/image-thumbnail.component.scss"],"names":[],"mappings":"AAAA;EACE,eAAA;AACF;;AAEA;EACE,cAAA;AACF;;AAEA;EACE,gBAAA;EACA,eAAA;AACF","sourcesContent":[".image-thumbnail {\n  cursor: pointer;\n}\n\n.attached-file-thumbnail {\n  max-width: 80%;\n}\n\n.grid-image-thumbnail {\n  max-height: 50px;\n  max-width: 50px;\n}"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
