import { APP_INITIALIZER, NgModule } from "@angular/core";
import { BrowserModule } from "@angular/platform-browser";
import { AppComponent } from "./app.component";
import { AppRoutingModule } from "./app-routing.module";
import { SharedModule } from "./modules/shared/shared.module";
import { HTTP_INTERCEPTORS, HttpClientModule } from "@angular/common/http";
import { AuthorizationGuard } from "./modules/core/guards/authorization.guard";
import { Http500Interceptor } from "./modules/core/interceptors/Http500.interceptor";
import { AuthInterceptor } from "./modules/core/interceptors/auth.interceptor";
import { AuthService } from "./modules/core/services/auth.service";
import { CoreModule } from "./modules/core/core.module";
import { ReactiveFormsModule } from "@angular/forms";
import { LoginModule } from "./modules/login/login.module";
import { ToastrModule } from "ngx-toastr";
import { BrowserAnimationsModule } from "@angular/platform-browser/animations";
import { DragDropModule } from "@angular/cdk/drag-drop";
import { UserIdleModule, WSPowerPackModule } from "wspowerpack";
import { DatePipe } from "@angular/common";
import { NumberToCharacterPipe } from "./modules/shared/pipes/number-to-character.pipe";

const appInitializerFn = (appConfig: AuthService) => {
  return () => {
    return appConfig.initializeApp();
  };
};

@NgModule({
  declarations: [AppComponent],
  imports: [
    UserIdleModule.forRoot({
      idle: 1700,
      timeout: 30,
      ping: 300,
    }),
    BrowserModule,
    BrowserAnimationsModule,
    ToastrModule.forRoot({
      positionClass: "toast-bottom-right",
    }),
    AppRoutingModule,
    SharedModule,
    HttpClientModule,
    CoreModule,
    ReactiveFormsModule,
    LoginModule,
    DragDropModule,
  ],
  exports: [WSPowerPackModule],
  providers: [
    [DatePipe],
    [NumberToCharacterPipe],
    AuthorizationGuard,
    { provide: HTTP_INTERCEPTORS, useClass: AuthInterceptor, multi: true },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: Http500Interceptor,
      multi: true,
    },
    AuthService,
    {
      provide: APP_INITIALIZER,
      useFactory: appInitializerFn,
      multi: true,
      deps: [AuthService],
    },
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}
