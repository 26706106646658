import { Injectable } from "@angular/core";

@Injectable({
  providedIn: "root",
})
export class FileUploadService {
  constructor() {}

  //helper for converting a blob to base64
  //make sure to use .then((fileBase64: string) => { ... })
  blobToBase64(blob: any): any {
    const reader = new FileReader();
    reader.readAsDataURL(blob);
    return new Promise((resolve) => {
      reader.onloadend = () => {
        resolve(reader.result);
      };
    });
  }
}
