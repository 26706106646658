 
   
   import { Injectable } from '@angular/core';
   import { HttpClient, HttpHeaders, HttpErrorResponse } from '@angular/common/http';

   import { Observable, of, throwError  } from 'rxjs';

   import { environment } from '../../environments/environment';
   //import { LoggerService } from '../../modules/core/logger.service'
   import { GenericSearchResultV1Model } from '../../app/models/GenericSearchResultV1Model';
   import { PageableListV1Model } from '../../app/models/PageableListV1Model';

   const httpOptions = {
     headers: new HttpHeaders({ 'Content-Type': 'application/json' }),
     withCredentials: true
   };

   const httpOptions_Handle400InComponent = {
     headers: new HttpHeaders({ 'Content-Type': 'application/json', 'I400Local': '' }),
     withCredentials: true
   }
    
   @Injectable({
     providedIn: 'root'
   })
   export class EquipmentsService {

   
        private classVersion = "1.0";
        
         
      constructor(private http: HttpClient) { }
      

            


                  
        
          
              search(model: EquipmentsSearchV1Model,  handle400Locally: boolean = false) : Observable<EquipmentsV1Model[]>  { 
              let version = this.classVersion;
              return this.http.post<any>(environment.apiRoot + `api/v${version}/equipments/Search`,model, handle400Locally ? httpOptions_Handle400InComponent : httpOptions ) //p => p.Attributes.Count > 0 ----only adds FromBody     
            };
          
      
        
          
              searchScopingEquipment(model: EquipmentScopingSearchV1Model,  handle400Locally: boolean = false) : Observable<EquipmentsV1Model[]>  { 
              let version = this.classVersion;
              return this.http.post<any>(environment.apiRoot + `api/v${version}/equipments/SearchScopingEquipment`,model, handle400Locally ? httpOptions_Handle400InComponent : httpOptions ) //p => p.Attributes.Count > 0 ----only adds FromBody     
            };
          
      
        
          
              getEquipmentByID(equipmentId: number,  handle400Locally: boolean = false) : Observable<EquipmentsV1Model>  { 
              let version = this.classVersion;
              return this.http.post<any>(environment.apiRoot + `api/v${version}/equipments/GetById`,equipmentId, handle400Locally ? httpOptions_Handle400InComponent : httpOptions ) //p => p.Attributes.Count > 0 ----only adds FromBody     
            };
          
      
        
          
              getProcedureStepEquipment(procedureTaskStepId: number,  handle400Locally: boolean = false) : Observable<ProcedureStepEquipmentV1Model[]>  { 
              let version = this.classVersion;
              return this.http.post<any>(environment.apiRoot + `api/v${version}/equipments/GetProcedureStepEquipment`,procedureTaskStepId, handle400Locally ? httpOptions_Handle400InComponent : httpOptions ) //p => p.Attributes.Count > 0 ----only adds FromBody     
            };
          
      
        
          
              getScopingEquipment(scopeDocumentId: number,  handle400Locally: boolean = false) : Observable<ScopingEquipmentV1Model[]>  { 
              let version = this.classVersion;
              return this.http.post<any>(environment.apiRoot + `api/v${version}/equipments/GetScopingEquipment`,scopeDocumentId, handle400Locally ? httpOptions_Handle400InComponent : httpOptions ) //p => p.Attributes.Count > 0 ----only adds FromBody     
            };
          
      
        
          
              searchProcedureStepScopeEquipments(model: EquipmentScopingSearchV1Model,  handle400Locally: boolean = false) : Observable<EquipmentsV1Model[]>  { 
              let version = this.classVersion;
              return this.http.post<any>(environment.apiRoot + `api/v${version}/equipments/GetProcedureStepScopeEquipments`,model, handle400Locally ? httpOptions_Handle400InComponent : httpOptions ) //p => p.Attributes.Count > 0 ----only adds FromBody     
            };
          
      
        
          
              getProcedureRevisionEquipments(procedureRevisionId: number,  handle400Locally: boolean = false) : Observable<RevisionEquipmentV1Model[]>  { 
              let version = this.classVersion;
              return this.http.post<any>(environment.apiRoot + `api/v${version}/equipments/GetProcedureRevisionEquipments`,procedureRevisionId, handle400Locally ? httpOptions_Handle400InComponent : httpOptions ) //p => p.Attributes.Count > 0 ----only adds FromBody     
            };
          
      
        
          
              insert(model: EquipmentsInsertV1Model,  handle400Locally: boolean = false) : Observable<InsertResponseV1Model>  { 
              let version = this.classVersion;
              return this.http.post<any>(environment.apiRoot + `api/v${version}/equipments/Insert`,model, handle400Locally ? httpOptions_Handle400InComponent : httpOptions ) //p => p.Attributes.Count > 0 ----only adds FromBody     
            };
          
      
        
          
              insertProcedureEquipment(model: ProcedureStepEquipmentInsertV1Model,  handle400Locally: boolean = false) : Observable<InsertResponseV1Model>  { 
              let version = this.classVersion;
              return this.http.post<any>(environment.apiRoot + `api/v${version}/equipments/InsertProcedureEquipment`,model, handle400Locally ? httpOptions_Handle400InComponent : httpOptions ) //p => p.Attributes.Count > 0 ----only adds FromBody     
            };
          
      
        
          
              insertScopingEquipment(model: ScopingEquipmentInsertV1Model,  handle400Locally: boolean = false) : Observable<InsertResponseV1Model>  { 
              let version = this.classVersion;
              return this.http.post<any>(environment.apiRoot + `api/v${version}/equipments/InsertScopingEquipment`,model, handle400Locally ? httpOptions_Handle400InComponent : httpOptions ) //p => p.Attributes.Count > 0 ----only adds FromBody     
            };
          
      
        
          
              update(model: EquipmentsUpdateV1Model,  handle400Locally: boolean = false) : Observable<UpdateResponseV1Model>  { 
              let version = this.classVersion;
              return this.http.post<any>(environment.apiRoot + `api/v${version}/equipments/Update`,model, handle400Locally ? httpOptions_Handle400InComponent : httpOptions ) //p => p.Attributes.Count > 0 ----only adds FromBody     
            };
          
      
        
          
              updateProcedureEquipment(model: ProcedureStepEquipmentUpdateV1Model,  handle400Locally: boolean = false) : Observable<UpdateResponseV1Model>  { 
              let version = this.classVersion;
              return this.http.post<any>(environment.apiRoot + `api/v${version}/equipments/UpdateProcedureEquipment`,model, handle400Locally ? httpOptions_Handle400InComponent : httpOptions ) //p => p.Attributes.Count > 0 ----only adds FromBody     
            };
          
      
        
          
              updateScopingEquipment(model: ScopingEquipmentUpdateV1Model,  handle400Locally: boolean = false) : Observable<UpdateResponseV1Model>  { 
              let version = this.classVersion;
              return this.http.post<any>(environment.apiRoot + `api/v${version}/equipments/UpdateScopingEquipment`,model, handle400Locally ? httpOptions_Handle400InComponent : httpOptions ) //p => p.Attributes.Count > 0 ----only adds FromBody     
            };
          
      
        
          
              delete(equipmentId: number,  handle400Locally: boolean = false) : Observable<CommonResponseV1Model>  { 
              let version = this.classVersion;
              return this.http.post<any>(environment.apiRoot + `api/v${version}/equipments/Delete`,equipmentId, handle400Locally ? httpOptions_Handle400InComponent : httpOptions ) //p => p.Attributes.Count > 0 ----only adds FromBody     
            };
          
      
        
          
              removeProcedureStepEquipment(procedureTaskStepEquipmentId: number,  handle400Locally: boolean = false) : Observable<CommonResponseV1Model>  { 
              let version = this.classVersion;
              return this.http.post<any>(environment.apiRoot + `api/v${version}/equipments/RemoveProcedureStepEquipment`,procedureTaskStepEquipmentId, handle400Locally ? httpOptions_Handle400InComponent : httpOptions ) //p => p.Attributes.Count > 0 ----only adds FromBody     
            };
          
      
        
          
              removeScopingEquipment(scopeDocumentEquipmentId: number,  handle400Locally: boolean = false) : Observable<CommonResponseV1Model>  { 
              let version = this.classVersion;
              return this.http.post<any>(environment.apiRoot + `api/v${version}/equipments/RemoveScopingEquipment`,scopeDocumentEquipmentId, handle400Locally ? httpOptions_Handle400InComponent : httpOptions ) //p => p.Attributes.Count > 0 ----only adds FromBody     
            };
          
      
        
          
              equipmentInactive(equipmentId: number,  handle400Locally: boolean = false) : Observable<UpdateResponseV1Model>  { 
              let version = this.classVersion;
              return this.http.post<any>(environment.apiRoot + `api/v${version}/equipments/EquipmentInactive`,equipmentId, handle400Locally ? httpOptions_Handle400InComponent : httpOptions ) //p => p.Attributes.Count > 0 ----only adds FromBody     
            };
          
      

                        

                  
}

export class EquipmentsV1Model {
    constructor(  
        // Inherited properties 
        
      
        //Properties
        
        public equipmentId: number | null = null,   
        public name: string | null = null,   
        public shortName: string | null = null,   
        public idNumber: string | null = null,   
        public isActive: boolean | null = null,   
        public createdByUserId: number | null = null,   
        public createdDate: Date | null = null,   
        public updatedByUserId: number | null = null,   
        public updatedDate: Date | null = null,   
        public createdByUserName: string | null = null,   
        public updatedByUserName: string | null = null,   
        public accountId: number | null = null,   
      ) { }
}

export class EquipmentsSearchV1Model {
    constructor(  
        // Inherited properties 
        
      
        //Properties
        
        public searchTerm: string | null = null,   
        public procedureRevisionId: number | null = null,   
        public procedureTaskStepId: number | null = null,   
      ) { }
}

export class EquipmentScopingSearchV1Model {
    constructor(  
        // Inherited properties 
        
      
        //Properties
        
        public searchTerm: string | null = null,   
        public scopeDocumentId: number | null = null,   
        public procedureTaskStepId: number | null = null,   
      ) { }
}

export class ProcedureStepEquipmentV1Model {
    constructor(  
        // Inherited properties 
        
      
        //Properties
        
        public procedureTaskStepEquipmentId: number | null = null,   
        public procedureTaskStepId: number | null = null,   
        public equipmentId: number | null = null,   
        public quantity: number | null = null,   
        public name: string | null = null,   
        public shortName: string | null = null,   
        public idNumber: string | null = null,   
        public createdDate: Date | null = null,   
        public updatedDate: Date | null = null,   
        public accountId: number | null = null,   
      ) { }
}

export class ScopingEquipmentV1Model {
    constructor(  
        // Inherited properties 
        
      
        //Properties
        
        public scopeDocumentEquipmentId: number | null = null,   
        public scopeDocumentId: number | null = null,   
        public equipmentId: number | null = null,   
        public quantity: number | null = null,   
        public name: string | null = null,   
        public shortName: string | null = null,   
        public idNumber: string | null = null,   
        public createdDate: Date | null = null,   
        public updatedDate: Date | null = null,   
        public accountId: number | null = null,   
      ) { }
}

export class RevisionEquipmentV1Model {
    constructor(  
        // Inherited properties 
        
      
        //Properties
        
        public equipmentId: number | null = null,   
        public name: string | null = null,   
        public shortName: string | null = null,   
        public idNumber: string | null = null,   
        public quantity: number | null = null,   
      ) { }
}

export class InsertResponseV1Model  {
    constructor(  
        // Inherited properties 
        
        public success: boolean | null = null,
        public message: string | null = null,
      
        //Properties
        
        public identityId: number | null = null,   
        public identityIds: number[] | null = null,   
      ) { }
}

export class EquipmentsInsertV1Model {
    constructor(  
        // Inherited properties 
        
      
        //Properties
        
        public name: string | null = null,   
        public shortName: string | null = null,   
        public idNumber: string | null = null,   
        public isActive: boolean | null = null,   
      ) { }
}

export class ProcedureStepEquipmentInsertV1Model {
    constructor(  
        // Inherited properties 
        
      
        //Properties
        
        public procedureTaskStepId: number | null = null,   
        public equipmentId: number | null = null,   
        public quantity: number | null = null,   
      ) { }
}

export class ScopingEquipmentInsertV1Model {
    constructor(  
        // Inherited properties 
        
      
        //Properties
        
        public scopeDocumentId: number | null = null,   
        public equipmentId: number | null = null,   
        public quantity: number | null = null,   
      ) { }
}

export class UpdateResponseV1Model {
    constructor(  
        // Inherited properties 
        
      
        //Properties
        
        public totalRowsUpdated: number | null = null,   
      ) { }
}

export class EquipmentsUpdateV1Model {
    constructor(  
        // Inherited properties 
        
      
        //Properties
        
        public equipmentId: number | null = null,   
        public name: string | null = null,   
        public shortName: string | null = null,   
        public idNumber: string | null = null,   
        public isActive: boolean | null = null,   
      ) { }
}

export class ProcedureStepEquipmentUpdateV1Model {
    constructor(  
        // Inherited properties 
        
      
        //Properties
        
        public procedureTaskStepEquipmentId: number | null = null,   
        public quantity: number | null = null,   
      ) { }
}

export class ScopingEquipmentUpdateV1Model {
    constructor(  
        // Inherited properties 
        
      
        //Properties
        
        public scopeDocumentEquipmentId: number | null = null,   
        public quantity: number | null = null,   
      ) { }
}

export class CommonResponseV1Model {
    constructor(  
        // Inherited properties 
        
      
        //Properties
        
        public success: boolean | null = null,   
        public message: string | null = null,   
      ) { }
}





/* 
     Debug Info:
     PrintDebugInfo - put a dollar sign in front of Print to enable this 
*/

