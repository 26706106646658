import { Component, Input } from "@angular/core";
import {
  PermitsService,
  RevisionPermitV1Model,
} from "src/app/api/Permits.service";
import { environment } from "src/environments/environment";

@Component({
  selector: "app-prerequisite-permits",
  templateUrl: "./prerequisite-permits.component.html",
  styleUrls: ["./prerequisite-permits.component.scss"],
})
export class PrerequisitePermitsComponent {
  @Input() public procedureRevisionId: number = 0;
  @Input() public smallPreview: boolean = true;
  permits: RevisionPermitV1Model[] = [];
  apiRoot: string = environment.apiRoot;
  isLoading: boolean = true;
  constructor(private permitsService: PermitsService) {}

  ngOnInit(): void {
    this.permitsService
      .getRevisionPermits(this.procedureRevisionId)
      .subscribe((data) => {
        this.permits = data;
        this.isLoading = false;
      });
  }

  onFileClicked(documentGuid: any): void {
    window.open(
      this.apiRoot + "api/v1/documents/download/" + documentGuid,
      "_blank"
    );
  }
}
