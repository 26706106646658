 
   
   import { Injectable } from '@angular/core';
   import { HttpClient, HttpHeaders, HttpErrorResponse } from '@angular/common/http';

   import { Observable, of, throwError  } from 'rxjs';

   import { environment } from '../../environments/environment';
   //import { LoggerService } from '../../modules/core/logger.service'
   import { GenericSearchResultV1Model } from '../../app/models/GenericSearchResultV1Model';
   import { PageableListV1Model } from '../../app/models/PageableListV1Model';

   const httpOptions = {
     headers: new HttpHeaders({ 'Content-Type': 'application/json' }),
     withCredentials: true
   };

   const httpOptions_Handle400InComponent = {
     headers: new HttpHeaders({ 'Content-Type': 'application/json', 'I400Local': '' }),
     withCredentials: true
   }
    
   @Injectable({
     providedIn: 'root'
   })
   export class FiguresService {

   
        private classVersion = "1.0";
        
         
      constructor(private http: HttpClient) { }
      

            


                  
        
          
              search(model: FiguresSearchV1Model,  handle400Locally: boolean = false) : Observable<DocumentV1Model[]>  { 
              let version = this.classVersion;
              return this.http.post<any>(environment.apiRoot + `api/v${version}/figures/Search`,model, handle400Locally ? httpOptions_Handle400InComponent : httpOptions ) //p => p.Attributes.Count > 0 ----only adds FromBody     
            };
          
      
        
          
              searchStep(model: FiguresStepSearchV1Model,  handle400Locally: boolean = false) : Observable<FiguresV1Model[]>  { 
              let version = this.classVersion;
              return this.http.post<any>(environment.apiRoot + `api/v${version}/figures/SearchStep`,model, handle400Locally ? httpOptions_Handle400InComponent : httpOptions ) //p => p.Attributes.Count > 0 ----only adds FromBody     
            };
          
      
        
          
              searchScopingFigure(model: FiguresScopingSearchV1Model,  handle400Locally: boolean = false) : Observable<FiguresV1Model[]>  { 
              let version = this.classVersion;
              return this.http.post<any>(environment.apiRoot + `api/v${version}/figures/SearchScopingFigure`,model, handle400Locally ? httpOptions_Handle400InComponent : httpOptions ) //p => p.Attributes.Count > 0 ----only adds FromBody     
            };
          
      
        
          
              getProcedureStepFigures(procedureTaskStepId: number,  handle400Locally: boolean = false) : Observable<ProcedureStepFiguresV1Model[]>  { 
              let version = this.classVersion;
              return this.http.post<any>(environment.apiRoot + `api/v${version}/figures/GetProcedureStepFigures`,procedureTaskStepId, handle400Locally ? httpOptions_Handle400InComponent : httpOptions ) //p => p.Attributes.Count > 0 ----only adds FromBody     
            };
          
      
        
          
              getScopingFigures(scopeDocumentId: number,  handle400Locally: boolean = false) : Observable<ScopingFigureV1Model[]>  { 
              let version = this.classVersion;
              return this.http.post<any>(environment.apiRoot + `api/v${version}/figures/GetScopingFigures`,scopeDocumentId, handle400Locally ? httpOptions_Handle400InComponent : httpOptions ) //p => p.Attributes.Count > 0 ----only adds FromBody     
            };
          
      
        
          
              getRevisionFigures(procedureRevisionId: number,  handle400Locally: boolean = false) : Observable<RevisionFigureV1Model[]>  { 
              let version = this.classVersion;
              return this.http.post<any>(environment.apiRoot + `api/v${version}/figures/GetRevisionFigures`,procedureRevisionId, handle400Locally ? httpOptions_Handle400InComponent : httpOptions ) //p => p.Attributes.Count > 0 ----only adds FromBody     
            };
          
      
        
          
              getFiguresByID(figureId: number,  handle400Locally: boolean = false) : Observable<FiguresV1Model>  { 
              let version = this.classVersion;
              return this.http.post<any>(environment.apiRoot + `api/v${version}/figures/GetById`,figureId, handle400Locally ? httpOptions_Handle400InComponent : httpOptions ) //p => p.Attributes.Count > 0 ----only adds FromBody     
            };
          
      
        
          
              searchProcedureStepScopeFigures(model: FiguresScopingSearchV1Model,  handle400Locally: boolean = false) : Observable<FiguresV1Model[]>  { 
              let version = this.classVersion;
              return this.http.post<any>(environment.apiRoot + `api/v${version}/figures/SearchProcedureStepScopeFigures`,model, handle400Locally ? httpOptions_Handle400InComponent : httpOptions ) //p => p.Attributes.Count > 0 ----only adds FromBody     
            };
          
      
        
          
              insert(model: FiguresInsertV1Model,  handle400Locally: boolean = false) : Observable<InsertResponseV1Model>  { 
              let version = this.classVersion;
              return this.http.post<any>(environment.apiRoot + `api/v${version}/figures/Insert`,model, handle400Locally ? httpOptions_Handle400InComponent : httpOptions ) //p => p.Attributes.Count > 0 ----only adds FromBody     
            };
          
      
        
          
              procedureFigureInsert(model: ProcedureStepFigureInsertV1Model,  handle400Locally: boolean = false) : Observable<InsertResponseV1Model>  { 
              let version = this.classVersion;
              return this.http.post<any>(environment.apiRoot + `api/v${version}/figures/ProcedureFigureInsert`,model, handle400Locally ? httpOptions_Handle400InComponent : httpOptions ) //p => p.Attributes.Count > 0 ----only adds FromBody     
            };
          
      
        
          
              insertScopingFigure(model: ScopingFigureInsertV1Model,  handle400Locally: boolean = false) : Observable<InsertResponseV1Model>  { 
              let version = this.classVersion;
              return this.http.post<any>(environment.apiRoot + `api/v${version}/figures/InsertScopingFigure`,model, handle400Locally ? httpOptions_Handle400InComponent : httpOptions ) //p => p.Attributes.Count > 0 ----only adds FromBody     
            };
          
      
        
          
              update(model: FiguresUpdateV1Model,  handle400Locally: boolean = false) : Observable<UpdateResponseV1Model>  { 
              let version = this.classVersion;
              return this.http.post<any>(environment.apiRoot + `api/v${version}/figures/Update`,model, handle400Locally ? httpOptions_Handle400InComponent : httpOptions ) //p => p.Attributes.Count > 0 ----only adds FromBody     
            };
          
      
        
          
              delete(model: DocumentDeleteV1Model,  handle400Locally: boolean = false) : Observable<CommonResponseV1Model>  { 
              let version = this.classVersion;
              return this.http.post<any>(environment.apiRoot + `api/v${version}/figures/Delete`,model, handle400Locally ? httpOptions_Handle400InComponent : httpOptions ) //p => p.Attributes.Count > 0 ----only adds FromBody     
            };
          
      
        
          
              removeProcedureFigure(procedureTaskStepFigureId: number,  handle400Locally: boolean = false) : Observable<CommonResponseV1Model>  { 
              let version = this.classVersion;
              return this.http.post<any>(environment.apiRoot + `api/v${version}/figures/RemoveProcedureFigure`,procedureTaskStepFigureId, handle400Locally ? httpOptions_Handle400InComponent : httpOptions ) //p => p.Attributes.Count > 0 ----only adds FromBody     
            };
          
      
        
          
              removeScopingFigures(scopeDocumentFigureId: number,  handle400Locally: boolean = false) : Observable<CommonResponseV1Model>  { 
              let version = this.classVersion;
              return this.http.post<any>(environment.apiRoot + `api/v${version}/figures/RemoveScopingFigures`,scopeDocumentFigureId, handle400Locally ? httpOptions_Handle400InComponent : httpOptions ) //p => p.Attributes.Count > 0 ----only adds FromBody     
            };
          
      
        
          
              figureInactive(figureId: number,  handle400Locally: boolean = false) : Observable<UpdateResponseV1Model>  { 
              let version = this.classVersion;
              return this.http.post<any>(environment.apiRoot + `api/v${version}/figures/FigureInactive`,figureId, handle400Locally ? httpOptions_Handle400InComponent : httpOptions ) //p => p.Attributes.Count > 0 ----only adds FromBody     
            };
          
      

                        

                  
}

export class DocumentV1Model {
    constructor(  
        // Inherited properties 
        
      
        //Properties
        
        public documentId: number | null = null,   
        public fileBase64: string | null = null,   
        public displayName: string | null = null,   
        public fileName: string | null = null,   
        public documentGuid: string | null = null,   
        public isActive: boolean | null = null,   
        public createdDate: Date | null = null,   
        public createdByUserId: number | null = null,   
        public createdByUserName: string | null = null,   
        public contentType: string | null = null,   
        public tagNames: string | null = null,   
      ) { }
}

export class FiguresSearchV1Model {
    constructor(  
        // Inherited properties 
        
      
        //Properties
        
        public searchTerm: string | null = null,   
      ) { }
}

export class FiguresV1Model {
    constructor(  
        // Inherited properties 
        
      
        //Properties
        
        public figureId: number | null = null,   
        public fileName: string | null = null,   
        public fileBase64: string | null = null,   
        public displayName: string | null = null,   
        public documentGuid: string | null = null,   
        public isActive: boolean | null = null,   
        public createdByUserId: number | null = null,   
        public createdDate: Date | null = null,   
        public documentId: number | null = null,   
        public contentType: string | null = null,   
        public createdByUserName: string | null = null,   
        public updatedByUserName: string | null = null,   
        public tagNames: string | null = null,   
        public accountId: number | null = null,   
      ) { }
}

export class FiguresStepSearchV1Model {
    constructor(  
        // Inherited properties 
        
      
        //Properties
        
        public searchTerm: string | null = null,   
        public procedureRevisionId: number | null = null,   
        public procedureTaskStepId: number | null = null,   
      ) { }
}

export class FiguresScopingSearchV1Model {
    constructor(  
        // Inherited properties 
        
      
        //Properties
        
        public searchTerm: string | null = null,   
        public scopeDocumentId: number | null = null,   
        public procedureTaskStepId: number | null = null,   
      ) { }
}

export class ProcedureStepFiguresV1Model {
    constructor(  
        // Inherited properties 
        
      
        //Properties
        
        public procedureTaskStepFigureId: number | null = null,   
        public procedureTaskStepId: number | null = null,   
        public figureId: number | null = null,   
        public documentId: number | null = null,   
        public accountId: number | null = null,   
        public fileName: string | null = null,   
        public documentGuid: string | null = null,   
        public fileBase64: string | null = null,   
        public contentType: string | null = null,   
        public displayName: string | null = null,   
        public createdDate: Date | null = null,   
        public figureNumber: number | null = null,   
      ) { }
}

export class ScopingFigureV1Model {
    constructor(  
        // Inherited properties 
        
      
        //Properties
        
        public scopeDocumentFigureId: number | null = null,   
        public scopeDocumentId: number | null = null,   
        public figureId: number | null = null,   
        public documentId: number | null = null,   
        public accountId: number | null = null,   
        public fileName: string | null = null,   
        public documentGuid: string | null = null,   
        public fileBase64: string | null = null,   
        public contentType: string | null = null,   
        public displayName: string | null = null,   
        public createdDate: Date | null = null,   
        public figureNumber: number | null = null,   
      ) { }
}

export class RevisionFigureV1Model {
    constructor(  
        // Inherited properties 
        
      
        //Properties
        
        public figureId: number | null = null,   
        public fileName: string | null = null,   
        public displayName: string | null = null,   
        public documentGuid: string | null = null,   
      ) { }
}

export class InsertResponseV1Model  {
    constructor(  
        // Inherited properties 
        
        public success: boolean | null = null,
        public message: string | null = null,
      
        //Properties
        
        public identityId: number | null = null,   
        public identityIds: number[] | null = null,   
      ) { }
}

export class FiguresInsertV1Model {
    constructor(  
        // Inherited properties 
        
      
        //Properties
        
        public fileName: string | null = null,   
        public documentGuid: string | null = null,   
        public accountId: number | null = null,   
        public isActive: boolean | null = null,   
      ) { }
}

export class ProcedureStepFigureInsertV1Model {
    constructor(  
        // Inherited properties 
        
      
        //Properties
        
        public procedureTaskStepId: number | null = null,   
        public figureId: number | null = null,   
      ) { }
}

export class ScopingFigureInsertV1Model {
    constructor(  
        // Inherited properties 
        
      
        //Properties
        
        public scopeDocumentId: number | null = null,   
        public figureId: number | null = null,   
      ) { }
}

export class UpdateResponseV1Model {
    constructor(  
        // Inherited properties 
        
      
        //Properties
        
        public totalRowsUpdated: number | null = null,   
      ) { }
}

export class FiguresUpdateV1Model {
    constructor(  
        // Inherited properties 
        
      
        //Properties
        
        public figureId: number | null = null,   
        public fileName: string | null = null,   
        public documentGuid: string | null = null,   
        public isActive: boolean | null = null,   
      ) { }
}

export class CommonResponseV1Model {
    constructor(  
        // Inherited properties 
        
      
        //Properties
        
        public success: boolean | null = null,   
        public message: string | null = null,   
      ) { }
}

export class DocumentDeleteV1Model {
    constructor(  
        // Inherited properties 
        
      
        //Properties
        
        public documentId: number | null = null,   
        public tablename: string | null = null,   
      ) { }
}





/* 
     Debug Info:
     PrintDebugInfo - put a dollar sign in front of Print to enable this 
*/

