import { Component, EventEmitter, Input, Output } from "@angular/core";
import { NgbActiveModal } from "@ng-bootstrap/ng-bootstrap";
import { ToastrService } from "ngx-toastr";
import {
  ProceduresService,
  ProceduresUpdateV1Model,
  ProceduresV1Model,
} from "src/app/api/Procedures.service";
import { FormRepoService } from "src/app/modules/shared/services/form-repo.service";
import { DatePipe } from "@angular/common";
import { ScopingService, ScopingV1Model } from "src/app/api/Scoping.service";

@Component({
  selector: "app-edit-procedure-modal",
  templateUrl: "./edit-procedure-modal.component.html",
  styleUrls: ["./edit-procedure-modal.component.scss"],
})
export class EditProcedureModalComponent {
  @Input() public procedure!: ProceduresV1Model;
  @Output() public submitCompletedEvent = new EventEmitter();
  editProcedureForm = this.formRepoService.newProcedureForm();
  scopeDocuments: ScopingV1Model[] = [];
  constructor(
    public activeModal: NgbActiveModal,
    private toastService: ToastrService,
    private procedureService: ProceduresService,
    private formRepoService: FormRepoService,
    private datePipe: DatePipe,
    private scopeService: ScopingService
  ) {}

  ngOnInit(): void {
    this.scopeService.getScopingDocument().subscribe((data) => {
      this.scopeDocuments = data;
      this.setUpForm();
    });
  }

  setUpForm() {
    this.editProcedureForm.controls["name"].setValue(this.procedure.name);

    this.editProcedureForm.controls["procedureNumber"].setValue(
      this.procedure.procedureNumber
    );

    this.editProcedureForm.controls["scopeDocumentId"].setValue(
      this.procedure.scopeDocumentId || ""
    );
  }

  onSubmit(): void {
    let model = new ProceduresUpdateV1Model();
    Object.assign(model, this.editProcedureForm.value);
    model.name = this.editProcedureForm.controls["name"].value;
    model.procedureNumber =
      this.editProcedureForm.controls["procedureNumber"].value;
    model.procedureId = this.procedure.procedureId;

    this.procedureService.update(model).subscribe((data) => {
      this.toastService.success(model.name + " updated");
      this.submitCompletedEvent.emit();
      this.activeModal.close();
    });
  }

  onCancel(): void {
    this.activeModal.close();
  }
}
