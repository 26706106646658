 
   
   import { Injectable } from '@angular/core';
   import { HttpClient, HttpHeaders, HttpErrorResponse } from '@angular/common/http';

   import { Observable, of, throwError  } from 'rxjs';

   import { environment } from '../../environments/environment';
   //import { LoggerService } from '../../modules/core/logger.service'
   import { GenericSearchResultV1Model } from '../../app/models/GenericSearchResultV1Model';
   import { PageableListV1Model } from '../../app/models/PageableListV1Model';

   const httpOptions = {
     headers: new HttpHeaders({ 'Content-Type': 'application/json' }),
     withCredentials: true
   };

   const httpOptions_Handle400InComponent = {
     headers: new HttpHeaders({ 'Content-Type': 'application/json', 'I400Local': '' }),
     withCredentials: true
   }
    
   @Injectable({
     providedIn: 'root'
   })
   export class PartsService {

   
        private classVersion = "1.0";
        
         
      constructor(private http: HttpClient) { }
      

            


                  
        
          
              getPartsByID(partId: number,  handle400Locally: boolean = false) : Observable<PartsV1Model>  { 
              let version = this.classVersion;
              return this.http.post<any>(environment.apiRoot + `api/v${version}/parts/GetById`,partId, handle400Locally ? httpOptions_Handle400InComponent : httpOptions ) //p => p.Attributes.Count > 0 ----only adds FromBody     
            };
          
      
        
          
              search(model: PartsSearchV1Model,  handle400Locally: boolean = false) : Observable<PartsV1Model[]>  { 
              let version = this.classVersion;
              return this.http.post<any>(environment.apiRoot + `api/v${version}/parts/Search`,model, handle400Locally ? httpOptions_Handle400InComponent : httpOptions ) //p => p.Attributes.Count > 0 ----only adds FromBody     
            };
          
      
        
          
              searchScopingPart(model: PartScopingSearchV1Model,  handle400Locally: boolean = false) : Observable<PartsV1Model[]>  { 
              let version = this.classVersion;
              return this.http.post<any>(environment.apiRoot + `api/v${version}/parts/SearchScopingPart`,model, handle400Locally ? httpOptions_Handle400InComponent : httpOptions ) //p => p.Attributes.Count > 0 ----only adds FromBody     
            };
          
      
        
          
              searchProcedureStepScopeParts(model: PartScopingSearchV1Model,  handle400Locally: boolean = false) : Observable<PartsV1Model[]>  { 
              let version = this.classVersion;
              return this.http.post<any>(environment.apiRoot + `api/v${version}/parts/SearchProcedureStepScopeParts`,model, handle400Locally ? httpOptions_Handle400InComponent : httpOptions ) //p => p.Attributes.Count > 0 ----only adds FromBody     
            };
          
      
        
          
              getProcedureStepPart(procedureTaskStepId: number,  handle400Locally: boolean = false) : Observable<ProcedureStepPartV1Model[]>  { 
              let version = this.classVersion;
              return this.http.post<any>(environment.apiRoot + `api/v${version}/parts/GetProcedureStepPart`,procedureTaskStepId, handle400Locally ? httpOptions_Handle400InComponent : httpOptions ) //p => p.Attributes.Count > 0 ----only adds FromBody     
            };
          
      
        
          
              getScopingPart(scopeDocumentId: number,  handle400Locally: boolean = false) : Observable<ScopingPartV1Model[]>  { 
              let version = this.classVersion;
              return this.http.post<any>(environment.apiRoot + `api/v${version}/parts/GetScopingPart`,scopeDocumentId, handle400Locally ? httpOptions_Handle400InComponent : httpOptions ) //p => p.Attributes.Count > 0 ----only adds FromBody     
            };
          
      
        
          
              getRevisionParts(procedureRevisionId: number,  handle400Locally: boolean = false) : Observable<RevisionPartsV1Model[]>  { 
              let version = this.classVersion;
              return this.http.post<any>(environment.apiRoot + `api/v${version}/parts/GetRevisionParts`,procedureRevisionId, handle400Locally ? httpOptions_Handle400InComponent : httpOptions ) //p => p.Attributes.Count > 0 ----only adds FromBody     
            };
          
      
        
          
              insert(model: PartsInsertV1Model,  handle400Locally: boolean = false) : Observable<InsertResponseV1Model>  { 
              let version = this.classVersion;
              return this.http.post<any>(environment.apiRoot + `api/v${version}/parts/Insert`,model, handle400Locally ? httpOptions_Handle400InComponent : httpOptions ) //p => p.Attributes.Count > 0 ----only adds FromBody     
            };
          
      
        
          
              insertProcedurePart(model: ProcedureStepPartInsertV1Model,  handle400Locally: boolean = false) : Observable<InsertResponseV1Model>  { 
              let version = this.classVersion;
              return this.http.post<any>(environment.apiRoot + `api/v${version}/parts/InsertProcedurePart`,model, handle400Locally ? httpOptions_Handle400InComponent : httpOptions ) //p => p.Attributes.Count > 0 ----only adds FromBody     
            };
          
      
        
          
              insertScopingPart(model: ScopingPartInsertV1Model,  handle400Locally: boolean = false) : Observable<InsertResponseV1Model>  { 
              let version = this.classVersion;
              return this.http.post<any>(environment.apiRoot + `api/v${version}/parts/InsertScopingPart`,model, handle400Locally ? httpOptions_Handle400InComponent : httpOptions ) //p => p.Attributes.Count > 0 ----only adds FromBody     
            };
          
      
        
          
              update(model: PartsUpdateV1Model,  handle400Locally: boolean = false) : Observable<UpdateResponseV1Model>  { 
              let version = this.classVersion;
              return this.http.post<any>(environment.apiRoot + `api/v${version}/parts/Update`,model, handle400Locally ? httpOptions_Handle400InComponent : httpOptions ) //p => p.Attributes.Count > 0 ----only adds FromBody     
            };
          
      
        
          
              updateProcedurePart(model: ProcedureStepPartUpdateV1Model,  handle400Locally: boolean = false) : Observable<UpdateResponseV1Model>  { 
              let version = this.classVersion;
              return this.http.post<any>(environment.apiRoot + `api/v${version}/parts/UpdateProcedurePart`,model, handle400Locally ? httpOptions_Handle400InComponent : httpOptions ) //p => p.Attributes.Count > 0 ----only adds FromBody     
            };
          
      
        
          
              updateScopingPart(model: ScopingPartUpdateV1Model,  handle400Locally: boolean = false) : Observable<UpdateResponseV1Model>  { 
              let version = this.classVersion;
              return this.http.post<any>(environment.apiRoot + `api/v${version}/parts/UpdateScopingPart`,model, handle400Locally ? httpOptions_Handle400InComponent : httpOptions ) //p => p.Attributes.Count > 0 ----only adds FromBody     
            };
          
      
        
          
              delete(partId: number,  handle400Locally: boolean = false) : Observable<CommonResponseV1Model>  { 
              let version = this.classVersion;
              return this.http.post<any>(environment.apiRoot + `api/v${version}/parts/Delete`,partId, handle400Locally ? httpOptions_Handle400InComponent : httpOptions ) //p => p.Attributes.Count > 0 ----only adds FromBody     
            };
          
      
        
          
              removeProcedureStepPart(procedureTaskStepPartId: number,  handle400Locally: boolean = false) : Observable<CommonResponseV1Model>  { 
              let version = this.classVersion;
              return this.http.post<any>(environment.apiRoot + `api/v${version}/parts/RemoveProcedureStepPart`,procedureTaskStepPartId, handle400Locally ? httpOptions_Handle400InComponent : httpOptions ) //p => p.Attributes.Count > 0 ----only adds FromBody     
            };
          
      
        
          
              removeScopingPart(scopeDocumentPartId: number,  handle400Locally: boolean = false) : Observable<CommonResponseV1Model>  { 
              let version = this.classVersion;
              return this.http.post<any>(environment.apiRoot + `api/v${version}/parts/RemoveScopingPart`,scopeDocumentPartId, handle400Locally ? httpOptions_Handle400InComponent : httpOptions ) //p => p.Attributes.Count > 0 ----only adds FromBody     
            };
          
      
        
          
              partInactive(partId: number,  handle400Locally: boolean = false) : Observable<UpdateResponseV1Model>  { 
              let version = this.classVersion;
              return this.http.post<any>(environment.apiRoot + `api/v${version}/parts/PartInactive`,partId, handle400Locally ? httpOptions_Handle400InComponent : httpOptions ) //p => p.Attributes.Count > 0 ----only adds FromBody     
            };
          
      

                        

                  
}

export class PartsV1Model {
    constructor(  
        // Inherited properties 
        
      
        //Properties
        
        public partId: number | null = null,   
        public name: string | null = null,   
        public shortName: string | null = null,   
        public idNumber: string | null = null,   
        public uom: string | null = null,   
        public isActive: boolean | null = null,   
        public createdByUserId: number | null = null,   
        public createdDate: Date | null = null,   
        public updatedByUserId: number | null = null,   
        public updatedDate: Date | null = null,   
        public createdByUserName: string | null = null,   
        public updatedByUserName: string | null = null,   
        public accountId: number | null = null,   
        public tagNames: string | null = null,   
      ) { }
}

export class PartsSearchV1Model {
    constructor(  
        // Inherited properties 
        
      
        //Properties
        
        public searchTerm: string | null = null,   
        public procedureRevisionId: number | null = null,   
        public procedureTaskStepId: number | null = null,   
      ) { }
}

export class PartScopingSearchV1Model {
    constructor(  
        // Inherited properties 
        
      
        //Properties
        
        public searchTerm: string | null = null,   
        public scopeDocumentId: number | null = null,   
        public procedureTaskStepId: number | null = null,   
      ) { }
}

export class ProcedureStepPartV1Model {
    constructor(  
        // Inherited properties 
        
      
        //Properties
        
        public procedureTaskStepPartId: number | null = null,   
        public procedureTaskStepId: number | null = null,   
        public partId: number | null = null,   
        public quantity: number | null = null,   
        public name: string | null = null,   
        public shortName: string | null = null,   
        public idNumber: string | null = null,   
        public uom: string | null = null,   
        public createdDate: Date | null = null,   
        public updatedDate: Date | null = null,   
        public accountId: number | null = null,   
      ) { }
}

export class ScopingPartV1Model {
    constructor(  
        // Inherited properties 
        
      
        //Properties
        
        public scopeDocumentPartId: number | null = null,   
        public scopeDocumentId: number | null = null,   
        public partId: number | null = null,   
        public quantity: number | null = null,   
        public name: string | null = null,   
        public shortName: string | null = null,   
        public idNumber: string | null = null,   
        public uom: string | null = null,   
        public createdDate: Date | null = null,   
        public updatedDate: Date | null = null,   
        public accountId: number | null = null,   
      ) { }
}

export class RevisionPartsV1Model {
    constructor(  
        // Inherited properties 
        
      
        //Properties
        
        public partId: number | null = null,   
        public quantity: number | null = null,   
        public name: string | null = null,   
        public shortName: string | null = null,   
        public idNumber: string | null = null,   
        public uom: string | null = null,   
      ) { }
}

export class InsertResponseV1Model  {
    constructor(  
        // Inherited properties 
        
        public success: boolean | null = null,
        public message: string | null = null,
      
        //Properties
        
        public identityId: number | null = null,   
        public identityIds: number[] | null = null,   
      ) { }
}

export class PartsInsertV1Model {
    constructor(  
        // Inherited properties 
        
      
        //Properties
        
        public name: string | null = null,   
        public shortName: string | null = null,   
        public idNumber: string | null = null,   
        public uom: string | null = null,   
        public isActive: boolean | null = null,   
        public partTags: TagsV1Model[] | null = null,   
      ) { }
}

export class TagsV1Model {
    constructor(  
        // Inherited properties 
        
      
        //Properties
        
        public tagId: number | null = null,   
        public tagName: string | null = null,   
        public accountId: number | null = null,   
      ) { }
}

export class ProcedureStepPartInsertV1Model {
    constructor(  
        // Inherited properties 
        
      
        //Properties
        
        public procedureTaskStepId: number | null = null,   
        public partId: number | null = null,   
        public quantity: number | null = null,   
      ) { }
}

export class ScopingPartInsertV1Model {
    constructor(  
        // Inherited properties 
        
      
        //Properties
        
        public scopeDocumentId: number | null = null,   
        public partId: number | null = null,   
        public quantity: number | null = null,   
      ) { }
}

export class UpdateResponseV1Model {
    constructor(  
        // Inherited properties 
        
      
        //Properties
        
        public totalRowsUpdated: number | null = null,   
      ) { }
}

export class PartsUpdateV1Model {
    constructor(  
        // Inherited properties 
        
      
        //Properties
        
        public partId: number | null = null,   
        public name: string | null = null,   
        public shortName: string | null = null,   
        public idNumber: string | null = null,   
        public uom: string | null = null,   
        public isActive: boolean | null = null,   
        public partTags: TagsV1Model[] | null = null,   
      ) { }
}

export class ProcedureStepPartUpdateV1Model {
    constructor(  
        // Inherited properties 
        
      
        //Properties
        
        public procedureTaskStepPartId: number | null = null,   
        public quantity: number | null = null,   
      ) { }
}

export class ScopingPartUpdateV1Model {
    constructor(  
        // Inherited properties 
        
      
        //Properties
        
        public scopeDocumentPartId: number | null = null,   
        public quantity: number | null = null,   
      ) { }
}

export class CommonResponseV1Model {
    constructor(  
        // Inherited properties 
        
      
        //Properties
        
        public success: boolean | null = null,   
        public message: string | null = null,   
      ) { }
}





/* 
     Debug Info:
     PrintDebugInfo - put a dollar sign in front of Print to enable this 
*/

