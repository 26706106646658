import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'executionIconColor'
})
export class ExecutionIconColorPipe implements PipeTransform {

  transform(status: number | null): object {
    switch (status) {
      case null:
        return { 'color': 'gray' };
      case 1:
        return { 'color': 'black' };
      case 2:
        return { 'color': 'black' };
      case 3:
        return { 'color': 'black' };
      case 4:
        return { 'color': 'green' };
      case 5:
        return { 'color': 'red' };
      case 6:
        return { 'color': 'black' };
      default:
        return { 'color': 'black' };
    }
  }
}
