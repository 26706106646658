import { Component, ElementRef, EventEmitter, Input, OnInit, Output, ViewChild } from "@angular/core";
import { NgbActiveModal } from "@ng-bootstrap/ng-bootstrap";
import { ToastrService } from "ngx-toastr";
import { BeforeUploadEventArgs, UploaderComponent, UploadingEventArgs } from "@syncfusion/ej2-angular-inputs";
import { environment } from "src/environments/environment";
import { AuthService } from "../../core/services/auth.service";
import { ImportType } from "src/app/models/enums";

@Component({
  selector: "app-excel-import-modal",
  templateUrl: "./excel-import-modal.component.html",
  styleUrls: ["./excel-import-modal.component.scss"],
})
export class ExcelImportModalComponent implements OnInit {
  @Input() public modalTitle: string = "Excel Import";
  @Input() public importType!: ImportType;
  @Output() public submitCompletedEvent = new EventEmitter();
  @ViewChild("uploadFiles", { static: true })
  public uploadObj!: UploaderComponent;
  @ViewChild("droparea") dropArea?: ElementRef;
  isLoading: boolean = false;
  previewUrl: any;
  public path: Object = {
    saveUrl: "",
    removeUrl: "",
  };

  constructor(private toastService: ToastrService, public activeModal: NgbActiveModal, private authService: AuthService) {}

  ngOnInit(): void {
    //Setting the end point path by import type
    switch (this.importType) {
      case ImportType.Tools:
        this.path = {
          saveUrl: environment.apiRoot + "api/v1/tools/importExcel",
          removeUrl: "",
        };
        break;
      case ImportType.Parts:
        this.path = {
          saveUrl: environment.apiRoot + "api/v1/parts/importExcel",
          removeUrl: "",
        };
        break;
      case ImportType.Equipments:
        this.path = {
          saveUrl: environment.apiRoot + "api/v1/equipments/importExcel",
          removeUrl: "",
        };
        break;
      case ImportType.QRCodes:
        this.path = {
          saveUrl: environment.apiRoot + "api/v1/qrcodes/importExcel",
          removeUrl: "",
        };
        break;
      case ImportType.WorkOrders:
        this.path = {
          saveUrl: environment.apiRoot + "api/v1/work/importExcel",
          removeUrl: "",
        };
        break;
      default:
        {
          this.toastService.error("Import type not specified");
        }
        break;
    }
  }

  ngAfterViewInit() {
    //Adds css for drag and drop
    const dropAreaElement = this.dropArea?.nativeElement;

    dropAreaElement.addEventListener("dragover", (e: any) => {
      e.preventDefault();
      dropAreaElement.classList.add("drag-over");
    });

    dropAreaElement.addEventListener("dragleave", () => {
      dropAreaElement.classList.remove("drag-over");
    });
  }

  beforeUpload(args: BeforeUploadEventArgs) {
    this.removeDropAreaCss();
    if (this.uploadObj.getFilesData()[0].type != "xlsx") {
      this.toastService.error("Invalid file type. File must be .xlsx.");
      this.uploadObj.clearAll();
      return;
    }
  }

  onUploadBegin(args: UploadingEventArgs) {
    args.currentRequest!.setRequestHeader("Authorization", "Bearer " + this.authService.getAccessToken());
    args.currentRequest!.setRequestHeader("I400Local", "");
    this.isLoading = true;
  }

  success(args: any) {
    this.uploadObj.clearAll();
    this.isLoading = false;
    this.toastService.success("Excel imported");
    this.submitCompletedEvent.emit();
    this.activeModal.close();
  }

  uploadError(args: any) {
    //Need to move this similar to Http500Interceptor
    try {
      let obj = JSON.parse(args.e.currentTarget.responseText);
      const data = obj.errors;
      const fields = Object.keys(obj.errors || {});

      fields.forEach((field) => {
        data[field].forEach((e: any) => {
          if (field == "Error" || field == "errors") {
            this.toastService.error(e);
          } else {
            this.toastService.error(field + ": " + e);
          }
        });
      });
    } catch (e) {
      this.toastService.error("Error uploading file");
    }

    // FailureEventArgs) {
    this.uploadObj.clearAll();
    this.isLoading = false;
    this.submitCompletedEvent.emit();
    this.activeModal.close();
  }

  removeDropAreaCss(): void {
    const dropAreaElement = this.dropArea?.nativeElement;
    dropAreaElement.classList.remove("drag-over");
  }
}
