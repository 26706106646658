import { Pipe, PipeTransform } from "@angular/core";
import { StepType } from "src/app/api/ProcedureTasks.service";

@Pipe({
  name: "stepTypeDisplay",
})
export class StepTypeDisplayPipe implements PipeTransform {
  transform(value: number): string {
    const stepTypeName = StepType[value]; // Get the enum text

    // Convert camelCase to spaced text
    const spacedText = stepTypeName
      .replace(/([a-z])([A-Z])/g, "$1 $2")
      .replace(/([A-Z])([A-Z][a-z])/g, "$1 $2");

    return spacedText;
  }
}
