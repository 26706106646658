import {
  Component,
  EventEmitter,
  Input,
  Output,

} from "@angular/core";
import { NgbActiveModal } from "@ng-bootstrap/ng-bootstrap";

import { DocumentTypeEnum } from "src/app/api/Document.service";

@Component({
  selector: "app-new-figure-modal",
  templateUrl: "./new-figure-modal.component.html",
  styleUrls: ["./new-figure-modal.component.scss"],
})
export class NewFigureModalComponent {
  @Output() public submitCompletedEvent = new EventEmitter();
  @Input() isLibrary: boolean = true;
  documentType: DocumentTypeEnum = DocumentTypeEnum.Figures;
  constructor(public activeModal: NgbActiveModal) {}

  ngOnInit(): void {}

  submitCompleted(identityId: number): void {
    this.submitCompletedEvent.emit(identityId);
    this.activeModal.close();
  }
}
