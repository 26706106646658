 
   
   import { Injectable } from '@angular/core';
   import { HttpClient, HttpHeaders, HttpErrorResponse } from '@angular/common/http';

   import { Observable, of, throwError  } from 'rxjs';

   import { environment } from '../../environments/environment';
   //import { LoggerService } from '../../modules/core/logger.service'
   import { GenericSearchResultV1Model } from '../../app/models/GenericSearchResultV1Model';
   import { PageableListV1Model } from '../../app/models/PageableListV1Model';

   const httpOptions = {
     headers: new HttpHeaders({ 'Content-Type': 'application/json' }),
     withCredentials: true
   };

   const httpOptions_Handle400InComponent = {
     headers: new HttpHeaders({ 'Content-Type': 'application/json', 'I400Local': '' }),
     withCredentials: true
   }
    
   @Injectable({
     providedIn: 'root'
   })
   export class ProcedureTasksService {

   
        private classVersion = "1.0";
        
         
      constructor(private http: HttpClient) { }
      
        
          
              getByID(procedureTaskId: number,  handle400Locally: boolean = false) : Observable<ProcedureTaskV1Model>  { 
              let version = this.classVersion;
              return this.http.get<any>(environment.apiRoot + `api/v${version}/procedureTasks/GetById/${procedureTaskId}`, handle400Locally ? httpOptions_Handle400InComponent : httpOptions )       
            };
          
      
        
          
              getTaskSteps(procedureTaskId: number,  handle400Locally: boolean = false) : Observable<ProcedureStepV1Model[]>  { 
              let version = this.classVersion;
              return this.http.get<any>(environment.apiRoot + `api/v${version}/procedureTasks/GetTaskSteps/${procedureTaskId}`, handle400Locally ? httpOptions_Handle400InComponent : httpOptions )       
            };
          
      
        
          
              getByTasksByID(procedureTabId: number,  handle400Locally: boolean = false) : Observable<ProcedureTaskV1Model[]>  { 
              let version = this.classVersion;
              return this.http.get<any>(environment.apiRoot + `api/v${version}/procedureTasks/GetByTasksByID/${procedureTabId}`, handle400Locally ? httpOptions_Handle400InComponent : httpOptions )       
            };
          
      

            


                  
        
          
              search(model: ProcedureTaskSearchV1Model,  handle400Locally: boolean = false) : Observable<ProcedureTaskV1Model>  { 
              let version = this.classVersion;
              return this.http.post<any>(environment.apiRoot + `api/v${version}/procedureTasks/Search`,model, handle400Locally ? httpOptions_Handle400InComponent : httpOptions ) //p => p.Attributes.Count > 0 ----only adds FromBody     
            };
          
      
        
          
              insert(model: ProcedureTaskInsertV1Model,  handle400Locally: boolean = false) : Observable<InsertResponseV1Model>  { 
              let version = this.classVersion;
              return this.http.post<any>(environment.apiRoot + `api/v${version}/procedureTasks/Insert`,model, handle400Locally ? httpOptions_Handle400InComponent : httpOptions ) //p => p.Attributes.Count > 0 ----only adds FromBody     
            };
          
      
        
          
              update(model: ProcedureTaskUpdateV1Model,  handle400Locally: boolean = false) : Observable<UpdateResponseV1Model>  { 
              let version = this.classVersion;
              return this.http.post<any>(environment.apiRoot + `api/v${version}/procedureTasks/Update`,model, handle400Locally ? httpOptions_Handle400InComponent : httpOptions ) //p => p.Attributes.Count > 0 ----only adds FromBody     
            };
          
      
        
          
              updateTaskOrder(model: UpdateProcedureTaskOrderV1Model,  handle400Locally: boolean = false) : Observable<ProceduresUpdateV1Model>  { 
              let version = this.classVersion;
              return this.http.post<any>(environment.apiRoot + `api/v${version}/procedureTasks/UpdateTaskOrder`,model, handle400Locally ? httpOptions_Handle400InComponent : httpOptions ) //p => p.Attributes.Count > 0 ----only adds FromBody     
            };
          
      
        
          
              delete(procedureTaskId: number,  handle400Locally: boolean = false) : Observable<CommonResponseV1Model>  { 
              let version = this.classVersion;
              return this.http.post<any>(environment.apiRoot + `api/v${version}/procedureTasks/Delete`,procedureTaskId, handle400Locally ? httpOptions_Handle400InComponent : httpOptions ) //p => p.Attributes.Count > 0 ----only adds FromBody     
            };
          
      

                        

                  
}

export class ProcedureTaskV1Model {
    constructor(  
        // Inherited properties 
        
      
        //Properties
        
        public procedureTaskId: number | null = null,   
        public task: string | null = null,   
        public procedureTabId: number | null = null,   
        public createdByUserId: number | null = null,   
        public createdDate: Date | null = null,   
        public createdByUser: string | null = null,   
        public updatedByUserId: number | null = null,   
        public updatedDate: Date | null = null,   
        public updatedByUser: string | null = null,   
        public accountId: number | null = null,   
        public sort: number | null = null,   
        public prerequisite: boolean | null = null,   
      ) { }
}

export class ProcedureTaskSearchV1Model {
    constructor(  
        // Inherited properties 
        
      
        //Properties
        
        public procedureTabId: number | null = null,   
        public task: string | null = null,   
      ) { }
}

export class ProcedureStepV1Model {
    constructor(  
        // Inherited properties 
        
      
        //Properties
        
        public procedureTaskStepId: number | null = null,   
        public procedureTaskId: number | null = null,   
        public taskTypeId: number | null = null,   
        public task: string | null = null,   
        public title: string | null = null,   
        public step: string | null = null,   
        public stepName: string | null = null,   
        public sequential: boolean | null = null,   
        public critical: boolean | null = null,   
        public duration: number | null = null,   
        public repeatedCount: number | null = null,   
        public holdId: number | null = null,   
        public holdName: string | null = null,   
        public createdByUserId: number | null = null,   
        public createdDate: Date | null = null,   
        public updatedByUserId: number | null = null,   
        public updatedDate: Date | null = null,   
        public accountId: number | null = null,   
        public stepNumber: number | null = null,   
        public totalSteps: number | null = null,   
        public stepType: StepType | null = null,   
        public sort: number | null = null,   
        public prerequisiteStep: boolean | null = null,   
        public durationTolerance: number | null = null,   
        public endStep: boolean | null = null,   
        public failureStep: boolean | null = null,   
        public instructions: ProcedureStepInstructionV1Model[] | null = null,   
        public fields: ProcedureTaskStepFieldV1Model[] | null = null,   
      ) { }
}

export class ProcedureStepInstructionV1Model {
    constructor(  
        // Inherited properties 
        
      
        //Properties
        
        public procedureTaskStepInstructionId: number | null = null,   
        public instruction: string | null = null,   
        public procedureTaskStepId: number | null = null,   
        public sort: number | null = null,   
      ) { }
}

export class ProcedureTaskStepFieldV1Model {
    constructor(  
        // Inherited properties 
        
      
        //Properties
        
        public procedureTaskStepFieldId: number | null = null,   
        public fieldLabel: string | null = null,   
        public postFieldLabel: string | null = null,   
        public procedureTaskStepInstance: number | null = null,   
        public fieldType: number | null = null,   
        public createdByUserId: number | null = null,   
        public updatedByUserId: number | null = null,   
        public createdDate: Date | null = null,   
        public upatedDate: Date | null = null,   
        public rules: ProcedureRuleV1Model[] | null = null,   
        public workOrderFieldValues: WorkOrderFieldValueV1Model[] | null = null,   
      ) { }
}

export class ProcedureRuleV1Model {
    constructor(  
        // Inherited properties 
        
      
        //Properties
        
        public procedureTaskStepRuleId: number | null = null,   
        public procedureTaskStepId: number | null = null,   
        public ruleTypeId: RuleType | null = null,   
        public procedureTaskStepFieldId: number | null = null,   
        public name: string | null = null,   
        public successProcedureTaskStepId: number | null = null,   
        public successProcedureTaskStepName: string | null = null,   
        public failureProcedureTaskStepId: number | null = null,   
        public failureProcedureTaskStepName: string | null = null,   
        public createdByUserId: number | null = null,   
        public createdByUserName: string | null = null,   
        public updatedByUserId: number | null = null,   
        public updatedByUserName: string | null = null,   
        public createdDate: Date | null = null,   
        public updatedDate: Date | null = null,   
        public operand: OperandEnum | null = null,   
        public mathOperator: MathOperatorEnum | null = null,   
        public mathFieldId1: number | null = null,   
        public mathFieldId2: number | null = null,   
        public values: ProcedureRuleValueV1Model[] | null = null,   
      ) { }
}

export class ProcedureRuleValueV1Model {
    constructor(  
        // Inherited properties 
        
      
        //Properties
        
        public procedureTaskStepRuleValueId: number | null = null,   
        public procedureTaskStepRuleId: number | null = null,   
        public successProcedureTaskStepId: number | null = null,   
        public value: string | null = null,   
        public value2: string | null = null,   
        public createdByUserId: number | null = null,   
        public createdDate: Date | null = null,   
        public updatedByUserId: number | null = null,   
        public updatedDate: Date | null = null,   
      ) { }
}

export class WorkOrderFieldValueV1Model {
    constructor(  
        // Inherited properties 
        
      
        //Properties
        
        public workOrderFieldValueId: number | null = null,   
        public workOrderId: number | null = null,   
        public procedureTaskStepFieldId: number | null = null,   
        public procedureTaskStepInstance: number | null = null,   
        public value: string | null = null,   
        public createdByUserId: number | null = null,   
        public createdDate: Date | null = null,   
        public updatedByUserId: number | null = null,   
        public updatedDate: Date | null = null,   
        public createdByUserName: string | null = null,   
        public updatedByUserName: string | null = null,   
        public accountId: number | null = null,   
      ) { }
}

export class InsertResponseV1Model  {
    constructor(  
        // Inherited properties 
        
        public success: boolean | null = null,
        public message: string | null = null,
      
        //Properties
        
        public identityId: number | null = null,   
        public identityIds: number[] | null = null,   
      ) { }
}

export class ProcedureTaskInsertV1Model {
    constructor(  
        // Inherited properties 
        
      
        //Properties
        
        public task: string | null = null,   
        public procedureTabId: number | null = null,   
      ) { }
}

export class UpdateResponseV1Model {
    constructor(  
        // Inherited properties 
        
      
        //Properties
        
        public totalRowsUpdated: number | null = null,   
      ) { }
}

export class ProcedureTaskUpdateV1Model {
    constructor(  
        // Inherited properties 
        
      
        //Properties
        
        public procedureTaskId: number | null = null,   
        public task: string | null = null,   
      ) { }
}

export class ProceduresUpdateV1Model {
    constructor(  
        // Inherited properties 
        
      
        //Properties
        
        public procedureId: number | null = null,   
        public name: string | null = null,   
        public procedureNumber: string | null = null,   
        public scopeDocumentId: number | null = null,   
      ) { }
}

export class UpdateProcedureTaskOrderV1Model {
    constructor(  
        // Inherited properties 
        
      
        //Properties
        
        public procedureTabId: number | null = null,   
        public procedureTaskId: number | null = null,   
        public sort: number | null = null,   
      ) { }
}

export class CommonResponseV1Model {
    constructor(  
        // Inherited properties 
        
      
        //Properties
        
        public success: boolean | null = null,   
        public message: string | null = null,   
      ) { }
}



export enum StepType
    { 
       Concurrent = 1,
       Continuous = 2,
       EquallyAcceptableAlternative = 3,
       Repeated = 4,
       Standard = 5,
       TimeDependent = 6,
       Equipment = 7,
       LaborResources = 8,
       Parts = 9,
       Tools = 10,
       ContinuousEnd = 11,
    }

export enum RuleType
    { 
       Concurrent = 1,
       Continuous = 2,
       EquallyAcceptableAlternative = 3,
       Repeated = 4,
       Standard = 5,
       TimeDependent = 6,
       SuccessFailure = 7,
       Required = 8,
       CalculatedValue = 10,
       ContinuousEnd = 11,
       ValidOptionsSuccess = 12,
    }

export enum OperandEnum
    { 
       Equal = 1,
       GreaterThan = 2,
       LessThan = 3,
       GreaterThanOrEqual = 4,
       LessThanOrEqual = 5,
       Between = 6,
    }

export enum MathOperatorEnum
    { 
       Plus = 1,
       Minus = 2,
       Multiply = 3,
       Divide = 4,
    }



/* 
     Debug Info:
     PrintDebugInfo - put a dollar sign in front of Print to enable this 
*/

