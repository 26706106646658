import { Pipe, PipeTransform } from "@angular/core";

@Pipe({
  name: "numberToCharacter",
})
export class NumberToCharacterPipe implements PipeTransform {
  transform(value: number): string {
    return this.numberToCharacter(value);
  }

  public numberToCharacter(value: number): string {
    let letters = "";

    while (value > 1) {
      letters = "ABCDEFGHIJKLMNOPQRSTUVWXYZ"[(value - 2) % 26] + letters;
      value = Math.floor(value / 26) - 1;
    }

    return letters;
  }
}
