import { BehaviorSubject, Observable, Subject } from "rxjs";
import { Injectable } from "@angular/core";
import { ProcedureRevisionService } from "src/app/api/ProcedureRevision.service";
import { ProcedureStepV1Model } from "src/app/api/ProcedureTasks.service";

@Injectable({
  providedIn: "root",
})
export class WritingService {
  private procedureTaskStepIdSubject: BehaviorSubject<number> = new BehaviorSubject<number>(0);

  private procedureTaskIdSubject: BehaviorSubject<number> = new BehaviorSubject<number>(0);

  private procedureStepSubject: BehaviorSubject<ProcedureStepV1Model | null> =
    new BehaviorSubject<ProcedureStepV1Model | null>(null);

  procedureTaskStepId$: Observable<number> = this.procedureTaskStepIdSubject.asObservable();

  procedureTaskId$: Observable<number> = this.procedureTaskIdSubject.asObservable();

  procedureStep$: Observable<ProcedureStepV1Model | null> = this.procedureStepSubject.asObservable();

  procedureId$: BehaviorSubject<number> = new BehaviorSubject<number>(0);

  procedureRevisionId$: BehaviorSubject<number> = new BehaviorSubject<number>(0);

  isDraft$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(true);

  currentProcedureRevisionId: number | null = null;

  isInit: boolean = false;

  scopeDocumentId: number | null = null;

  isContinuous$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);

  constructor(private procedureRevisionService: ProcedureRevisionService) {}

  changeRevision(procedureRevisionId: number | null, isDraft: boolean | null) {
    if (procedureRevisionId != null) {
      this.changeDraftStatus(isDraft!);
      this.procedureRevisionId$.next(procedureRevisionId);
      this.currentProcedureRevisionId = procedureRevisionId;
    }
  }

  changeProcedureTaskId(procedureTaskId: number) {
    this.procedureTaskIdSubject.next(procedureTaskId);
  }

  changeProcedureTaskStepId(procedureTaskStepId: number) {
    this.procedureTaskStepIdSubject.next(procedureTaskStepId);
  }

  changeProcedureStep(procedureStep: ProcedureStepV1Model | null) {
    this.procedureStepSubject.next(procedureStep);
  }

  changeDraftStatus(isDraft: boolean | true) {
    this.isDraft$.next(isDraft);
  }

  changeLevelOfUse(levelOfUse: string) {
    if (levelOfUse == "Continuous") {
      this.isContinuous$.next(true);
    } else {
      this.isContinuous$.next(false);
    }
  }

  initializeProcedureRevisionId(procedureId: number) {
    if (!this.isInit || procedureId != this.procedureId$.getValue()) {
      this.procedureId$.next(procedureId);
      this.procedureRevisionService.getLatest(procedureId).subscribe((procedureRevision) => {
        this.scopeDocumentId = procedureRevision.scopeDocumentId;
        this.changeRevision(procedureRevision.procedureRevisionId, procedureRevision.isDraft);
        this.changeDraftStatus(procedureRevision.isDraft!);
        this.changeLevelOfUse(procedureRevision.levelOfUse);
      });
    }
    this.isInit = true;
  }
}
