import { Component, EventEmitter, Input, Output } from "@angular/core";
import { NgbActiveModal } from "@ng-bootstrap/ng-bootstrap";
import { DelayCodesService, DelayCodesV1Model } from "src/app/api/DelayCodes.service";
import { ExecutionService, UpdateLogEntryV1Model } from "src/app/api/Execution.service";
import { FormRepoService } from "../services/form-repo.service";

@Component({
  selector: "app-progress-save-modal",
  templateUrl: "./progress-save-modal.component.html",
  styleUrls: ["./progress-save-modal.component.scss"],
})
export class ProgressSaveModalComponent {
  @Input() public progressSave: UpdateLogEntryV1Model = new UpdateLogEntryV1Model();
  @Output() public actionConfirmedEvent = new EventEmitter();
  @Output() public actionCancelEvent = new EventEmitter();
  public delayCodes: DelayCodesV1Model[] = [];
  public delayOptions: DelayCodesV1Model[] = [];
  public labelText: string = "Pause Code";

  saveProgressForm = this.formRepoService.saveProgressForm();

  constructor(
    private executionService: ExecutionService,
    public activeModal: NgbActiveModal,
    private delayCodeService: DelayCodesService,
    private formRepoService: FormRepoService
  ) {}

  ngOnInit(): void {
    this.onResetProcedureInstance();

    this.reasonSubscription();

    this.delayCodeService.getAccountDelayCodes().subscribe((data) => {
      this.delayCodes = data;
      this.delayOptions = this.delayCodes.filter((x) => x.pauseCode == true);
    });
  }

  reasonSubscription(): void {
    this.saveProgressForm.controls["reason"].valueChanges.subscribe((value) => {
      if (value == "Pause") {
        this.delayOptions = this.delayCodes.filter((x) => x.pauseCode == true);
        this.labelText = "Pause Code";
      } else if (value == "Delay") {
        this.delayOptions = this.delayCodes.filter((x) => x.pauseCode == false);
        this.labelText = "Delay Code";
      }
    });
  }

  onConfirm(): void {
    this.progressSave.current!.delayCodeId = this.saveProgressForm.controls["delayCodeId"].value;

    this.executionService.updateLogEntry(this.progressSave).subscribe((data) => {});
    this.actionConfirmedEvent.emit(true);
  }

  onCancel(): void {
    this.actionCancelEvent.emit(false);
    this.activeModal.close();
  }

  onResetProcedureInstance(): void {
    this.progressSave.current!.delayCodeId = 42;
    this.executionService.resetLogEntry(this.progressSave).subscribe((data) => {});
  }
}
