import { Component, EventEmitter, Input, Output } from "@angular/core";

@Component({
  selector: "app-alert-modal",
  templateUrl: "./alert-modal.component.html",
  styleUrls: ["./alert-modal.component.scss"],
})
export class AlertModalComponent {
  @Input() modalTitle: string = "Alert";
  @Input() alertColor: string = "warning";
  @Input() alertText: string = "Alert!";
  @Input() showSubmit: boolean = false;
  @Input() submitText: string = "Submit";
  @Input() closeText: string = "Close";
  @Output() actionConfirmedEvent = new EventEmitter();
  @Output() actionCancelEvent = new EventEmitter();
  constructor() {}

  ngOnInit(): void {}

  onConfirm(): void {
    this.actionConfirmedEvent.emit(true);
  }

  onCancel(): void {
    this.actionCancelEvent.emit(false);
  }
}
