 
   
   import { Injectable } from '@angular/core';
   import { HttpClient, HttpHeaders, HttpErrorResponse } from '@angular/common/http';

   import { Observable, of, throwError  } from 'rxjs';

   import { environment } from '../../environments/environment';
   //import { LoggerService } from '../../modules/core/logger.service'
   import { GenericSearchResultV1Model } from '../../app/models/GenericSearchResultV1Model';
   import { PageableListV1Model } from '../../app/models/PageableListV1Model';

   const httpOptions = {
     headers: new HttpHeaders({ 'Content-Type': 'application/json' }),
     withCredentials: true
   };

   const httpOptions_Handle400InComponent = {
     headers: new HttpHeaders({ 'Content-Type': 'application/json', 'I400Local': '' }),
     withCredentials: true
   }
    
   @Injectable({
     providedIn: 'root'
   })
   export class DocumentService {

   
        private classVersion = "1.0";
        
         
      constructor(private http: HttpClient) { }
      

            


                  
        
          
              search(model: DocumentSearchV1Model,  handle400Locally: boolean = false) : Observable<DocumentV1Model[]>  { 
              let version = this.classVersion;
              return this.http.post<any>(environment.apiRoot + `api/v${version}/documents/Search`,model, handle400Locally ? httpOptions_Handle400InComponent : httpOptions ) //p => p.Attributes.Count > 0 ----only adds FromBody     
            };
          
      
        
          
              insert(model: DocumentUploadV1Model,  handle400Locally: boolean = false) : Observable<InsertResponseV1Model>  { 
              let version = this.classVersion;
              return this.http.post<any>(environment.apiRoot + `api/v${version}/documents/Insert`,model, handle400Locally ? httpOptions_Handle400InComponent : httpOptions ) //p => p.Attributes.Count > 0 ----only adds FromBody     
            };
          
      
        
          
              updateDocument(model: DocumentUploadV1Model,  handle400Locally: boolean = false) : Observable<UpdateResponseV1Model>  { 
              let version = this.classVersion;
              return this.http.post<any>(environment.apiRoot + `api/v${version}/documents/UpdateDocument`,model, handle400Locally ? httpOptions_Handle400InComponent : httpOptions ) //p => p.Attributes.Count > 0 ----only adds FromBody     
            };
          
      
        
          
              downloadDocument(document: DocumentV1Model,  handle400Locally: boolean = false) : Observable<DocumentV1Model>  { 
              let version = this.classVersion;
              return this.http.post<any>(environment.apiRoot + `api/v${version}/documents/DownloadDocument`,document, handle400Locally ? httpOptions_Handle400InComponent : httpOptions ) //p => p.Attributes.Count > 0 ----only adds FromBody     
            };
          
      
        
          
              getExecutionAttachments(model: WorkOrderAttachmentSearchV1Model,  handle400Locally: boolean = false) : Observable<WorkOrderAttachmentV1Model[]>  { 
              let version = this.classVersion;
              return this.http.post<any>(environment.apiRoot + `api/v${version}/documents/GetExecutionAttachments`,model, handle400Locally ? httpOptions_Handle400InComponent : httpOptions ) //p => p.Attributes.Count > 0 ----only adds FromBody     
            };
          
      
        
          
              insertExecutionComment(model: DocumentUploadV1Model,  handle400Locally: boolean = false) : Observable<InsertResponseV1Model>  { 
              let version = this.classVersion;
              return this.http.post<any>(environment.apiRoot + `api/v${version}/documents/InsertExecutionComment`,model, handle400Locally ? httpOptions_Handle400InComponent : httpOptions ) //p => p.Attributes.Count > 0 ----only adds FromBody     
            };
          
      
        
          
              updateExecutionComment(model: WorkOrderAttachmentV1Model,  handle400Locally: boolean = false) : Observable<UpdateResponseV1Model>  { 
              let version = this.classVersion;
              return this.http.post<any>(environment.apiRoot + `api/v${version}/documents/UpdateExecutionComment`,model, handle400Locally ? httpOptions_Handle400InComponent : httpOptions ) //p => p.Attributes.Count > 0 ----only adds FromBody     
            };
          
      
        
          
              deleteExecutionAttachment(workOrderAttachmentId: number,  handle400Locally: boolean = false) : Observable<CommonResponseV1Model>  { 
              let version = this.classVersion;
              return this.http.post<any>(environment.apiRoot + `api/v${version}/documents/DeleteExecutionAttachment`,workOrderAttachmentId, handle400Locally ? httpOptions_Handle400InComponent : httpOptions ) //p => p.Attributes.Count > 0 ----only adds FromBody     
            };
          
      

                        

                  
}

export class DocumentV1Model {
    constructor(  
        // Inherited properties 
        
      
        //Properties
        
        public documentId: number | null = null,   
        public fileBase64: string | null = null,   
        public displayName: string | null = null,   
        public fileName: string | null = null,   
        public documentGuid: string | null = null,   
        public isActive: boolean | null = null,   
        public createdDate: Date | null = null,   
        public createdByUserId: number | null = null,   
        public createdByUserName: string | null = null,   
        public contentType: string | null = null,   
        public tagNames: string | null = null,   
      ) { }
}

export class DocumentSearchV1Model {
    constructor(  
        // Inherited properties 
        
      
        //Properties
        
        public documentType: DocumentTypeEnum | null = null,   
        public search_term: string | null = null,   
        public sort: string | null = null,   
      ) { }
}

export class InsertResponseV1Model  {
    constructor(  
        // Inherited properties 
        
        public success: boolean | null = null,
        public message: string | null = null,
      
        //Properties
        
        public identityId: number | null = null,   
        public identityIds: number[] | null = null,   
      ) { }
}

export class DocumentUploadV1Model {
    constructor(  
        // Inherited properties 
        
      
        //Properties
        
        public fileName: string | null = null,   
        public fileBase64: string | null = null,   
        public documentType: DocumentTypeEnum | null = null,   
        public documentId: number | null = null,   
        public isUpdate: boolean | null = null,   
        public displayName: string | null = null,   
        public citations: string | null = null,   
        public accountId: number | null = null,   
        public isActive: boolean | null = null,   
        public documentTags: TagsV1Model[] | null = null,   
        public workOrderId: number | null = null,   
        public procedureTaskStepId: number | null = null,   
        public procedureTaskStepInstance: number | null = null,   
      ) { }
}

export class TagsV1Model {
    constructor(  
        // Inherited properties 
        
      
        //Properties
        
        public tagId: number | null = null,   
        public tagName: string | null = null,   
        public accountId: number | null = null,   
      ) { }
}

export class UpdateResponseV1Model {
    constructor(  
        // Inherited properties 
        
      
        //Properties
        
        public totalRowsUpdated: number | null = null,   
      ) { }
}

export class WorkOrderAttachmentV1Model {
    constructor(  
        // Inherited properties 
        
      
        //Properties
        
        public workOrderAttachmentId: number | null = null,   
        public documentId: number | null = null,   
        public workOrderId: number | null = null,   
        public procedureTaskStepId: number | null = null,   
        public procedureTaskStepInstance: number | null = null,   
        public step: string | null = null,   
        public stepNumber: number | null = null,   
        public comment: string | null = null,   
        public displayName: string | null = null,   
        public fileName: string | null = null,   
        public documentGuid: string | null = null,   
        public createdByUserName: string | null = null,   
        public updatedByUserName: string | null = null,   
        public createdDate: Date | null = null,   
        public updatedDate: Date | null = null,   
      ) { }
}

export class WorkOrderAttachmentSearchV1Model {
    constructor(  
        // Inherited properties 
        
      
        //Properties
        
        public workOrderId: number | null = null,   
        public procedureTaskStepId: number | null = null,   
        public procedureTaskStepInstance: number | null = null,   
      ) { }
}

export class CommonResponseV1Model {
    constructor(  
        // Inherited properties 
        
      
        //Properties
        
        public success: boolean | null = null,   
        public message: string | null = null,   
      ) { }
}



export enum DocumentTypeEnum
    { 
       Figures = 0,
       Permits = 1,
       Logo = 2,
       Execution = 3,
    }



/* 
     Debug Info:
     PrintDebugInfo - put a dollar sign in front of Print to enable this 
*/

