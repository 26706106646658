import { Component } from "@angular/core";

@Component({
  selector: "app-copyright-info",
  templateUrl: "./copyright-info.component.html",
  styleUrls: ["./copyright-info.component.scss"],
})
export class CopyrightInfoComponent {
  year: number = new Date().getFullYear();
}
