import { Injectable } from "@angular/core";
import { AbstractControl, FormArray, FormBuilder, FormControl, FormGroup, Validators } from "@angular/forms";

@Injectable({
  providedIn: "root",
})
// Using this as a shared service for getting copies of various forms. Might help not having to recreate the form every time we need one on a different page.
// Might be able to define like a "Base" form model for each model and then make modifications to it on the functions to return like an edit version or a new version.
export class FormRepoService {
  constructor(private formBuilder: FormBuilder) {}

  //quick mock of the new account form
  newAccountForm(): FormGroup {
    return this.formBuilder.group({
      name: ["", [Validators.required]],
      ownerName: ["", [Validators.required]],
      accountTypeId: ["", [Validators.required]],
      accountNumber: ["", [Validators.required]],
      country: ["US", [Validators.required]],
      internationalAddress: [""],
      address: ["", [Validators.required]],
      city: ["", [Validators.required]],
      state: ["", [Validators.required]],
      zip: ["", [Validators.required]],
      accountPhone: ["", [Validators.required]],
      accountEmail: ["", [Validators.required]],
      isActive: ["true", [Validators.required]],
      parentAccountId: [0],
    });
  }

  newWorkOrderForm(): FormGroup {
    return this.formBuilder.group({
      workOrder: ["", [Validators.required]],
      requestedDate: ["", [Validators.required]],
      requestedTime: ["", [Validators.required]],
      technicianUserId: [""],
      procedureRevisionId: [null, [Validators.required]],
    });
  }

  manageAccountLicensesForm(): FormGroup {
    return this.formBuilder.group({
      licensesScoping: [0, [Validators.required]],
      licensesWriting: [0, [Validators.required]],
      licensesExecution: [0, [Validators.required]],
      licensesScheduling: [0, [Validators.required]],
      licensesAnalytics: [0, [Validators.required]],
    });
  }

  newDelayCodeForm(): FormGroup {
    return this.formBuilder.group({
      delayCode: ["", [Validators.required]],
      delayName: ["", [Validators.required]],
      description: ["", [Validators.required]],
      isActive: ["true", [Validators.required]],
    });
  }

  newPartsForm(): FormGroup {
    return this.formBuilder.group({
      name: ["", [Validators.required]],
      shortName: ["", [Validators.required]],
      idNumber: ["", [Validators.required]],
      uom: ["", [Validators.required]],
      isActive: ["true", [Validators.required]],
    });
  }

  newToolForm(): FormGroup {
    return this.formBuilder.group({
      name: ["", [Validators.required]],
      shortName: ["", [Validators.required]],
      toolNumber: [null, [Validators.required]],
      isActive: ["true", [Validators.required]],
    });
  }

  newHoldCodeForm(): FormGroup {
    return this.formBuilder.group({
      holdName: ["", [Validators.required]],
      approvedByGroupId: ["", [Validators.required]],
      releaseCode: ["", [Validators.required]],
      isActive: ["true", [Validators.required]],
    });
  }

  newLaborForm(): FormGroup {
    return this.formBuilder.group({
      position: ["", [Validators.required]],
      shortName: ["", [Validators.required]],
      isActive: ["true", [Validators.required]],
      icon: ["None"],
    });
  }

  newLaborToStep(): FormGroup {
    return this.formBuilder.group({
      quantity: ["1", [Validators.required]],
    });
  }

  newHazardForm(): FormGroup {
    return this.formBuilder.group({
      description: ["", [Validators.required]],
      isActive: ["true", [Validators.required]],
    });
  }

  newPrecautionForm(): FormGroup {
    return this.formBuilder.group({
      description: ["", [Validators.required]],
      isActive: ["true", [Validators.required]],
    });
  }

  newProcedureForm(): FormGroup {
    return this.formBuilder.group({
      name: ["", [Validators.required]],
      procedureNumber: [null, [Validators.required]],
      scopeDocumentId: [null],
      levelOfUse: ["Continuous", [Validators.required]],
    });
  }

  newScopingForm(): FormGroup {
    return this.formBuilder.group({
      scopeName: ["", [Validators.required]],
      description: ["", [Validators.required]],
      isActive: ["true", [Validators.required]],
    });
  }

  newProcedureRevisionForm(): FormGroup {
    return this.formBuilder.group({
      purpose: [""],
      scope: [""],
      isDraft: [true],
    });
  }

  newNotesForm(): FormGroup {
    return this.formBuilder.group({
      note: [""],
    });
  }

  editRevisionForm(): FormGroup {
    return this.formBuilder.group({
      levelOfUse: ["", Validators.required],
      departmentOwner: ["", Validators.required],
    });
  }

  newProcedurePublishForm(): FormGroup {
    return this.formBuilder.group({
      effectiveDate: ["", [Validators.required]],
    });
  }

  newEquipmentForm(): FormGroup {
    return this.formBuilder.group({
      name: ["", [Validators.required]],
      shortName: ["", [Validators.required]],
      idNumber: ["", [Validators.required]],
      isActive: ["true", [Validators.required]],
    });
  }

  newUsersForm(): FormGroup {
    return this.formBuilder.group({
      firstName: ["", [Validators.required]],
      lastName: ["", [Validators.required]],
      jobTitle: ["", [Validators.required]],
      country: ["US"],
      internationalAddress: [""],
      address: [""],
      city: [""],
      state: [""],
      zip: [""],
      phone: ["", [Validators.required]],
      email: ["", [Validators.required]],
      password: [
        "",
        [
          Validators.required,
          Validators.minLength(8),
          Validators.pattern(/^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[$@$!%*?&]).*$/),
        ],
      ],
      //roleId: ["", [Validators.required]],
      isActive: [true, [Validators.required]],
      confirmPassword: ["", [Validators.required]],
    });
  }

  editUsersForm(): FormGroup {
    return this.formBuilder.group({
      firstName: ["", [Validators.required]],
      lastName: ["", [Validators.required]],
      jobTitle: ["", [Validators.required]],
      country: ["US"],
      internationalAddress: [""],
      address: [""],
      city: [""],
      state: [""],
      zip: [""],
      phone: ["", [Validators.required]],
      email: ["", [Validators.required]],
      //roleId: ["", [Validators.required]],
      isActive: [true, [Validators.required]],
    });
  }

  newFigureForm(): FormGroup {
    return this.formBuilder.group({
      fileName: ["", [Validators.required]],
      tags: ["", [Validators.required]],
    });
  }

  newRoleForm(): FormGroup {
    return this.formBuilder.group({
      role: ["", [Validators.required]],
      isGlobal: [false, [Validators.required]],
      allowDelete: [true, [Validators.required]],
      licenseNeededScoping: [false, [Validators.required]],
      licenseNeededWriting: [false, [Validators.required]],
      licenseNeededExecution: [false, [Validators.required]],
      licenseNeededScheduling: [false, [Validators.required]],
      licenseNeededAnalytics: [false, [Validators.required]],
    });
  }

  newTagForm(): FormGroup {
    return this.formBuilder.group({
      tagName: ["", [Validators.required]],
    });
  }

  newProcedureTabForm(): FormGroup {
    return this.formBuilder.group({
      title: ["", [Validators.required]],
      description: ["", [Validators.required]],
    });
  }

  newProcedureTaskForm(): FormGroup {
    return this.formBuilder.group({
      task: ["", [Validators.required]],
    });
  }

  newProcedureTaskStepForm(): FormGroup {
    return this.formBuilder.group({
      step: [""],
      stepType: [5, [Validators.required]],
      duration: [1, [Validators.required]],
      durationTolerance: [15, [Validators.required]],
      sequential: [true, [Validators.required]],
      repeatedCount: [null],
      holdId: [null],
      critical: [false, [Validators.required]],
    });
  }

  newEquallyAcceptableAlternativeStepForm(): FormGroup {
    return this.formBuilder.group({
      failureStep: [null],
      successStep: [null],
      stepValues: new FormArray([]),
    });
  }

  newProcedureStepIntructionForm(): FormGroup {
    return this.formBuilder.group({
      instruction: ["", [Validators.required]],
      sort: [0],
    });
  }

  newToolToStep(): FormGroup {
    return this.formBuilder.group({
      quantity: ["1", [Validators.required]],
    });
  }

  newEquipmentToStep(): FormGroup {
    return this.formBuilder.group({
      quantity: ["1", [Validators.required]],
    });
  }

  newPartToStep(): FormGroup {
    return this.formBuilder.group({
      quantity: ["1", [Validators.required]],
    });
  }

  newFieldForm(): FormGroup {
    return this.formBuilder.group({
      fieldLabel: ["", Validators.required],
      postFieldLabel: [""],
      fieldType: [7, Validators.required],
    });
  }

  newCalculatedFieldForm(): FormGroup {
    return this.formBuilder.group({
      mathFieldId1: ["", Validators.required],
      mathFieldId2: ["", Validators.required],
      mathOperator: ["", Validators.required],
      operand: ["", Validators.required],
      value: ["", Validators.required],
      value2: [null],
      successProcedureTaskStepId: [null, Validators.required],
      failureProcedureTaskStepId: [null, Validators.required],
    });
  }

  newRuleForm(): FormGroup {
    return this.formBuilder.group({
      name: ["", Validators.required],
      ruleTypeId: ["", Validators.required],
    });
  }

  newSuccessFailureForm(): FormGroup {
    return this.formBuilder.group({
      operand: [1, Validators.required],
      value: ["", Validators.required],
      value2: [null],
      successProcedureTaskStepId: [null, Validators.required],
      failureProcedureTaskStepId: [null, Validators.required],
    });
  }

  newValidOptionsForm(): FormGroup {
    return this.formBuilder.group({
      validOptionsArray: this.formBuilder.array([]),
    });
  }

  newValidOptionsSuccessForm(): FormGroup {
    return this.formBuilder.group({
      validOptionsArray: this.formBuilder.array([this.createValidOptionFormGroup()]),
    });
  }

  createValidOptionFormGroup(): FormGroup {
    return this.formBuilder.group({
      value: ["", Validators.required],
      successProcedureTaskStepId: ["", Validators.required],
    });
  }

  newEquallyAcceptableAlternativeForm(): FormGroup {
    return this.formBuilder.group({
      failureStep: [null],
      successStep: [null],
      stepValues: new FormArray([]),
    });
  }

  newConcurrentForm(): FormGroup {
    return this.formBuilder.group({
      ranges: new FormArray([]),
    });
  }

  newTimeDependentForm(): FormGroup {
    return this.formBuilder.group({
      time: ["1", Validators.required],
      startProcedureTaskStepId: [null, Validators.required],
      endProcedureTaskStepId: [null, Validators.required],
    });
  }

  newRepeatedForm(): FormGroup {
    return this.formBuilder.group({
      repeatedCount: ["", Validators.required],
      startProcedureTaskStepId: [null, Validators.required],
      endProcedureTaskStepId: [null, Validators.required],
    });
  }

  newContinuousForm(showFieldLabel: boolean = false): FormGroup {
    var formGroup;
    if (showFieldLabel) {
      formGroup = this.formBuilder.group({
        fieldLabel: ["", Validators.required],
        successProcedureTaskStepId: [null, Validators.required],
        failureProcedureTaskStepId: [null, Validators.required],
      });
    } else {
      formGroup = this.formBuilder.group({
        successProcedureTaskStepId: [null, Validators.required],
        failureProcedureTaskStepId: [null, Validators.required],
      });
    }

    return formGroup;
  }

  continuousEndForm(): FormGroup {
    return this.formBuilder.group({
      successProcedureTaskStepId: [null, Validators.required],
      failureProcedureTaskStepId: [null, Validators.required],
    });
  }

  newPrerequisiteForm(): FormGroup {
    return this.formBuilder.group({
      title: ["", Validators.required],
    });
  }

  newPrerequisiteInstructionForm(): FormGroup {
    return this.formBuilder.group({
      title: ["", Validators.required],
      type: [0, Validators.required],
      duration: [null, Validators.required],
    });
  }

  nextStepForm(): FormGroup {
    return this.formBuilder.group({
      procedureTabId: [null, Validators.required],
      procedureTaskStepId: [null],
    });
  }

  newQRCodeForm(): FormGroup {
    return this.formBuilder.group({
      name: ["", [Validators.required]],
      description: ["", [Validators.required]],
    });
  }

  editSidebarSettings(): FormGroup {
    return this.formBuilder.group({
      showDecimals: [true, [Validators.required]],
      showAdditionalTabs: [true, [Validators.required]],
    });
  }

  saveProgressForm(): FormGroup {
    return this.formBuilder.group({
      reason: ["Pause", [Validators.required]],
      delayCodeId: [null, [Validators.required]],
    });
  }

  copyProcedureForm(): FormGroup {
    return this.formBuilder.group({
      selectedAccountId: [null, [Validators.required]],
    });
  }
}
