import { Component, Input } from "@angular/core";

@Component({
  selector: "app-hazard-icons",
  templateUrl: "./hazard-icons.component.html",
  styleUrls: ["./hazard-icons.component.scss"],
})
export class HazardIconsComponent {
  @Input() public icon: string = "";
}
