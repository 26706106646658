import { Component, Input } from "@angular/core";
import { FormControl } from "@angular/forms";
import { ProcedureStepMinV1Model } from "../../../api/ProcedureTaskSteps.service";
import { SelectStepFromOtherProcedureModalComponent } from "../select-step-from-other-procedure-modal/select-step-from-other-procedure-modal.component";
import { NgbModal } from "@ng-bootstrap/ng-bootstrap";
import { SelectedOtherProcedureStepInfo } from "./selected-other-procedure-step-info";
import { ProceduresV1Model } from "../../../api/Procedures.service";
import { WritingService } from "../../writing/writing.service";

@Component({
  selector: "app-step-select",
  templateUrl: "./step-select.component.html",
  styleUrls: ["./step-select.component.scss"],
})
//This component is used to consolidate the logic for showing a select dropdown for procedure steps and the other logic that can happen with those choices.
export class StepSelectComponent {
  @Input() public labelText!: string;
  @Input() public isRequired: boolean = true;
  @Input() public steps: ProcedureStepMinV1Model[] = [];
  @Input() public allowNewStep: boolean = false;
  @Input() public allowSelectFromOtherProcedure: boolean = false;
  @Input() public formControlInput!: FormControl;
  @Input() public isDraft: boolean | null = null;
  otherProcedureStepSelected: ProcedureStepMinV1Model | null = null;
  otherProcedureStepProcedureSelected: ProceduresV1Model | null = null;

  constructor(private modalService: NgbModal, private writingService: WritingService) {}

  ngOnInit(): void {
    if (this.allowSelectFromOtherProcedure) {
      this.onSelectFromOtherProcedure();
    }

    this.isDraft = this.writingService.isDraft$.value;

    if (!this.isDraft) {
      this.formControlInput.disable();
    }
  }

  onSelectFromOtherProcedure(): void {
    this.formControlInput.touched;

    this.formControlInput.valueChanges.subscribe((data) => {
      if (data == "-1") {
        const modalRef = this.modalService.open(SelectStepFromOtherProcedureModalComponent, {
          size: "lg",
        });
        //modalRef.componentInstance. = ;
        modalRef.componentInstance.submitCompletedEvent.subscribe((data: SelectedOtherProcedureStepInfo | null) => {
          if (data != null) {
            this.otherProcedureStepSelected = data.step;
            this.otherProcedureStepProcedureSelected = data.procedure;
            this.formControlInput.setValue(data.step.procedureTaskStepId);
          }
        });
      }
    });
  }

  removeOtherProcedureStepSelected(): void {
    this.otherProcedureStepSelected = null;
    this.formControlInput.setValue(null);
  }
}
