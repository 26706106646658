import { Component, EventEmitter, Input, Output, ViewChild } from "@angular/core";
import { FormBuilder, Validators } from "@angular/forms";
import { NgbActiveModal } from "@ng-bootstrap/ng-bootstrap";
import { MultiSelectComponent } from "@syncfusion/ej2-angular-dropdowns";
import { ToastrService } from "ngx-toastr";
import { AccountService } from "src/app/api/Account.service";
import { LaborsInsertV1Model, LaborsService } from "src/app/api/Labors.service";
import { UserService, UserV1Model } from "src/app/api/User.service";
import { AuthService } from "src/app/modules/core/services/auth.service";
import { FormRepoService } from "src/app/modules/shared/services/form-repo.service";

@Component({
  selector: "app-new-labor-modal",
  templateUrl: "./new-labor-modal.component.html",
  styleUrls: ["./new-labor-modal.component.scss"],
})
export class NewLaborModalComponent {
  //@Input() public inputParameter: type;
  @Input() isLibrary: boolean = true;
  @Output() public submitCompletedEvent = new EventEmitter();
  newLaborForm = this.formRepoService.newLaborForm();

  //Multiselect
  hideSelector: boolean = false;
  userOptions: UserV1Model[] = [];
  selectedGroupContacts: UserV1Model[] = [];
  selectedContactIds: number[] = [];
  userPlaceholder = "Select from list...";
  @ViewChild("userDropdown")
  public userDropdown!: MultiSelectComponent;
  public userDropdownFields: Object = {
    text: "name",
    value: "userId",
  };

  constructor(
    private laborService: LaborsService,
    public activeModal: NgbActiveModal,
    private toastService: ToastrService,
    private formRepoService: FormRepoService,
    private userService: UserService
  ) {}

  ngOnInit(): void {
    this.loadUserOptions();
  }

  loadUserOptions(): void {
    this.userService.getUsers().subscribe((data) => {
      this.userOptions = data;
    });
  }

  onSelectedUser(args: any): void {
    if (args.itemData != null && args.itemData != undefined) {
      const selectedUser = new UserV1Model();
      Object.assign(selectedUser, args.itemData);

      this.selectedGroupContacts.push(selectedUser);
    }
  }

  onRemoveUser(args: any): void {
    const userToRemove = new UserV1Model();
    Object.assign(userToRemove, args.itemData);

    this.selectedGroupContacts = this.selectedGroupContacts.filter(
      (contact) => contact == userToRemove
    );
  }

  onSubmit(): void {
    let model = new LaborsInsertV1Model();

    model.position = this.newLaborForm.controls["position"].value;
    model.shortName = this.newLaborForm.controls["shortName"].value;
    if (this.newLaborForm.controls["isActive"].value == "true") {
      model.isActive = true;
    } else {
      model.isActive = false;
    }
    model.assignedUsers = this.selectedGroupContacts;
    model.icon = this.newLaborForm.controls["icon"].value;
    this.laborService.insert(model).subscribe((data) => {
      this.toastService.success(model.position + " created");
      this.submitCompletedEvent.emit(data.identityId);
      this.activeModal.close();
    });
  }

  onCancel(): void {
    this.activeModal.close();
  }
}
