import { Component, EventEmitter, Input, Output } from "@angular/core";
import { DomSanitizer, SafeHtml } from "@angular/platform-browser";

@Component({
  selector: "app-confirmation-modal",
  templateUrl: "./confirmation-modal.component.html",
  styleUrls: ["./confirmation-modal.component.scss"],
})
export class ConfirmationModalComponent {
  @Input() modalTitle: string = "Confirm Action";
  @Input() modalText: string = "Are you sure you want to do this action?";
  @Input() submitText: string = "Submit";
  @Input() closeText: string = "Close";
  @Output() actionConfirmedEvent = new EventEmitter();
  @Output() actionCancelEvent = new EventEmitter();
  safeHtml: SafeHtml = "";
  constructor(private sanitizer: DomSanitizer) {}

  ngOnInit(): void {
    //Remove extra stuff if not already handled
    if (this.modalText != null && typeof this.modalText === "string") {
      const mainMessage = this.modalText.split(":")[0];
      this.modalText = this.modalText.replace(/<\/?p>/g, "");
      this.safeHtml = this.sanitizer.bypassSecurityTrustHtml(this.modalText);
    }
    //Extra stuff already handled
    else {
      this.safeHtml = this.modalText;
    }
  }

  onConfirm(): void {
    this.actionConfirmedEvent.emit(true);
  }

  onCancel(): void {
    this.actionCancelEvent.emit(false);
  }
}
