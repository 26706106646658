import { Component, Input } from "@angular/core";
import {
  RevisionVideoV1Model,
  VideosService,
} from "src/app/api/Videos.service";
import { environment } from "src/environments/environment";

@Component({
  selector: "app-prerequisite-videos",
  templateUrl: "./prerequisite-videos.component.html",
  styleUrls: ["./prerequisite-videos.component.scss"],
})
export class PrerequisiteVideosComponent {
  @Input() public procedureRevisionId: number = 0;
  @Input() public smallPreview: boolean = true;
  videos: RevisionVideoV1Model[] = [];
  apiRoot: string = environment.apiRoot;
  isLoading: boolean = true;

  constructor(private videosService: VideosService) {}

  ngOnInit(): void {
    this.videosService
      .getRevisionVideos(this.procedureRevisionId)
      .subscribe((data) => {
        this.videos = data;
        this.isLoading = false;
      });
  }

  onVideoClicked(video: RevisionVideoV1Model): void {
    window.open(
      this.apiRoot + "api/v1/videos/download/" + video.videoGuid,
      "_blank"
    );
  }
}
