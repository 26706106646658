import { Component, Input, OnInit } from "@angular/core";

@Component({
  selector: "app-image-thumbnail",
  templateUrl: "./image-thumbnail.component.html",
  styleUrls: ["./image-thumbnail.component.scss"],
})
export class ImageThumbnailComponent implements OnInit {
  @Input() imageSource: string = "";
  @Input() isInGrid: boolean = false;
  orignalImageSource: string = "";
  showLightbox: boolean = false;
  constructor() {}

  ngOnInit(): void {
    if (this.imageSource.includes("/preview")) {
      this.orignalImageSource = this.imageSource.replace("/preview", "");
    } else {
      this.orignalImageSource = this.imageSource;
    }
  }

  toggleLightbox(): void {
    this.showLightbox = !this.showLightbox;
  }
}
