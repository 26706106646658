 
   
   import { Injectable } from '@angular/core';
   import { HttpClient, HttpHeaders, HttpErrorResponse } from '@angular/common/http';

   import { Observable, of, throwError  } from 'rxjs';

   import { environment } from '../../environments/environment';
   //import { LoggerService } from '../../modules/core/logger.service'
   import { GenericSearchResultV1Model } from '../../app/models/GenericSearchResultV1Model';
   import { PageableListV1Model } from '../../app/models/PageableListV1Model';

   const httpOptions = {
     headers: new HttpHeaders({ 'Content-Type': 'application/json' }),
     withCredentials: true
   };

   const httpOptions_Handle400InComponent = {
     headers: new HttpHeaders({ 'Content-Type': 'application/json', 'I400Local': '' }),
     withCredentials: true
   }
    
   @Injectable({
     providedIn: 'root'
   })
   export class PrecautionsService {

   
        private classVersion = "1.0";
        
         
      constructor(private http: HttpClient) { }
      

            


                  
        
          
              getPrecautionByID(precautionId: number,  handle400Locally: boolean = false) : Observable<PrecautionsV1Model>  { 
              let version = this.classVersion;
              return this.http.post<any>(environment.apiRoot + `api/v${version}/precautions/GetById`,precautionId, handle400Locally ? httpOptions_Handle400InComponent : httpOptions ) //p => p.Attributes.Count > 0 ----only adds FromBody     
            };
          
      
        
          
              insert(model: PrecautionsInsertV1Model,  handle400Locally: boolean = false) : Observable<InsertResponseV1Model>  { 
              let version = this.classVersion;
              return this.http.post<any>(environment.apiRoot + `api/v${version}/precautions/Insert`,model, handle400Locally ? httpOptions_Handle400InComponent : httpOptions ) //p => p.Attributes.Count > 0 ----only adds FromBody     
            };
          
      
        
          
              update(model: PrecautionsUpdateV1Model,  handle400Locally: boolean = false) : Observable<UpdateResponseV1Model>  { 
              let version = this.classVersion;
              return this.http.post<any>(environment.apiRoot + `api/v${version}/precautions/Update`,model, handle400Locally ? httpOptions_Handle400InComponent : httpOptions ) //p => p.Attributes.Count > 0 ----only adds FromBody     
            };
          
      
        
          
              delete(precautionId: number,  handle400Locally: boolean = false) : Observable<CommonResponseV1Model>  { 
              let version = this.classVersion;
              return this.http.post<any>(environment.apiRoot + `api/v${version}/precautions/Delete`,precautionId, handle400Locally ? httpOptions_Handle400InComponent : httpOptions ) //p => p.Attributes.Count > 0 ----only adds FromBody     
            };
          
      
        
          
              insertRevisionPrecaution(model: RevisionPrecautionInsertV1Model,  handle400Locally: boolean = false) : Observable<InsertResponseV1Model>  { 
              let version = this.classVersion;
              return this.http.post<any>(environment.apiRoot + `api/v${version}/precautions/InsertRevisionPrecaution`,model, handle400Locally ? httpOptions_Handle400InComponent : httpOptions ) //p => p.Attributes.Count > 0 ----only adds FromBody     
            };
          
      
        
          
              insertProcedureStepPrecaution(model: ProcedureStepPrecautionInsertV1Model,  handle400Locally: boolean = false) : Observable<InsertResponseV1Model>  { 
              let version = this.classVersion;
              return this.http.post<any>(environment.apiRoot + `api/v${version}/precautions/InsertProcedureStepPrecaution`,model, handle400Locally ? httpOptions_Handle400InComponent : httpOptions ) //p => p.Attributes.Count > 0 ----only adds FromBody     
            };
          
      
        
          
              insertScopingPrecaution(model: ScopingPrecautionInsertV1Model,  handle400Locally: boolean = false) : Observable<InsertResponseV1Model>  { 
              let version = this.classVersion;
              return this.http.post<any>(environment.apiRoot + `api/v${version}/precautions/InsertScopingPrecaution`,model, handle400Locally ? httpOptions_Handle400InComponent : httpOptions ) //p => p.Attributes.Count > 0 ----only adds FromBody     
            };
          
      
        
          
              deleteRevisionPrecaution(model: RevisionPrecautionInsertV1Model,  handle400Locally: boolean = false) : Observable<CommonResponseV1Model>  { 
              let version = this.classVersion;
              return this.http.post<any>(environment.apiRoot + `api/v${version}/precautions/DeleteRevisionPrecaution`,model, handle400Locally ? httpOptions_Handle400InComponent : httpOptions ) //p => p.Attributes.Count > 0 ----only adds FromBody     
            };
          
      
        
          
              deleteProcedureStepPrecaution(procedureTaskStepPrecautionId: number,  handle400Locally: boolean = false) : Observable<CommonResponseV1Model>  { 
              let version = this.classVersion;
              return this.http.post<any>(environment.apiRoot + `api/v${version}/precautions/DeleteProcedureStepPrecaution`,procedureTaskStepPrecautionId, handle400Locally ? httpOptions_Handle400InComponent : httpOptions ) //p => p.Attributes.Count > 0 ----only adds FromBody     
            };
          
      
        
          
              removeScopingPrecaution(scopeDocumentPrecautionId: number,  handle400Locally: boolean = false) : Observable<CommonResponseV1Model>  { 
              let version = this.classVersion;
              return this.http.post<any>(environment.apiRoot + `api/v${version}/precautions/RemoveScopingPrecaution`,scopeDocumentPrecautionId, handle400Locally ? httpOptions_Handle400InComponent : httpOptions ) //p => p.Attributes.Count > 0 ----only adds FromBody     
            };
          
      
        
          
              precautionInactive(precautionId: number,  handle400Locally: boolean = false) : Observable<UpdateResponseV1Model>  { 
              let version = this.classVersion;
              return this.http.post<any>(environment.apiRoot + `api/v${version}/precautions/PrecautionInactive`,precautionId, handle400Locally ? httpOptions_Handle400InComponent : httpOptions ) //p => p.Attributes.Count > 0 ----only adds FromBody     
            };
          
      

                        

                  
}

export class PrecautionsV1Model {
    constructor(  
        // Inherited properties 
        
      
        //Properties
        
        public precautionId: number | null = null,   
        public description: string | null = null,   
        public createdByUserId: number | null = null,   
        public createdDate: Date | null = null,   
        public updatedByUserId: number | null = null,   
        public updatedDate: Date | null = null,   
        public createdByUserName: string | null = null,   
        public updatedByUserName: string | null = null,   
        public accountId: number | null = null,   
        public hazardId: number | null = null,   
        public hazardClass: string | null = null,   
        public hazardIcon: string | null = null,   
        public precautionLevel: string | null = null,   
        public isActive: boolean | null = null,   
        public precautionColor: string | null = null,   
      ) { }
}

export class InsertResponseV1Model  {
    constructor(  
        // Inherited properties 
        
        public success: boolean | null = null,
        public message: string | null = null,
      
        //Properties
        
        public identityId: number | null = null,   
        public identityIds: number[] | null = null,   
      ) { }
}

export class PrecautionsInsertV1Model {
    constructor(  
        // Inherited properties 
        
      
        //Properties
        
        public description: string | null = null,   
        public hazardId: number | null = null,   
        public precautionLevel: string | null = null,   
        public isActive: boolean | null = null,   
      ) { }
}

export class UpdateResponseV1Model {
    constructor(  
        // Inherited properties 
        
      
        //Properties
        
        public totalRowsUpdated: number | null = null,   
      ) { }
}

export class PrecautionsUpdateV1Model {
    constructor(  
        // Inherited properties 
        
      
        //Properties
        
        public precautionId: number | null = null,   
        public description: string | null = null,   
        public hazardId: number | null = null,   
        public precautionLevel: string | null = null,   
        public isActive: boolean | null = null,   
      ) { }
}

export class CommonResponseV1Model {
    constructor(  
        // Inherited properties 
        
      
        //Properties
        
        public success: boolean | null = null,   
        public message: string | null = null,   
      ) { }
}

export class RevisionPrecautionInsertV1Model {
    constructor(  
        // Inherited properties 
        
      
        //Properties
        
        public procedureRevisionId: number | null = null,   
        public precautionId: number | null = null,   
      ) { }
}

export class ProcedureStepPrecautionInsertV1Model {
    constructor(  
        // Inherited properties 
        
      
        //Properties
        
        public procedureTaskStepId: number | null = null,   
        public precautionId: number | null = null,   
      ) { }
}

export class ScopingPrecautionInsertV1Model {
    constructor(  
        // Inherited properties 
        
      
        //Properties
        
        public scopeDocumentId: number | null = null,   
        public precautionId: number | null = null,   
      ) { }
}





/* 
     Debug Info:
     PrintDebugInfo - put a dollar sign in front of Print to enable this 
*/

