 
   
   import { Injectable } from '@angular/core';
   import { HttpClient, HttpHeaders, HttpErrorResponse } from '@angular/common/http';

   import { Observable, of, throwError  } from 'rxjs';

   import { environment } from '../../environments/environment';
   //import { LoggerService } from '../../modules/core/logger.service'
   import { GenericSearchResultV1Model } from '../../app/models/GenericSearchResultV1Model';
   import { PageableListV1Model } from '../../app/models/PageableListV1Model';

   const httpOptions = {
     headers: new HttpHeaders({ 'Content-Type': 'application/json' }),
     withCredentials: true
   };

   const httpOptions_Handle400InComponent = {
     headers: new HttpHeaders({ 'Content-Type': 'application/json', 'I400Local': '' }),
     withCredentials: true
   }
    
   @Injectable({
     providedIn: 'root'
   })
   export class LaborsService {

   
        private classVersion = "1.0";
        
         
      constructor(private http: HttpClient) { }
      
        
          
              getAccountLabors( handle400Locally: boolean = false) : Observable<LaborsV1Model[]>  { 
              let version = this.classVersion;
              return this.http.get<any>(environment.apiRoot + `api/v${version}/labors/GetAccountLabors`, handle400Locally ? httpOptions_Handle400InComponent : httpOptions )       
            };
          
      

            


                  
        
          
              getLaborsByID(positionId: number,  handle400Locally: boolean = false) : Observable<LaborsV1Model>  { 
              let version = this.classVersion;
              return this.http.post<any>(environment.apiRoot + `api/v${version}/labors/GetById`,positionId, handle400Locally ? httpOptions_Handle400InComponent : httpOptions ) //p => p.Attributes.Count > 0 ----only adds FromBody     
            };
          
      
        
          
              getLaborGroupUsers(positionId: number,  handle400Locally: boolean = false) : Observable<UserV1Model[]>  { 
              let version = this.classVersion;
              return this.http.post<any>(environment.apiRoot + `api/v${version}/labors/GetLaborGroupUsers`,positionId, handle400Locally ? httpOptions_Handle400InComponent : httpOptions ) //p => p.Attributes.Count > 0 ----only adds FromBody     
            };
          
      
        
          
              getUsersLabor(userId: number,  handle400Locally: boolean = false) : Observable<LaborsV1Model[]>  { 
              let version = this.classVersion;
              return this.http.post<any>(environment.apiRoot + `api/v${version}/labors/GetUsersLabor`,userId, handle400Locally ? httpOptions_Handle400InComponent : httpOptions ) //p => p.Attributes.Count > 0 ----only adds FromBody     
            };
          
      
        
          
              getProcedureStepLabor(procedureTaskStepId: number,  handle400Locally: boolean = false) : Observable<ProcedureStepLaborV1Model[]>  { 
              let version = this.classVersion;
              return this.http.post<any>(environment.apiRoot + `api/v${version}/labors/GetProcedureStepLabor`,procedureTaskStepId, handle400Locally ? httpOptions_Handle400InComponent : httpOptions ) //p => p.Attributes.Count > 0 ----only adds FromBody     
            };
          
      
        
          
              getScopingLabor(scopeDocumentId: number,  handle400Locally: boolean = false) : Observable<ScopingLaborV1Model[]>  { 
              let version = this.classVersion;
              return this.http.post<any>(environment.apiRoot + `api/v${version}/labors/GetScopingLabor`,scopeDocumentId, handle400Locally ? httpOptions_Handle400InComponent : httpOptions ) //p => p.Attributes.Count > 0 ----only adds FromBody     
            };
          
      
        
          
              searchProcedureStepScopeLabors(model: LaborScopingSearchV1Model,  handle400Locally: boolean = false) : Observable<LaborsV1Model[]>  { 
              let version = this.classVersion;
              return this.http.post<any>(environment.apiRoot + `api/v${version}/labors/SearchProcedureStepScopeLabors`,model, handle400Locally ? httpOptions_Handle400InComponent : httpOptions ) //p => p.Attributes.Count > 0 ----only adds FromBody     
            };
          
      
        
          
              getRevisionLabors(procedureRevisionId: number,  handle400Locally: boolean = false) : Observable<RevisionLaborV1Model[]>  { 
              let version = this.classVersion;
              return this.http.post<any>(environment.apiRoot + `api/v${version}/labors/procedureRevisionId`,procedureRevisionId, handle400Locally ? httpOptions_Handle400InComponent : httpOptions ) //p => p.Attributes.Count > 0 ----only adds FromBody     
            };
          
      
        
          
              insert(model: LaborsInsertV1Model,  handle400Locally: boolean = false) : Observable<InsertResponseV1Model>  { 
              let version = this.classVersion;
              return this.http.post<any>(environment.apiRoot + `api/v${version}/labors/Insert`,model, handle400Locally ? httpOptions_Handle400InComponent : httpOptions ) //p => p.Attributes.Count > 0 ----only adds FromBody     
            };
          
      
        
          
              insertProcedureLabor(model: ProcedureStepLaborInsertV1Model,  handle400Locally: boolean = false) : Observable<InsertResponseV1Model>  { 
              let version = this.classVersion;
              return this.http.post<any>(environment.apiRoot + `api/v${version}/labors/InsertProcedureLabor`,model, handle400Locally ? httpOptions_Handle400InComponent : httpOptions ) //p => p.Attributes.Count > 0 ----only adds FromBody     
            };
          
      
        
          
              insertScopingLabor(model: ScopingLaborInsertV1Model,  handle400Locally: boolean = false) : Observable<InsertResponseV1Model>  { 
              let version = this.classVersion;
              return this.http.post<any>(environment.apiRoot + `api/v${version}/labors/InsertScopingLabor`,model, handle400Locally ? httpOptions_Handle400InComponent : httpOptions ) //p => p.Attributes.Count > 0 ----only adds FromBody     
            };
          
      
        
          
              update(model: LaborsUpdateV1Model,  handle400Locally: boolean = false) : Observable<UpdateResponseV1Model>  { 
              let version = this.classVersion;
              return this.http.post<any>(environment.apiRoot + `api/v${version}/labors/Update`,model, handle400Locally ? httpOptions_Handle400InComponent : httpOptions ) //p => p.Attributes.Count > 0 ----only adds FromBody     
            };
          
      
        
          
              updateProcedureLabor(model: ProcedureStepLaborUpdateV1Model,  handle400Locally: boolean = false) : Observable<UpdateResponseV1Model>  { 
              let version = this.classVersion;
              return this.http.post<any>(environment.apiRoot + `api/v${version}/labors/UpdateProcedureLabor`,model, handle400Locally ? httpOptions_Handle400InComponent : httpOptions ) //p => p.Attributes.Count > 0 ----only adds FromBody     
            };
          
      
        
          
              updateScopingLabor(model: ScopingLaborUpdateV1Model,  handle400Locally: boolean = false) : Observable<UpdateResponseV1Model>  { 
              let version = this.classVersion;
              return this.http.post<any>(environment.apiRoot + `api/v${version}/labors/UpdateScopingLabor`,model, handle400Locally ? httpOptions_Handle400InComponent : httpOptions ) //p => p.Attributes.Count > 0 ----only adds FromBody     
            };
          
      
        
          
              delete(positionId: number,  handle400Locally: boolean = false) : Observable<CommonResponseV1Model>  { 
              let version = this.classVersion;
              return this.http.post<any>(environment.apiRoot + `api/v${version}/labors/Delete`,positionId, handle400Locally ? httpOptions_Handle400InComponent : httpOptions ) //p => p.Attributes.Count > 0 ----only adds FromBody     
            };
          
      
        
          
              removeProcedureStepLabor(procedureTaskStepLaborId: number,  handle400Locally: boolean = false) : Observable<CommonResponseV1Model>  { 
              let version = this.classVersion;
              return this.http.post<any>(environment.apiRoot + `api/v${version}/labors/RemoveProcedureStepLabor`,procedureTaskStepLaborId, handle400Locally ? httpOptions_Handle400InComponent : httpOptions ) //p => p.Attributes.Count > 0 ----only adds FromBody     
            };
          
      
        
          
              removeScopingLabor(scopeDocumentLaborId: number,  handle400Locally: boolean = false) : Observable<CommonResponseV1Model>  { 
              let version = this.classVersion;
              return this.http.post<any>(environment.apiRoot + `api/v${version}/labors/RemoveScopingLabor`,scopeDocumentLaborId, handle400Locally ? httpOptions_Handle400InComponent : httpOptions ) //p => p.Attributes.Count > 0 ----only adds FromBody     
            };
          
      
        
          
              search(model: LaborsSearchV1Model,  handle400Locally: boolean = false) : Observable<LaborsV1Model[]>  { 
              let version = this.classVersion;
              return this.http.post<any>(environment.apiRoot + `api/v${version}/labors/Search`,model, handle400Locally ? httpOptions_Handle400InComponent : httpOptions ) //p => p.Attributes.Count > 0 ----only adds FromBody     
            };
          
      
        
          
              searchScopingLabor(model: LaborScopingSearchV1Model,  handle400Locally: boolean = false) : Observable<LaborsV1Model[]>  { 
              let version = this.classVersion;
              return this.http.post<any>(environment.apiRoot + `api/v${version}/labors/SearchScopingLabor`,model, handle400Locally ? httpOptions_Handle400InComponent : httpOptions ) //p => p.Attributes.Count > 0 ----only adds FromBody     
            };
          
      
        
          
              laborInactive(positionId: number,  handle400Locally: boolean = false) : Observable<UpdateResponseV1Model>  { 
              let version = this.classVersion;
              return this.http.post<any>(environment.apiRoot + `api/v${version}/labors/LaborInactive`,positionId, handle400Locally ? httpOptions_Handle400InComponent : httpOptions ) //p => p.Attributes.Count > 0 ----only adds FromBody     
            };
          
      

                        

                  
}

export class LaborsV1Model {
    constructor(  
        // Inherited properties 
        
      
        //Properties
        
        public positionId: number | null = null,   
        public icon: string | null = null,   
        public position: string | null = null,   
        public shortName: string | null = null,   
        public isActive: boolean | null = null,   
        public createdByUserId: number | null = null,   
        public createdDate: Date | null = null,   
        public updatedByUserId: number | null = null,   
        public updatedDate: Date | null = null,   
        public createdByUserName: string | null = null,   
        public updatedByUserName: string | null = null,   
        public accountId: number | null = null,   
      ) { }
}

export class UserV1Model {
    constructor(  
        // Inherited properties 
        
      
        //Properties
        
        public userId: number | null = null,   
        public firstName: string | null = null,   
        public lastName: string | null = null,   
        public name: string | null = null,   
        public jobTitle: string | null = null,   
        public email: string | null = null,   
        public country: string | null = null,   
        public address: string | null = null,   
        public address2: string | null = null,   
        public city: string | null = null,   
        public state: string | null = null,   
        public zip: string | null = null,   
        public phone: string | null = null,   
        public createdDate: Date | null = null,   
        public createdByUserId: number | null = null,   
        public updatedDate: Date | null = null,   
        public updatedByUserId: number | null = null,   
        public notes: string | null = null,   
        public isActive: boolean | null = null,   
        public roles: RoleM1V1Model[] | null = null,   
        public lastLogin: Date | null = null,   
        public resetCode: string | null = null,   
        public resetDate: Date | null = null,   
        public lastAccountId: number | null = null,   
        public isGlobal: boolean | null = null,   
        public accountId: number | null = null,   
      ) { }
}

export class RoleM1V1Model {
    constructor(  
        // Inherited properties 
        
      
        //Properties
        
        public roleId: number | null = null,   
        public role: string | null = null,   
        public isGlobal: boolean | null = null,   
      ) { }
}

export class ProcedureStepLaborV1Model {
    constructor(  
        // Inherited properties 
        
      
        //Properties
        
        public procedureTaskStepLaborId: number | null = null,   
        public procedureTaskStepId: number | null = null,   
        public positionId: number | null = null,   
        public position: string | null = null,   
        public shortName: string | null = null,   
        public icon: string | null = null,   
        public quantity: number | null = null,   
        public createdDate: Date | null = null,   
        public updatedDate: Date | null = null,   
        public accountId: number | null = null,   
      ) { }
}

export class ScopingLaborV1Model {
    constructor(  
        // Inherited properties 
        
      
        //Properties
        
        public scopeDocumentLaborId: number | null = null,   
        public scopeDocumentId: number | null = null,   
        public positionId: number | null = null,   
        public position: string | null = null,   
        public shortName: string | null = null,   
        public icon: string | null = null,   
        public quantity: number | null = null,   
        public createdDate: Date | null = null,   
        public updatedDate: Date | null = null,   
        public accountId: number | null = null,   
      ) { }
}

export class LaborScopingSearchV1Model {
    constructor(  
        // Inherited properties 
        
      
        //Properties
        
        public searchTerm: string | null = null,   
        public scopeDocumentId: number | null = null,   
        public procedureTaskStepId: number | null = null,   
      ) { }
}

export class RevisionLaborV1Model {
    constructor(  
        // Inherited properties 
        
      
        //Properties
        
        public positionId: number | null = null,   
        public position: string | null = null,   
        public shortName: string | null = null,   
        public icon: string | null = null,   
        public quantity: number | null = null,   
      ) { }
}

export class InsertResponseV1Model  {
    constructor(  
        // Inherited properties 
        
        public success: boolean | null = null,
        public message: string | null = null,
      
        //Properties
        
        public identityId: number | null = null,   
        public identityIds: number[] | null = null,   
      ) { }
}

export class LaborsInsertV1Model {
    constructor(  
        // Inherited properties 
        
      
        //Properties
        
        public position: string | null = null,   
        public shortName: string | null = null,   
        public isActive: boolean | null = null,   
        public icon: string | null = null,   
        public assignedUsers: UserV1Model[] | null = null,   
      ) { }
}

export class ProcedureStepLaborInsertV1Model {
    constructor(  
        // Inherited properties 
        
      
        //Properties
        
        public procedureTaskStepId: number | null = null,   
        public positionId: number | null = null,   
        public quantity: number | null = null,   
      ) { }
}

export class ScopingLaborInsertV1Model {
    constructor(  
        // Inherited properties 
        
      
        //Properties
        
        public scopeDocumentId: number | null = null,   
        public positionId: number | null = null,   
        public quantity: number | null = null,   
      ) { }
}

export class UpdateResponseV1Model {
    constructor(  
        // Inherited properties 
        
      
        //Properties
        
        public totalRowsUpdated: number | null = null,   
      ) { }
}

export class LaborsUpdateV1Model {
    constructor(  
        // Inherited properties 
        
      
        //Properties
        
        public positionId: number | null = null,   
        public position: string | null = null,   
        public shortName: string | null = null,   
        public isActive: boolean | null = null,   
        public icon: string | null = null,   
        public assignedUsers: UserV1Model[] | null = null,   
      ) { }
}

export class ProcedureStepLaborUpdateV1Model {
    constructor(  
        // Inherited properties 
        
      
        //Properties
        
        public procedureTaskStepLaborId: number | null = null,   
        public quantity: number | null = null,   
      ) { }
}

export class ScopingLaborUpdateV1Model {
    constructor(  
        // Inherited properties 
        
      
        //Properties
        
        public scopeDocumentLaborId: number | null = null,   
        public quantity: number | null = null,   
      ) { }
}

export class CommonResponseV1Model {
    constructor(  
        // Inherited properties 
        
      
        //Properties
        
        public success: boolean | null = null,   
        public message: string | null = null,   
      ) { }
}

export class LaborsSearchV1Model {
    constructor(  
        // Inherited properties 
        
      
        //Properties
        
        public searchTerm: string | null = null,   
        public procedureRevisionId: number | null = null,   
        public procedureTaskStepId: number | null = null,   
      ) { }
}





/* 
     Debug Info:
     PrintDebugInfo - put a dollar sign in front of Print to enable this 
*/

