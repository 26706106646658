import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { Router, RouterModule, Routes } from "@angular/router";
import { LoginComponent } from "./modules/login/login/login.component";
import { NotFoundComponent } from "./modules/shared/not-found/not-found.component";
import { UnauthorizedComponent } from "./modules/shared/unauthorized/unauthorized.component";
import { AuthorizationGuard } from "./modules/core/guards/authorization.guard";
import { permissionType } from "./models/enums";

const routes: Routes = [
  {
    path: ":guid/home",
    canActivate: [AuthorizationGuard],
    data: {
      auth: [permissionType.Authenticated],
    },
    loadChildren: () =>
      import("./modules/home/home.module").then((m) => m.HomeModule),
  },
  {
    path: ":guid/admin",
    canActivate: [AuthorizationGuard],
    data: {
      auth: [permissionType.ManageAccounts, permissionType.ManageUsers],
    },
    loadChildren: () =>
      import("./modules/admin/admin.module").then((m) => m.AdminModule),
  },
  {
    path: ":guid/library",
    canActivate: [AuthorizationGuard],
    data: {
      auth: [permissionType.Authenticated],
    },
    loadChildren: () =>
      import("./modules/library/library.module").then((m) => m.LibraryModule),
  },
  {
    path: ":guid/writing",
    canActivate: [AuthorizationGuard],
    data: {
      auth: [permissionType.ManageProcedures],
    },
    loadChildren: () =>
      import("./modules/writing/writing.module").then((m) => m.WritingModule),
  },
  {
    path: ":guid/execution",
    canActivate: [AuthorizationGuard],
    data: {
      auth: [permissionType.ExecuteProcedures],
    },
    loadChildren: () =>
      import("./modules/execution/execution.module").then(
        (m) => m.ExecutionModule
      ),
  },
  {
    path: ":guid/scoping",
    canActivate: [AuthorizationGuard],
    data: {
      auth: [permissionType.Scoping, permissionType.CreateAccounts],
    },
    loadChildren: () =>
      import("./modules/scoping/scoping.module").then((m) => m.ScopingModule),
  },
  {
    path: ":guid/scheduling",
    canActivate: [AuthorizationGuard],
    data: {
      auth: [permissionType.Scheduling, permissionType.CreateAccounts],
    },
    loadChildren: () =>
      import("./modules/scheduling/scheduling.module").then(
        (m) => m.SchedulingModule
      ),
  },
  {
    path: ":guid/analytics",
    canActivate: [AuthorizationGuard],
    data: {
      auth: [permissionType.Analytics, permissionType.CreateAccounts],
    },
    loadChildren: () =>
      import("./modules/analytics/analytics.module").then(
        (m) => m.AnalyticsModule
      ),
  },
  {
    path: "contact",
    loadChildren: () =>
      import("./modules/contact/contact.module").then((m) => m.ContactModule),
  },
  {
    path: "facilities",
    canActivate: [AuthorizationGuard],
    data: {
      auth: [permissionType.Authenticated],
    },
    loadChildren: () =>
      import("./modules/facilities/facilities.module").then(
        (m) => m.FacilitiesModule
      ),
  },
  {
    path: ":guid/QRCode",
    canActivate: [AuthorizationGuard],
    data: {
      auth: [permissionType.Authenticated],
    },
    loadChildren: () =>
      import("./modules/qr-codes-public/qr-codes-public.module").then(
        (m) => m.QrCodesPublicModule
      ),
  },

  { path: "login", component: LoginComponent },
  { path: "unauthorized", component: UnauthorizedComponent },
  { path: "notfound", component: NotFoundComponent },
  { path: "**", redirectTo: "/notfound" },
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, {
      onSameUrlNavigation: "reload",
    }),
  ],
  exports: [RouterModule],
})
export class AppRoutingModule {
  constructor(private router: Router) {
    // // override the route reuse strategy
    this.router.routeReuseStrategy.shouldReuseRoute = function () {
      return false;
    };
  }
}
