import { NgModule, Optional, SkipSelf } from "@angular/core";
import { CommonModule } from "@angular/common";
import { throwIfAlreadyLoaded } from "./guards/module-import.guard";
import { AuthorizationGuardService } from "./services/authorization-guard.service";
import { PredicateService } from "./services/predicate.service";
import { VersionCheckService } from "./services/version-check.service";

@NgModule({
  declarations: [],
  imports: [CommonModule],
  providers: [VersionCheckService, AuthorizationGuardService, PredicateService],
})
export class CoreModule {
  constructor(@Optional() @SkipSelf() parentModule: CoreModule) {
    throwIfAlreadyLoaded(parentModule, "CoreModule");
  }
}
