// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.grid-image-thumbnail {
  max-height: 100px;
  max-width: 100px;
}

.attached-file-thumbnail {
  max-width: 80%;
}`, "",{"version":3,"sources":["webpack://./src/app/modules/shared/video-preview/video-preview.component.scss"],"names":[],"mappings":"AAAA;EACI,iBAAA;EACA,gBAAA;AACJ;;AAEE;EACE,cAAA;AACJ","sourcesContent":[".grid-image-thumbnail {\n    max-height: 100px;\n    max-width: 100px;\n  }\n\n  .attached-file-thumbnail{\n    max-width: 80%;\n  }"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
