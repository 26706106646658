 
   
   import { Injectable } from '@angular/core';
   import { HttpClient, HttpHeaders, HttpErrorResponse } from '@angular/common/http';

   import { Observable, of, throwError  } from 'rxjs';

   import { environment } from '../../environments/environment';
   //import { LoggerService } from '../../modules/core/logger.service'
   import { GenericSearchResultV1Model } from '../../app/models/GenericSearchResultV1Model';
   import { PageableListV1Model } from '../../app/models/PageableListV1Model';

   const httpOptions = {
     headers: new HttpHeaders({ 'Content-Type': 'application/json' }),
     withCredentials: true
   };

   const httpOptions_Handle400InComponent = {
     headers: new HttpHeaders({ 'Content-Type': 'application/json', 'I400Local': '' }),
     withCredentials: true
   }
    
   @Injectable({
     providedIn: 'root'
   })
   export class ExecutionService {

   
        private classVersion = "1.0";
        
         
      constructor(private http: HttpClient) { }
      
        
          
              getExecutionLanes(procedureRevisionId: number, executionGuid: string,  handle400Locally: boolean = false) : Observable<ExecutionLaneV1Model[]>  { 
              let version = this.classVersion;
              return this.http.get<any>(environment.apiRoot + `api/v${version}/execution/ExecutionTree/${procedureRevisionId}/${encodeURIComponent(executionGuid)}`, handle400Locally ? httpOptions_Handle400InComponent : httpOptions )       
            };
          
      
        
          
              closeOpenLogsForUser( handle400Locally: boolean = false) : Observable<UpdateResponseV1Model>  { 
              let version = this.classVersion;
              return this.http.get<any>(environment.apiRoot + `api/v${version}/execution/CloseOpenLogsForUser`, handle400Locally ? httpOptions_Handle400InComponent : httpOptions )       
            };
          
      

            


                  
        
          
              continueNextStep(current: ExecutionCurrentSectionV1Model,  handle400Locally: boolean = false) : Observable<ExecutionSectionV1Model>  { 
              let version = this.classVersion;
              return this.http.post<any>(environment.apiRoot + `api/v${version}/execution/continue`,current, handle400Locally ? httpOptions_Handle400InComponent : httpOptions ) //p => p.Attributes.Count > 0 ----only adds FromBody     
            };
          
      
        
          
              previousStep(current: ExecutionCurrentSectionV1Model,  handle400Locally: boolean = false) : Observable<ExecutionSectionV1Model>  { 
              let version = this.classVersion;
              return this.http.post<any>(environment.apiRoot + `api/v${version}/execution/previous`,current, handle400Locally ? httpOptions_Handle400InComponent : httpOptions ) //p => p.Attributes.Count > 0 ----only adds FromBody     
            };
          
      
        
          
              updateLogEntry(model: UpdateLogEntryV1Model,  handle400Locally: boolean = false) : Observable<UpdateResponseV1Model>  { 
              let version = this.classVersion;
              return this.http.post<any>(environment.apiRoot + `api/v${version}/execution/UpdateLogEntry`,model, handle400Locally ? httpOptions_Handle400InComponent : httpOptions ) //p => p.Attributes.Count > 0 ----only adds FromBody     
            };
          
      
        
          
              skipStep(model: ExecutionSkipStepV1Model,  handle400Locally: boolean = false) : Observable<UpdateResponseV1Model>  { 
              let version = this.classVersion;
              return this.http.post<any>(environment.apiRoot + `api/v${version}/execution/SkipStep`,model, handle400Locally ? httpOptions_Handle400InComponent : httpOptions ) //p => p.Attributes.Count > 0 ----only adds FromBody     
            };
          
      
        
          
              resetLogEntry(model: UpdateLogEntryV1Model,  handle400Locally: boolean = false) : Observable<UpdateResponseV1Model>  { 
              let version = this.classVersion;
              return this.http.post<any>(environment.apiRoot + `api/v${version}/execution/ResetLogEntry`,model, handle400Locally ? httpOptions_Handle400InComponent : httpOptions ) //p => p.Attributes.Count > 0 ----only adds FromBody     
            };
          
      

                        

                  
}

export class ExecutionSectionV1Model {
    constructor(  
        // Inherited properties 
        
      
        //Properties
        
        public executionSection: ExecutionSection | null = null,   
        public step: ProcedureStepV1Model | null = null,   
        public totalSeconds: number | null = null,   
        public totalRangeSeconds: number | null = null,   
        public preview: boolean | null = null,   
        public procedureTaskStepInstance: number | null = null,   
        public planStepNumber: number | null = null,   
        public isLockedByAnotherDevice: boolean | null = null,   
        public isQualified: boolean | null = null,   
        public procedureTaskStepNumber: string | null = null,   
        public isPreviousWorkOpen: boolean | null = null,   
        public isConcurent: boolean | null = null,   
      ) { }
}

export class ProcedureStepV1Model {
    constructor(  
        // Inherited properties 
        
      
        //Properties
        
        public procedureTaskStepId: number | null = null,   
        public procedureTaskId: number | null = null,   
        public taskTypeId: number | null = null,   
        public task: string | null = null,   
        public title: string | null = null,   
        public step: string | null = null,   
        public stepName: string | null = null,   
        public sequential: boolean | null = null,   
        public critical: boolean | null = null,   
        public duration: number | null = null,   
        public repeatedCount: number | null = null,   
        public holdId: number | null = null,   
        public holdName: string | null = null,   
        public createdByUserId: number | null = null,   
        public createdDate: Date | null = null,   
        public updatedByUserId: number | null = null,   
        public updatedDate: Date | null = null,   
        public accountId: number | null = null,   
        public stepNumber: number | null = null,   
        public totalSteps: number | null = null,   
        public stepType: StepType | null = null,   
        public sort: number | null = null,   
        public prerequisiteStep: boolean | null = null,   
        public durationTolerance: number | null = null,   
        public endStep: boolean | null = null,   
        public failureStep: boolean | null = null,   
        public instructions: ProcedureStepInstructionV1Model[] | null = null,   
        public fields: ProcedureTaskStepFieldV1Model[] | null = null,   
      ) { }
}

export class ProcedureStepInstructionV1Model {
    constructor(  
        // Inherited properties 
        
      
        //Properties
        
        public procedureTaskStepInstructionId: number | null = null,   
        public instruction: string | null = null,   
        public procedureTaskStepId: number | null = null,   
        public sort: number | null = null,   
      ) { }
}

export class ProcedureTaskStepFieldV1Model {
    constructor(  
        // Inherited properties 
        
      
        //Properties
        
        public procedureTaskStepFieldId: number | null = null,   
        public fieldLabel: string | null = null,   
        public postFieldLabel: string | null = null,   
        public procedureTaskStepInstance: number | null = null,   
        public fieldType: number | null = null,   
        public createdByUserId: number | null = null,   
        public updatedByUserId: number | null = null,   
        public createdDate: Date | null = null,   
        public upatedDate: Date | null = null,   
        public rules: ProcedureRuleV1Model[] | null = null,   
        public workOrderFieldValues: WorkOrderFieldValueV1Model[] | null = null,   
      ) { }
}

export class ProcedureRuleV1Model {
    constructor(  
        // Inherited properties 
        
      
        //Properties
        
        public procedureTaskStepRuleId: number | null = null,   
        public procedureTaskStepId: number | null = null,   
        public ruleTypeId: RuleType | null = null,   
        public procedureTaskStepFieldId: number | null = null,   
        public name: string | null = null,   
        public successProcedureTaskStepId: number | null = null,   
        public successProcedureTaskStepName: string | null = null,   
        public failureProcedureTaskStepId: number | null = null,   
        public failureProcedureTaskStepName: string | null = null,   
        public createdByUserId: number | null = null,   
        public createdByUserName: string | null = null,   
        public updatedByUserId: number | null = null,   
        public updatedByUserName: string | null = null,   
        public createdDate: Date | null = null,   
        public updatedDate: Date | null = null,   
        public operand: OperandEnum | null = null,   
        public mathOperator: MathOperatorEnum | null = null,   
        public mathFieldId1: number | null = null,   
        public mathFieldId2: number | null = null,   
        public values: ProcedureRuleValueV1Model[] | null = null,   
      ) { }
}

export class ProcedureRuleValueV1Model {
    constructor(  
        // Inherited properties 
        
      
        //Properties
        
        public procedureTaskStepRuleValueId: number | null = null,   
        public procedureTaskStepRuleId: number | null = null,   
        public successProcedureTaskStepId: number | null = null,   
        public value: string | null = null,   
        public value2: string | null = null,   
        public createdByUserId: number | null = null,   
        public createdDate: Date | null = null,   
        public updatedByUserId: number | null = null,   
        public updatedDate: Date | null = null,   
      ) { }
}

export class WorkOrderFieldValueV1Model {
    constructor(  
        // Inherited properties 
        
      
        //Properties
        
        public workOrderFieldValueId: number | null = null,   
        public workOrderId: number | null = null,   
        public procedureTaskStepFieldId: number | null = null,   
        public procedureTaskStepInstance: number | null = null,   
        public value: string | null = null,   
        public createdByUserId: number | null = null,   
        public createdDate: Date | null = null,   
        public updatedByUserId: number | null = null,   
        public updatedDate: Date | null = null,   
        public createdByUserName: string | null = null,   
        public updatedByUserName: string | null = null,   
        public accountId: number | null = null,   
      ) { }
}

export class ExecutionCurrentSectionV1Model {
    constructor(  
        // Inherited properties 
        
      
        //Properties
        
        public executionGuid: string | null = null,   
        public executionSection: ExecutionSection | null = null,   
        public planStepNumber: number | null = null,   
        public procedureTaskStepId: number | null = null,   
        public positionId: number | null = null,   
        public continueProcedure: boolean | null = null,   
        public delayCodeId: number | null = null,   
        public totalSeconds: number | null = null,   
        public totalRangeSeconds: number | null = null,   
        public previewStep: boolean | null = null,   
        public procedureTaskStepInstance: number | null = null,   
        public fieldValues: WorkOrderFieldValueInsertV1Model[] | null = null,   
      ) { }
}

export class WorkOrderFieldValueInsertV1Model {
    constructor(  
        // Inherited properties 
        
      
        //Properties
        
        public workOrderId: number | null = null,   
        public procedureTaskStepFieldId: number | null = null,   
        public procedureTaskStepInstance: number | null = null,   
        public value: string | null = null,   
      ) { }
}

export class ExecutionLaneV1Model {
    constructor(  
        // Inherited properties 
        
      
        //Properties
        
        public lane: string | null = null,   
        public nodes: ExecutionLaneNodeV1Model[] | null = null,   
        public laneNodeCount: number | null = null,   
        public laneColor: string | null = null,   
        public relatedTaskStepId: number | null = null,   
      ) { }
}

export class ExecutionLaneNodeV1Model {
    constructor(  
        // Inherited properties 
        
      
        //Properties
        
        public procedureRevisionId: number | null = null,   
        public procedureTaskStepId: number | null = null,   
        public planStepNumber: number | null = null,   
        public procedureTaskStepInstance: number | null = null,   
        public procedureTaskStepNumber: string | null = null,   
        public step: string | null = null,   
        public stepType: StepType | null = null,   
        public executionOrder: number | null = null,   
        public endStep: boolean | null = null,   
        public decisionNode: boolean | null = null,   
        public executionType: ExecutionType | null = null,   
        public executionStatus: ExecutionStatus | null = null,   
        public isLoopBack: boolean | null = null,   
        public relatedLaneColor: string | null = null,   
      ) { }
}

export class UpdateResponseV1Model {
    constructor(  
        // Inherited properties 
        
      
        //Properties
        
        public totalRowsUpdated: number | null = null,   
      ) { }
}

export class UpdateLogEntryV1Model {
    constructor(  
        // Inherited properties 
        
      
        //Properties
        
        public workOrderId: number | null = null,   
        public current: ExecutionCurrentSectionV1Model | null = null,   
        public completed: boolean | null = null,   
      ) { }
}

export class ExecutionSkipStepV1Model {
    constructor(  
        // Inherited properties 
        
      
        //Properties
        
        public workOrderId: number | null = null,   
        public procedureRevisionId: number | null = null,   
        public planStepNumber: number | null = null,   
      ) { }
}



export enum ExecutionSection
    { 
       PurposeScope = 1,
       References = 2,
       Responsibilities = 3,
       Precautions = 4,
       Prerequisites = 5,
       Instructions = 6,
       Criteria = 7,
       Retention = 8,
       Attachments = 9,
       Supplemental = 10,
       Completed = 11,
    }

export enum StepType
    { 
       Concurrent = 1,
       Continuous = 2,
       EquallyAcceptableAlternative = 3,
       Repeated = 4,
       Standard = 5,
       TimeDependent = 6,
       Equipment = 7,
       LaborResources = 8,
       Parts = 9,
       Tools = 10,
       ContinuousEnd = 11,
    }

export enum RuleType
    { 
       Concurrent = 1,
       Continuous = 2,
       EquallyAcceptableAlternative = 3,
       Repeated = 4,
       Standard = 5,
       TimeDependent = 6,
       SuccessFailure = 7,
       Required = 8,
       CalculatedValue = 10,
       ContinuousEnd = 11,
       ValidOptionsSuccess = 12,
    }

export enum OperandEnum
    { 
       Equal = 1,
       GreaterThan = 2,
       LessThan = 3,
       GreaterThanOrEqual = 4,
       LessThanOrEqual = 5,
       Between = 6,
    }

export enum MathOperatorEnum
    { 
       Plus = 1,
       Minus = 2,
       Multiply = 3,
       Divide = 4,
    }

export enum ExecutionType
    { 
       Sequential = 1,
       Parallel = 2,
       Conditional = 3,
    }

export enum ExecutionStatus
    { 
       NotStarted = 1,
       InProgressOnTime = 2,
       InProgressLate = 3,
       CompletedOnTime = 4,
       CompletedLate = 5,
       Skipped = 6,
       Approved = 7,
       Cancelled = 8,
    }



/* 
     Debug Info:
     PrintDebugInfo - put a dollar sign in front of Print to enable this 
*/

