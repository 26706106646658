import { Component, EventEmitter, Input, Output } from "@angular/core";
import { NgbActiveModal } from "@ng-bootstrap/ng-bootstrap";
import { HazardsService, HazardsV1Model } from "src/app/api/Hazards.service";
import {
  PrecautionsInsertV1Model,
  PrecautionsService,
} from "src/app/api/Precautions.service";
import { FormRepoService } from "src/app/modules/shared/services/form-repo.service";
import { ToastService } from "../../core/services/toast.service";

@Component({
  selector: "app-new-precaution-modal",
  templateUrl: "./new-precaution-modal.component.html",
  styleUrls: ["./new-precaution-modal.component.scss"],
})
export class NewPrecautionModalComponent {
  @Input() public hazardId!: number;
  @Input() isLibrary: boolean = true;
  @Output() public submitCompletedEvent = new EventEmitter();
  newPrecautionForm = this.formRepoService.newPrecautionForm();
  hazards: HazardsV1Model[] = [];
  precautionLevel: string | null = null;
  precautionLevels: string[] = ["CAUTION", "LIMITATION", "NOTE", "WARNING"];

  constructor(
    private precautionService: PrecautionsService,
    public activeModal: NgbActiveModal,
    private formRepoService: FormRepoService
  ) {}

  ngOnInit(): void {}

  onSubmit(): void {
    let model = new PrecautionsInsertV1Model();

    model.description = this.newPrecautionForm.controls["description"].value;
    model.hazardId = this.hazardId;
    model.precautionLevel = this.precautionLevel;
    if (this.newPrecautionForm.controls["isActive"].value == "true") {
      model.isActive = true;
    } else {
      model.isActive = false;
    }

    this.precautionService.insert(model).subscribe((data) => {
      this.submitCompletedEvent.emit(data.identityId);
      this.activeModal.close();
    });
  }

  onCancel(): void {
    this.activeModal.close();
  }
}
