 
   
   import { Injectable } from '@angular/core';
   import { HttpClient, HttpHeaders, HttpErrorResponse } from '@angular/common/http';

   import { Observable, of, throwError  } from 'rxjs';

   import { environment } from '../../environments/environment';
   //import { LoggerService } from '../../modules/core/logger.service'
   import { GenericSearchResultV1Model } from '../../app/models/GenericSearchResultV1Model';
   import { PageableListV1Model } from '../../app/models/PageableListV1Model';

   const httpOptions = {
     headers: new HttpHeaders({ 'Content-Type': 'application/json' }),
     withCredentials: true
   };

   const httpOptions_Handle400InComponent = {
     headers: new HttpHeaders({ 'Content-Type': 'application/json', 'I400Local': '' }),
     withCredentials: true
   }
    
   @Injectable({
     providedIn: 'root'
   })
   export class TagsService {

   
        private classVersion = "1.0";
        
         
      constructor(private http: HttpClient) { }
      
        
          
              getAccountTags( handle400Locally: boolean = false) : Observable<TagsV1Model[]>  { 
              let version = this.classVersion;
              return this.http.get<any>(environment.apiRoot + `api/v${version}/tags/GetAccountTags`, handle400Locally ? httpOptions_Handle400InComponent : httpOptions )       
            };
          
      

            


                  
        
          
              getTagsByID(tagId: number,  handle400Locally: boolean = false) : Observable<TagsV1Model>  { 
              let version = this.classVersion;
              return this.http.post<any>(environment.apiRoot + `api/v${version}/tags/GetById`,tagId, handle400Locally ? httpOptions_Handle400InComponent : httpOptions ) //p => p.Attributes.Count > 0 ----only adds FromBody     
            };
          
      
        
          
              getDocumentTags(documentId: number,  handle400Locally: boolean = false) : Observable<TagsV1Model[]>  { 
              let version = this.classVersion;
              return this.http.post<any>(environment.apiRoot + `api/v${version}/tags/GetDocumentTags`,documentId, handle400Locally ? httpOptions_Handle400InComponent : httpOptions ) //p => p.Attributes.Count > 0 ----only adds FromBody     
            };
          
      
        
          
              getPartTags(partId: number,  handle400Locally: boolean = false) : Observable<TagsV1Model[]>  { 
              let version = this.classVersion;
              return this.http.post<any>(environment.apiRoot + `api/v${version}/tags/GetPartTags`,partId, handle400Locally ? httpOptions_Handle400InComponent : httpOptions ) //p => p.Attributes.Count > 0 ----only adds FromBody     
            };
          
      
        
          
              getVideoTags(videoId: number,  handle400Locally: boolean = false) : Observable<TagsV1Model[]>  { 
              let version = this.classVersion;
              return this.http.post<any>(environment.apiRoot + `api/v${version}/tags/GetVideoTags`,videoId, handle400Locally ? httpOptions_Handle400InComponent : httpOptions ) //p => p.Attributes.Count > 0 ----only adds FromBody     
            };
          
      
        
          
              insert(model: TagsInsertV1Model,  handle400Locally: boolean = false) : Observable<InsertResponseV1Model>  { 
              let version = this.classVersion;
              return this.http.post<any>(environment.apiRoot + `api/v${version}/tags/Insert`,model, handle400Locally ? httpOptions_Handle400InComponent : httpOptions ) //p => p.Attributes.Count > 0 ----only adds FromBody     
            };
          
      
        
          
              update(model: TagsUpdateV1Model,  handle400Locally: boolean = false) : Observable<UpdateResponseV1Model>  { 
              let version = this.classVersion;
              return this.http.post<any>(environment.apiRoot + `api/v${version}/tags/Update`,model, handle400Locally ? httpOptions_Handle400InComponent : httpOptions ) //p => p.Attributes.Count > 0 ----only adds FromBody     
            };
          
      
        
          
              delete(tagId: number,  handle400Locally: boolean = false) : Observable<CommonResponseV1Model>  { 
              let version = this.classVersion;
              return this.http.post<any>(environment.apiRoot + `api/v${version}/tags/Delete`,tagId, handle400Locally ? httpOptions_Handle400InComponent : httpOptions ) //p => p.Attributes.Count > 0 ----only adds FromBody     
            };
          
      

                        

                  
}

export class TagsV1Model {
    constructor(  
        // Inherited properties 
        
      
        //Properties
        
        public tagId: number | null = null,   
        public tagName: string | null = null,   
        public accountId: number | null = null,   
      ) { }
}

export class InsertResponseV1Model  {
    constructor(  
        // Inherited properties 
        
        public success: boolean | null = null,
        public message: string | null = null,
      
        //Properties
        
        public identityId: number | null = null,   
        public identityIds: number[] | null = null,   
      ) { }
}

export class TagsInsertV1Model {
    constructor(  
        // Inherited properties 
        
      
        //Properties
        
        public tagName: string | null = null,   
      ) { }
}

export class UpdateResponseV1Model {
    constructor(  
        // Inherited properties 
        
      
        //Properties
        
        public totalRowsUpdated: number | null = null,   
      ) { }
}

export class TagsUpdateV1Model {
    constructor(  
        // Inherited properties 
        
      
        //Properties
        
        public tagId: number | null = null,   
        public tagName: string | null = null,   
      ) { }
}

export class CommonResponseV1Model {
    constructor(  
        // Inherited properties 
        
      
        //Properties
        
        public success: boolean | null = null,   
        public message: string | null = null,   
      ) { }
}





/* 
     Debug Info:
     PrintDebugInfo - put a dollar sign in front of Print to enable this 
*/

