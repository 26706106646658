 
   
   import { Injectable } from '@angular/core';
   import { HttpClient, HttpHeaders, HttpErrorResponse } from '@angular/common/http';

   import { Observable, of, throwError  } from 'rxjs';

   import { environment } from '../../environments/environment';
   //import { LoggerService } from '../../modules/core/logger.service'
   import { GenericSearchResultV1Model } from '../../app/models/GenericSearchResultV1Model';
   import { PageableListV1Model } from '../../app/models/PageableListV1Model';

   const httpOptions = {
     headers: new HttpHeaders({ 'Content-Type': 'application/json' }),
     withCredentials: true
   };

   const httpOptions_Handle400InComponent = {
     headers: new HttpHeaders({ 'Content-Type': 'application/json', 'I400Local': '' }),
     withCredentials: true
   }
    
   @Injectable({
     providedIn: 'root'
   })
   export class VideosService {

   
        private classVersion = "1.0";
        
         
      constructor(private http: HttpClient) { }
      

            


                  
        
          
              getById(model: VideoV1Model,  handle400Locally: boolean = false) : Observable<VideoV1Model[]>  { 
              let version = this.classVersion;
              return this.http.post<any>(environment.apiRoot + `api/v${version}/videos/GetById`,model, handle400Locally ? httpOptions_Handle400InComponent : httpOptions ) //p => p.Attributes.Count > 0 ----only adds FromBody     
            };
          
      
        
          
              getStepVideos(procedureTaskStepId: number,  handle400Locally: boolean = false) : Observable<ProcedureStepVideoV1Model[]>  { 
              let version = this.classVersion;
              return this.http.post<any>(environment.apiRoot + `api/v${version}/videos/GetStepVideos`,procedureTaskStepId, handle400Locally ? httpOptions_Handle400InComponent : httpOptions ) //p => p.Attributes.Count > 0 ----only adds FromBody     
            };
          
      
        
          
              getScopeDocumentVideos(scopeDocumentId: number,  handle400Locally: boolean = false) : Observable<ScopingVideoV1Model[]>  { 
              let version = this.classVersion;
              return this.http.post<any>(environment.apiRoot + `api/v${version}/videos/GetScopeDocumentVideos`,scopeDocumentId, handle400Locally ? httpOptions_Handle400InComponent : httpOptions ) //p => p.Attributes.Count > 0 ----only adds FromBody     
            };
          
      
        
          
              getScopingVideos(procedureTaskStepId: number,  handle400Locally: boolean = false) : Observable<VideoV1Model[]>  { 
              let version = this.classVersion;
              return this.http.post<any>(environment.apiRoot + `api/v${version}/videos/GetScopingVideos`,procedureTaskStepId, handle400Locally ? httpOptions_Handle400InComponent : httpOptions ) //p => p.Attributes.Count > 0 ----only adds FromBody     
            };
          
      
        
          
              getRevisionVideos(procedureRevisionId: number,  handle400Locally: boolean = false) : Observable<RevisionVideoV1Model[]>  { 
              let version = this.classVersion;
              return this.http.post<any>(environment.apiRoot + `api/v${version}/videos/GetRevisionVideos`,procedureRevisionId, handle400Locally ? httpOptions_Handle400InComponent : httpOptions ) //p => p.Attributes.Count > 0 ----only adds FromBody     
            };
          
      
        
          
              search(model: VideoSearchModelV1Model,  handle400Locally: boolean = false) : Observable<VideoV1Model[]>  { 
              let version = this.classVersion;
              return this.http.post<any>(environment.apiRoot + `api/v${version}/videos/Search`,model, handle400Locally ? httpOptions_Handle400InComponent : httpOptions ) //p => p.Attributes.Count > 0 ----only adds FromBody     
            };
          
      
        
          
              searchScopingVideo(model: VideoScopingSearchV1Model,  handle400Locally: boolean = false) : Observable<VideoV1Model[]>  { 
              let version = this.classVersion;
              return this.http.post<any>(environment.apiRoot + `api/v${version}/videos/SearchScopingVideo`,model, handle400Locally ? httpOptions_Handle400InComponent : httpOptions ) //p => p.Attributes.Count > 0 ----only adds FromBody     
            };
          
      
        
          
              searchProcedureStepScopingVideos(model: VideoScopingSearchV1Model,  handle400Locally: boolean = false) : Observable<VideoV1Model[]>  { 
              let version = this.classVersion;
              return this.http.post<any>(environment.apiRoot + `api/v${version}/videos/SearchProcedureStepScopingVideos`,model, handle400Locally ? httpOptions_Handle400InComponent : httpOptions ) //p => p.Attributes.Count > 0 ----only adds FromBody     
            };
          
      
        
          
              insertStepVideo(model: ProcedureStepVideoInsertV1Model,  handle400Locally: boolean = false) : Observable<InsertResponseV1Model>  { 
              let version = this.classVersion;
              return this.http.post<any>(environment.apiRoot + `api/v${version}/videos/InsertStepVideo`,model, handle400Locally ? httpOptions_Handle400InComponent : httpOptions ) //p => p.Attributes.Count > 0 ----only adds FromBody     
            };
          
      
        
          
              insertScopingVideo(model: ScopingVideoInsertV1Model,  handle400Locally: boolean = false) : Observable<InsertResponseV1Model>  { 
              let version = this.classVersion;
              return this.http.post<any>(environment.apiRoot + `api/v${version}/videos/InsertScopingVideo`,model, handle400Locally ? httpOptions_Handle400InComponent : httpOptions ) //p => p.Attributes.Count > 0 ----only adds FromBody     
            };
          
      
        
          
              update(model: VideoUpdateV1Model,  handle400Locally: boolean = false) : Observable<UpdateResponseV1Model>  { 
              let version = this.classVersion;
              return this.http.post<any>(environment.apiRoot + `api/v${version}/videos/Update`,model, handle400Locally ? httpOptions_Handle400InComponent : httpOptions ) //p => p.Attributes.Count > 0 ----only adds FromBody     
            };
          
      
        
          
              delete(videoId: number,  handle400Locally: boolean = false) : Observable<CommonResponseV1Model>  { 
              let version = this.classVersion;
              return this.http.post<any>(environment.apiRoot + `api/v${version}/videos/Delete`,videoId, handle400Locally ? httpOptions_Handle400InComponent : httpOptions ) //p => p.Attributes.Count > 0 ----only adds FromBody     
            };
          
      
        
          
              removeStepVideo(procedureTaskStepId: number, videoId: number,  handle400Locally: boolean = false) : Observable<CommonResponseV1Model>  { 
              let version = this.classVersion;
              return this.http.post<any>(environment.apiRoot + `api/v${version}/videos/RemoveStepVideo?videoId=${videoId}`,procedureTaskStepId, handle400Locally ? httpOptions_Handle400InComponent : httpOptions ) //p => p.Attributes.Count > 0 ----only adds FromBody     
            };
          
      
        
          
              removeScopingVideo(scopeDocumentId: number, videoId: number,  handle400Locally: boolean = false) : Observable<CommonResponseV1Model>  { 
              let version = this.classVersion;
              return this.http.post<any>(environment.apiRoot + `api/v${version}/videos/RemoveScopingVideo?videoId=${videoId}`,scopeDocumentId, handle400Locally ? httpOptions_Handle400InComponent : httpOptions ) //p => p.Attributes.Count > 0 ----only adds FromBody     
            };
          
      
        
          
              videoInactive(videoId: number,  handle400Locally: boolean = false) : Observable<UpdateResponseV1Model>  { 
              let version = this.classVersion;
              return this.http.post<any>(environment.apiRoot + `api/v${version}/videos/VideoInactive`,videoId, handle400Locally ? httpOptions_Handle400InComponent : httpOptions ) //p => p.Attributes.Count > 0 ----only adds FromBody     
            };
          
      

                        

                  
}

export class VideoV1Model {
    constructor(  
        // Inherited properties 
        
      
        //Properties
        
        public videoId: number | null = null,   
        public videoGuid: string | null = null,   
        public displayName: string | null = null,   
        public fileName: string | null = null,   
        public createdDate: Date | null = null,   
        public contentType: string | null = null,   
        public createdByUserId: number | null = null,   
        public createdByUserName: string | null = null,   
        public tagNames: string | null = null,   
        public accountId: number | null = null,   
        public isActive: boolean | null = null,   
        public videoNumber: number | null = null,   
      ) { }
}

export class ProcedureStepVideoV1Model  {
    constructor(  
        // Inherited properties 
        
        public videoId: number | null = null,
        public videoGuid: string | null = null,
        public displayName: string | null = null,
        public fileName: string | null = null,
        public createdDate: Date | null = null,
        public contentType: string | null = null,
        public createdByUserId: number | null = null,
        public createdByUserName: string | null = null,
        public tagNames: string | null = null,
        public accountId: number | null = null,
        public isActive: boolean | null = null,
        public videoNumber: number | null = null,
      
        //Properties
        

      ) { }
}

export class ScopingVideoV1Model  {
    constructor(  
        // Inherited properties 
        
        public videoId: number | null = null,
        public videoGuid: string | null = null,
        public displayName: string | null = null,
        public fileName: string | null = null,
        public createdDate: Date | null = null,
        public contentType: string | null = null,
        public createdByUserId: number | null = null,
        public createdByUserName: string | null = null,
        public tagNames: string | null = null,
        public accountId: number | null = null,
        public isActive: boolean | null = null,
        public videoNumber: number | null = null,
      
        //Properties
        
        public scopeDocumentVideoId: number | null = null,   
        public scopeDocumentId: number | null = null,   
       
      ) { }
}

export class RevisionVideoV1Model {
    constructor(  
        // Inherited properties 
        
      
        //Properties
        
        public videoId: number | null = null,   
        public videoGuid: string | null = null,   
        public displayName: string | null = null,   
        public fileName: string | null = null,   
        public contentType: string | null = null,   
      ) { }
}

export class VideoSearchModelV1Model {
    constructor(  
        // Inherited properties 
        
      
        //Properties
        
        public searchTerm: string | null = null,   
        public procedureRevisionId: number | null = null,   
        public procedureTaskStepId: number | null = null,   
      ) { }
}

export class VideoScopingSearchV1Model {
    constructor(  
        // Inherited properties 
        
      
        //Properties
        
        public searchTerm: string | null = null,   
        public scopeDocumentId: number | null = null,   
        public procedureTaskStepId: number | null = null,   
      ) { }
}

export class InsertResponseV1Model  {
    constructor(  
        // Inherited properties 
        
        public success: boolean | null = null,
        public message: string | null = null,
      
        //Properties
        
        public identityId: number | null = null,   
        public identityIds: number[] | null = null,   
      ) { }
}

export class ProcedureStepVideoInsertV1Model {
    constructor(  
        // Inherited properties 
        
      
        //Properties
        
        public procedureTaskStepId: number | null = null,   
        public videoId: number | null = null,   
      ) { }
}

export class ScopingVideoInsertV1Model {
    constructor(  
        // Inherited properties 
        
      
        //Properties
        
        public scopeDocumentId: number | null = null,   
        public videoId: number | null = null,   
      ) { }
}

export class UpdateResponseV1Model {
    constructor(  
        // Inherited properties 
        
      
        //Properties
        
        public totalRowsUpdated: number | null = null,   
      ) { }
}

export class VideoUpdateV1Model {
    constructor(  
        // Inherited properties 
        
      
        //Properties
        
        public videoId: number | null = null,   
        public fileName: string | null = null,   
        public displayName: string | null = null,   
        public videoGuid: string | null = null,   
        public contentType: string | null = null,   
        public tags: TagsV1Model[] | null = null,   
        public isActive: boolean | null = null,   
      ) { }
}

export class TagsV1Model {
    constructor(  
        // Inherited properties 
        
      
        //Properties
        
        public tagId: number | null = null,   
        public tagName: string | null = null,   
        public accountId: number | null = null,   
      ) { }
}

export class CommonResponseV1Model {
    constructor(  
        // Inherited properties 
        
      
        //Properties
        
        public success: boolean | null = null,   
        public message: string | null = null,   
      ) { }
}





/* 
     Debug Info:
     PrintDebugInfo - put a dollar sign in front of Print to enable this 
*/

