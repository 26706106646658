 
   
   import { Injectable } from '@angular/core';
   import { HttpClient, HttpHeaders, HttpErrorResponse } from '@angular/common/http';

   import { Observable, of, throwError  } from 'rxjs';

   import { environment } from '../../environments/environment';
   //import { LoggerService } from '../../modules/core/logger.service'
   import { GenericSearchResultV1Model } from '../../app/models/GenericSearchResultV1Model';
   import { PageableListV1Model } from '../../app/models/PageableListV1Model';

   const httpOptions = {
     headers: new HttpHeaders({ 'Content-Type': 'application/json' }),
     withCredentials: true
   };

   const httpOptions_Handle400InComponent = {
     headers: new HttpHeaders({ 'Content-Type': 'application/json', 'I400Local': '' }),
     withCredentials: true
   }
    
   @Injectable({
     providedIn: 'root'
   })
   export class ProcedureRevisionService {

   
        private classVersion = "1.0";
        
         
      constructor(private http: HttpClient) { }
      
        
          
              getByID(procedureRevisionId: number,  handle400Locally: boolean = false) : Observable<ProcedureRevisionV1Model>  { 
              let version = this.classVersion;
              return this.http.get<any>(environment.apiRoot + `api/v${version}/procedureRevision/GetById/${procedureRevisionId}`, handle400Locally ? httpOptions_Handle400InComponent : httpOptions )       
            };
          
      
        
          
              getLatest(procedureId: number,  handle400Locally: boolean = false) : Observable<ProcedureRevisionV1Model>  { 
              let version = this.classVersion;
              return this.http.get<any>(environment.apiRoot + `api/v${version}/procedureRevision/GetLatest/${procedureId}`, handle400Locally ? httpOptions_Handle400InComponent : httpOptions )       
            };
          
      
        
          
              getAllDepartmentOwners( handle400Locally: boolean = false) : Observable<ProcedureRevisionV1Model[]>  { 
              let version = this.classVersion;
              return this.http.get<any>(environment.apiRoot + `api/v${version}/procedureRevision/GetAllDepartmentOwners`, handle400Locally ? httpOptions_Handle400InComponent : httpOptions )       
            };
          
      

            


                  
        
          
              insertCopy(procedureRevisionId: number,  handle400Locally: boolean = false) : Observable<InsertResponseV1Model>  { 
              let version = this.classVersion;
              return this.http.post<any>(environment.apiRoot + `api/v${version}/procedureRevision/InsertCopy`,procedureRevisionId, handle400Locally ? httpOptions_Handle400InComponent : httpOptions ) //p => p.Attributes.Count > 0 ----only adds FromBody     
            };
          
      
        
          
              update(model: ProcedureRevisionUpdateV1Model,  handle400Locally: boolean = false) : Observable<UpdateResponseV1Model>  { 
              let version = this.classVersion;
              return this.http.post<any>(environment.apiRoot + `api/v${version}/procedureRevision/Update`,model, handle400Locally ? httpOptions_Handle400InComponent : httpOptions ) //p => p.Attributes.Count > 0 ----only adds FromBody     
            };
          
      
        
          
              publishDraft(model: ProcedureRevisionUpdateV1Model,  handle400Locally: boolean = false) : Observable<UpdateResponseV1Model>  { 
              let version = this.classVersion;
              return this.http.post<any>(environment.apiRoot + `api/v${version}/procedureRevision/PublishDraft`,model, handle400Locally ? httpOptions_Handle400InComponent : httpOptions ) //p => p.Attributes.Count > 0 ----only adds FromBody     
            };
          
      
        
          
              updateRevisionSettings(model: ProcedureRevisionSettingsV1Model,  handle400Locally: boolean = false) : Observable<ProceduresUpdateV1Model>  { 
              let version = this.classVersion;
              return this.http.post<any>(environment.apiRoot + `api/v${version}/procedureRevision/UpdateRevisionSettings`,model, handle400Locally ? httpOptions_Handle400InComponent : httpOptions ) //p => p.Attributes.Count > 0 ----only adds FromBody     
            };
          
      

                        

                  
}

export class ProcedureRevisionV1Model {
    constructor(  
        // Inherited properties 
        
      
        //Properties
        
        public procedureRevisionId: number | null = null,   
        public procedureId: number | null = null,   
        public revision: number | null = null,   
        public isDraft: boolean | null = null,   
        public createdByUserId: number | null = null,   
        public createdDate: Date | null = null,   
        public createdByUser: string | null = null,   
        public updatedByUserId: number | null = null,   
        public updatedDate: Date | null = null,   
        public updatedByUser: string | null = null,   
        public name: string | null = null,   
        public procedureNumber: string | null = null,   
        public levelOfUse: string | null = null,   
        public effectiveDate: Date | null = null,   
        public totalStepCount: number | null = null,   
        public totalDurationMintues: number | null = null,   
        public version: number | null = null,   
        public purpose: string | null = null,   
        public showAdditionalTabs: boolean | null = null,   
        public scope: string | null = null,   
        public accountId: number | null = null,   
        public departmentOwner: string | null = null,   
        public writtenBy: string | null = null,   
        public approvedBy: string | null = null,   
        public description: string | null = null,   
        public scopeDocumentId: number | null = null,   
      ) { }
}

export class InsertResponseV1Model  {
    constructor(  
        // Inherited properties 
        
        public success: boolean | null = null,
        public message: string | null = null,
      
        //Properties
        
        public identityId: number | null = null,   
        public identityIds: number[] | null = null,   
      ) { }
}

export class UpdateResponseV1Model {
    constructor(  
        // Inherited properties 
        
      
        //Properties
        
        public totalRowsUpdated: number | null = null,   
      ) { }
}

export class ProcedureRevisionUpdateV1Model {
    constructor(  
        // Inherited properties 
        
      
        //Properties
        
        public procedureRevisionId: number | null = null,   
        public isDraft: boolean | null = null,   
        public effectiveDate: Date | null = null,   
        public purpose: string | null = null,   
        public scope: string | null = null,   
        public levelOfUse: string | null = null,   
        public departmentOwner: string | null = null,   
        public description: string | null = null,   
      ) { }
}

export class ProceduresUpdateV1Model {
    constructor(  
        // Inherited properties 
        
      
        //Properties
        
        public procedureId: number | null = null,   
        public name: string | null = null,   
        public procedureNumber: string | null = null,   
        public scopeDocumentId: number | null = null,   
      ) { }
}

export class ProcedureRevisionSettingsV1Model {
    constructor(  
        // Inherited properties 
        
      
        //Properties
        
        public procedureRevisionId: number | null = null,   
        public showAdditionalTabs: boolean | null = null,   
        public showDecimals: boolean | null = null,   
      ) { }
}





/* 
     Debug Info:
     PrintDebugInfo - put a dollar sign in front of Print to enable this 
*/

