 
   
   import { Injectable } from '@angular/core';
   import { HttpClient, HttpHeaders, HttpErrorResponse } from '@angular/common/http';

   import { Observable, of, throwError  } from 'rxjs';

   import { environment } from '../../environments/environment';
   //import { LoggerService } from '../../modules/core/logger.service'
   import { GenericSearchResultV1Model } from '../../app/models/GenericSearchResultV1Model';
   import { PageableListV1Model } from '../../app/models/PageableListV1Model';

   const httpOptions = {
     headers: new HttpHeaders({ 'Content-Type': 'application/json' }),
     withCredentials: true
   };

   const httpOptions_Handle400InComponent = {
     headers: new HttpHeaders({ 'Content-Type': 'application/json', 'I400Local': '' }),
     withCredentials: true
   }
    
   @Injectable({
     providedIn: 'root'
   })
   export class ProcedureTaskStepsService {

   
        private classVersion = "1.0";
        
         
      constructor(private http: HttpClient) { }
      
        
          
              getAllCurrentAccountSteps( handle400Locally: boolean = false) : Observable<ProcedureStepMinV1Model[]>  { 
              let version = this.classVersion;
              return this.http.get<any>(environment.apiRoot + `api/v${version}/procedureTaskSteps/GetAllCurrentAccountSteps`, handle400Locally ? httpOptions_Handle400InComponent : httpOptions )       
            };
          
      
        
          
              getByID(procedureTaskStepId: number,  handle400Locally: boolean = false) : Observable<ProcedureStepV1Model>  { 
              let version = this.classVersion;
              return this.http.get<any>(environment.apiRoot + `api/v${version}/procedureTaskSteps/GetById/${procedureTaskStepId}`, handle400Locally ? httpOptions_Handle400InComponent : httpOptions )       
            };
          
      
        
          
              getPreviousStep(procedureTaskStepId: number,  handle400Locally: boolean = false) : Observable<ProcedureStepV1Model>  { 
              let version = this.classVersion;
              return this.http.get<any>(environment.apiRoot + `api/v${version}/procedureTaskSteps/GetPreviousStep/${procedureTaskStepId}`, handle400Locally ? httpOptions_Handle400InComponent : httpOptions )       
            };
          
      
        
          
              getStepItemCount(procedureTaskStepId: number,  handle400Locally: boolean = false) : Observable<ProcedureStepItemCountV1Model>  { 
              let version = this.classVersion;
              return this.http.get<any>(environment.apiRoot + `api/v${version}/procedureTaskSteps/GetStepItemCount/${procedureTaskStepId}`, handle400Locally ? httpOptions_Handle400InComponent : httpOptions )       
            };
          
      
        
          
              getScopingCount(scopeDocumentId: number,  handle400Locally: boolean = false) : Observable<ScopingItemCountV1Model>  { 
              let version = this.classVersion;
              return this.http.get<any>(environment.apiRoot + `api/v${version}/procedureTaskSteps/GetScopingCount/${scopeDocumentId}`, handle400Locally ? httpOptions_Handle400InComponent : httpOptions )       
            };
          
      
        
          
              getNextSteps(procedureTaskStepId: number,  handle400Locally: boolean = false) : Observable<ProcedureStepV1Model[]>  { 
              let version = this.classVersion;
              return this.http.get<any>(environment.apiRoot + `api/v${version}/procedureTaskSteps/GetNextSteps/${procedureTaskStepId}`, handle400Locally ? httpOptions_Handle400InComponent : httpOptions )       
            };
          
      
        
          
              getDefaultNextStep(procedureTaskStepId: number,  handle400Locally: boolean = false) : Observable<ProcedureStepV1Model[]>  { 
              let version = this.classVersion;
              return this.http.get<any>(environment.apiRoot + `api/v${version}/procedureTaskSteps/GetDefaultNextStep/${procedureTaskStepId}`, handle400Locally ? httpOptions_Handle400InComponent : httpOptions )       
            };
          
      
        
          
              getFieldRules(procedureTaskStepFieldId: number,  handle400Locally: boolean = false) : Observable<ProcedureRuleV1Model[]>  { 
              let version = this.classVersion;
              return this.http.get<any>(environment.apiRoot + `api/v${version}/procedureTaskSteps/GetFieldRules/${procedureTaskStepFieldId}`, handle400Locally ? httpOptions_Handle400InComponent : httpOptions )       
            };
          
      
        
          
              getRuleRangesByRuleID(procedureTaskStepRuleId: number,  handle400Locally: boolean = false) : Observable<ProcedureRuleRangeV1Model[]>  { 
              let version = this.classVersion;
              return this.http.get<any>(environment.apiRoot + `api/v${version}/procedureTaskSteps/GetRuleRangesByRuleID/${procedureTaskStepRuleId}`, handle400Locally ? httpOptions_Handle400InComponent : httpOptions )       
            };
          
      

            


                  
        
          
              getAllStepsForProcedureRevision(procedureRevisionId: number,  handle400Locally: boolean = false) : Observable<ProcedureStepMinV1Model[]>  { 
              let version = this.classVersion;
              return this.http.post<any>(environment.apiRoot + `api/v${version}/procedureTaskSteps/GetAllStepsForProcedureRevision`,procedureRevisionId, handle400Locally ? httpOptions_Handle400InComponent : httpOptions ) //p => p.Attributes.Count > 0 ----only adds FromBody     
            };
          
      
        
          
              getScopingInstructions(scopeDocumentId: number,  handle400Locally: boolean = false) : Observable<ScopingInstructionV1Model[]>  { 
              let version = this.classVersion;
              return this.http.post<any>(environment.apiRoot + `api/v${version}/procedureTaskSteps/GetScopingInstructions`,scopeDocumentId, handle400Locally ? httpOptions_Handle400InComponent : httpOptions ) //p => p.Attributes.Count > 0 ----only adds FromBody     
            };
          
      
        
          
              insertStandardStep(model: ProcedureStepInsertV1Model,  handle400Locally: boolean = false) : Observable<InsertResponseV1Model>  { 
              let version = this.classVersion;
              return this.http.post<any>(environment.apiRoot + `api/v${version}/procedureTaskSteps/InsertStandardStep`,model, handle400Locally ? httpOptions_Handle400InComponent : httpOptions ) //p => p.Attributes.Count > 0 ----only adds FromBody     
            };
          
      
        
          
              insertEquallyAcceptableAlternativeStep(model: ProcedureStepEquallyAcceptableAlternativeInsertV1Model,  handle400Locally: boolean = false) : Observable<InsertResponseV1Model>  { 
              let version = this.classVersion;
              return this.http.post<any>(environment.apiRoot + `api/v${version}/procedureTaskSteps/InsertEquallyAcceptableAlternativeStep`,model, handle400Locally ? httpOptions_Handle400InComponent : httpOptions ) //p => p.Attributes.Count > 0 ----only adds FromBody     
            };
          
      
        
          
              insertContinuousStep(model: ProcedureStepContinuousInsertV1Model,  handle400Locally: boolean = false) : Observable<InsertResponseV1Model>  { 
              let version = this.classVersion;
              return this.http.post<any>(environment.apiRoot + `api/v${version}/procedureTaskSteps/InsertContinuousStep`,model, handle400Locally ? httpOptions_Handle400InComponent : httpOptions ) //p => p.Attributes.Count > 0 ----only adds FromBody     
            };
          
      
        
          
              insertConcurrentStep(model: ProcedureStepConcurrentInsertV1Model,  handle400Locally: boolean = false) : Observable<InsertResponseV1Model>  { 
              let version = this.classVersion;
              return this.http.post<any>(environment.apiRoot + `api/v${version}/procedureTaskSteps/InsertConcurrentStep`,model, handle400Locally ? httpOptions_Handle400InComponent : httpOptions ) //p => p.Attributes.Count > 0 ----only adds FromBody     
            };
          
      
        
          
              insertTimeDependentStep(model: ProcedureStepTimeDependentInsertV1Model,  handle400Locally: boolean = false) : Observable<InsertResponseV1Model>  { 
              let version = this.classVersion;
              return this.http.post<any>(environment.apiRoot + `api/v${version}/procedureTaskSteps/InsertTimeDependentStep`,model, handle400Locally ? httpOptions_Handle400InComponent : httpOptions ) //p => p.Attributes.Count > 0 ----only adds FromBody     
            };
          
      
        
          
              insertRepeatedStep(model: ProcedureStepRepeatedInsertV1Model,  handle400Locally: boolean = false) : Observable<InsertResponseV1Model>  { 
              let version = this.classVersion;
              return this.http.post<any>(environment.apiRoot + `api/v${version}/procedureTaskSteps/InsertRepeatedStep`,model, handle400Locally ? httpOptions_Handle400InComponent : httpOptions ) //p => p.Attributes.Count > 0 ----only adds FromBody     
            };
          
      
        
          
              updateStepOrder(model: UpdateProcedureStepOrderV1Model,  handle400Locally: boolean = false) : Observable<UpdateResponseV1Model>  { 
              let version = this.classVersion;
              return this.http.post<any>(environment.apiRoot + `api/v${version}/procedureTaskSteps/UpdateStepOrder`,model, handle400Locally ? httpOptions_Handle400InComponent : httpOptions ) //p => p.Attributes.Count > 0 ----only adds FromBody     
            };
          
      
        
          
              updateNextStep(model: ProcedureStepNextStepUpdateV1Model,  handle400Locally: boolean = false) : Observable<UpdateResponseV1Model>  { 
              let version = this.classVersion;
              return this.http.post<any>(environment.apiRoot + `api/v${version}/procedureTaskSteps/UpdateNextStep`,model, handle400Locally ? httpOptions_Handle400InComponent : httpOptions ) //p => p.Attributes.Count > 0 ----only adds FromBody     
            };
          
      
        
          
              update(model: ProcedureStepUpdateV1Model,  handle400Locally: boolean = false) : Observable<UpdateResponseV1Model>  { 
              let version = this.classVersion;
              return this.http.post<any>(environment.apiRoot + `api/v${version}/procedureTaskSteps/Update`,model, handle400Locally ? httpOptions_Handle400InComponent : httpOptions ) //p => p.Attributes.Count > 0 ----only adds FromBody     
            };
          
      
        
          
              delete(procedureTaskStepId: number,  handle400Locally: boolean = false) : Observable<CommonResponseV1Model>  { 
              let version = this.classVersion;
              return this.http.post<any>(environment.apiRoot + `api/v${version}/procedureTaskSteps/Delete`,procedureTaskStepId, handle400Locally ? httpOptions_Handle400InComponent : httpOptions ) //p => p.Attributes.Count > 0 ----only adds FromBody     
            };
          
      
        
          
              getProcedureStepScopeInstructions(model: ProcedureStepInstructionSearchV1Model,  handle400Locally: boolean = false) : Observable<ProcedureStepInstructionV1Model[]>  { 
              let version = this.classVersion;
              return this.http.post<any>(environment.apiRoot + `api/v${version}/procedureTaskSteps/GetProcedureStepScopeInstructions`,model, handle400Locally ? httpOptions_Handle400InComponent : httpOptions ) //p => p.Attributes.Count > 0 ----only adds FromBody     
            };
          
      
        
          
              insertStepInstruction(model: ProcedureStepInstructionInsertV1Model,  handle400Locally: boolean = false) : Observable<InsertResponseV1Model>  { 
              let version = this.classVersion;
              return this.http.post<any>(environment.apiRoot + `api/v${version}/procedureTaskSteps/InsertStepInstruction`,model, handle400Locally ? httpOptions_Handle400InComponent : httpOptions ) //p => p.Attributes.Count > 0 ----only adds FromBody     
            };
          
      
        
          
              insertScopingInstruction(model: ScopingInstructionInsertV1Model,  handle400Locally: boolean = false) : Observable<InsertResponseV1Model>  { 
              let version = this.classVersion;
              return this.http.post<any>(environment.apiRoot + `api/v${version}/procedureTaskSteps/InsertScopingInstruction`,model, handle400Locally ? httpOptions_Handle400InComponent : httpOptions ) //p => p.Attributes.Count > 0 ----only adds FromBody     
            };
          
      
        
          
              updateStepInstructionOrder(model: ProcedureStepInstructionUpdateV1Model,  handle400Locally: boolean = false) : Observable<ProceduresUpdateV1Model>  { 
              let version = this.classVersion;
              return this.http.post<any>(environment.apiRoot + `api/v${version}/procedureTaskSteps/UpdateStepInstructionOrder`,model, handle400Locally ? httpOptions_Handle400InComponent : httpOptions ) //p => p.Attributes.Count > 0 ----only adds FromBody     
            };
          
      
        
          
              updateStepInstruction(model: ProcedureStepInstructionUpdateV1Model,  handle400Locally: boolean = false) : Observable<ProceduresUpdateV1Model>  { 
              let version = this.classVersion;
              return this.http.post<any>(environment.apiRoot + `api/v${version}/procedureTaskSteps/UpdateStepInstruction`,model, handle400Locally ? httpOptions_Handle400InComponent : httpOptions ) //p => p.Attributes.Count > 0 ----only adds FromBody     
            };
          
      
        
          
              updateScopingInstruction(model: ScopingInstructionUpdateV1Model,  handle400Locally: boolean = false) : Observable<UpdateResponseV1Model>  { 
              let version = this.classVersion;
              return this.http.post<any>(environment.apiRoot + `api/v${version}/procedureTaskSteps/UpdateScopingInstruction`,model, handle400Locally ? httpOptions_Handle400InComponent : httpOptions ) //p => p.Attributes.Count > 0 ----only adds FromBody     
            };
          
      
        
          
              removeScopingInstruction(scopeDocumentInstructionId: number,  handle400Locally: boolean = false) : Observable<CommonResponseV1Model>  { 
              let version = this.classVersion;
              return this.http.post<any>(environment.apiRoot + `api/v${version}/procedureTaskSteps/RemoveScopingInstruction`,scopeDocumentInstructionId, handle400Locally ? httpOptions_Handle400InComponent : httpOptions ) //p => p.Attributes.Count > 0 ----only adds FromBody     
            };
          
      
        
          
              deleteStepInstructionById(procedureTaskStepInstructionId: number,  handle400Locally: boolean = false) : Observable<CommonResponseV1Model>  { 
              let version = this.classVersion;
              return this.http.post<any>(environment.apiRoot + `api/v${version}/procedureTaskSteps/DeleteStepInstructionById`,procedureTaskStepInstructionId, handle400Locally ? httpOptions_Handle400InComponent : httpOptions ) //p => p.Attributes.Count > 0 ----only adds FromBody     
            };
          
      
        
          
              getInputFieldsByRevisionID(procedureRevisionId: number,  handle400Locally: boolean = false) : Observable<ProcedureTaskStepFieldV1Model[]>  { 
              let version = this.classVersion;
              return this.http.post<any>(environment.apiRoot + `api/v${version}/procedureTaskSteps/GetInputFieldsByRevisionID`,procedureRevisionId, handle400Locally ? httpOptions_Handle400InComponent : httpOptions ) //p => p.Attributes.Count > 0 ----only adds FromBody     
            };
          
      
        
          
              insertStepField(model: ProcedureTaskStepFieldInsertV1Model,  handle400Locally: boolean = false) : Observable<InsertResponseV1Model>  { 
              let version = this.classVersion;
              return this.http.post<any>(environment.apiRoot + `api/v${version}/procedureTaskSteps/InsertStepField`,model, handle400Locally ? httpOptions_Handle400InComponent : httpOptions ) //p => p.Attributes.Count > 0 ----only adds FromBody     
            };
          
      
        
          
              insertStepFieldCalculatedValue(model: ProcedureTaskStepFieldCalculatedValueInsertV1Model,  handle400Locally: boolean = false) : Observable<InsertResponseV1Model>  { 
              let version = this.classVersion;
              return this.http.post<any>(environment.apiRoot + `api/v${version}/procedureTaskSteps/InsertStepFieldCalculatedValue`,model, handle400Locally ? httpOptions_Handle400InComponent : httpOptions ) //p => p.Attributes.Count > 0 ----only adds FromBody     
            };
          
      
        
          
              updateCalculatedValueFieldRule(model: ProcedureRuleCalculatedValueUpdateV1Model,  handle400Locally: boolean = false) : Observable<UpdateResponseV1Model>  { 
              let version = this.classVersion;
              return this.http.post<any>(environment.apiRoot + `api/v${version}/procedureTaskSteps/UpdateCalculatedValueFieldRule`,model, handle400Locally ? httpOptions_Handle400InComponent : httpOptions ) //p => p.Attributes.Count > 0 ----only adds FromBody     
            };
          
      
        
          
              updateStepField(model: ProcedureTaskStepFieldUpdateV1Model,  handle400Locally: boolean = false) : Observable<InsertResponseV1Model>  { 
              let version = this.classVersion;
              return this.http.post<any>(environment.apiRoot + `api/v${version}/procedureTaskSteps/UpdateStepInstructionField`,model, handle400Locally ? httpOptions_Handle400InComponent : httpOptions ) //p => p.Attributes.Count > 0 ----only adds FromBody     
            };
          
      
        
          
              deleteStepField(procedureTaskStepFieldId: number,  handle400Locally: boolean = false) : Observable<CommonResponseV1Model>  { 
              let version = this.classVersion;
              return this.http.post<any>(environment.apiRoot + `api/v${version}/procedureTaskSteps/DeleteStepField`,procedureTaskStepFieldId, handle400Locally ? httpOptions_Handle400InComponent : httpOptions ) //p => p.Attributes.Count > 0 ----only adds FromBody     
            };
          
      
        
          
              deleteFieldRule(procedureTaskStepRuleId: number,  handle400Locally: boolean = false) : Observable<InsertResponseV1Model>  { 
              let version = this.classVersion;
              return this.http.post<any>(environment.apiRoot + `api/v${version}/procedureTaskSteps/DeleteFieldRule`,procedureTaskStepRuleId, handle400Locally ? httpOptions_Handle400InComponent : httpOptions ) //p => p.Attributes.Count > 0 ----only adds FromBody     
            };
          
      
        
          
              insertSuccessFailureFieldRule(model: ProcedureRuleSuccessFailureInsertV1Model,  handle400Locally: boolean = false) : Observable<InsertResponseV1Model>  { 
              let version = this.classVersion;
              return this.http.post<any>(environment.apiRoot + `api/v${version}/procedureTaskSteps/InsertSuccessFailureFieldRule`,model, handle400Locally ? httpOptions_Handle400InComponent : httpOptions ) //p => p.Attributes.Count > 0 ----only adds FromBody     
            };
          
      
        
          
              updateSuccessFailureFieldRule(model: ProcedureRuleSuccessFailureUpdateV1Model,  handle400Locally: boolean = false) : Observable<InsertResponseV1Model>  { 
              let version = this.classVersion;
              return this.http.post<any>(environment.apiRoot + `api/v${version}/procedureTaskSteps/UpdateSuccessFailureFieldRule`,model, handle400Locally ? httpOptions_Handle400InComponent : httpOptions ) //p => p.Attributes.Count > 0 ----only adds FromBody     
            };
          
      
        
          
              insertRequiredFieldRule(model: ProcedureRuleRequiredInsertV1Model,  handle400Locally: boolean = false) : Observable<InsertResponseV1Model>  { 
              let version = this.classVersion;
              return this.http.post<any>(environment.apiRoot + `api/v${version}/procedureTaskSteps/InsertRequiredFieldRule`,model, handle400Locally ? httpOptions_Handle400InComponent : httpOptions ) //p => p.Attributes.Count > 0 ----only adds FromBody     
            };
          
      
        
          
              insertValidOptionsFieldRule(model: ProcedureRuleValidOptionsInsertV1Model,  handle400Locally: boolean = false) : Observable<InsertResponseV1Model>  { 
              let version = this.classVersion;
              return this.http.post<any>(environment.apiRoot + `api/v${version}/procedureTaskSteps/InsertValidOptionsFieldRule`,model, handle400Locally ? httpOptions_Handle400InComponent : httpOptions ) //p => p.Attributes.Count > 0 ----only adds FromBody     
            };
          
      
        
          
              updateValidOptionsFieldRule(model: ProcedureRuleValidOptionsUpdateV1Model,  handle400Locally: boolean = false) : Observable<InsertResponseV1Model>  { 
              let version = this.classVersion;
              return this.http.post<any>(environment.apiRoot + `api/v${version}/procedureTaskSteps/UpdateValidOptionsFieldRule`,model, handle400Locally ? httpOptions_Handle400InComponent : httpOptions ) //p => p.Attributes.Count > 0 ----only adds FromBody     
            };
          
      
        
          
              updateEquallyAcceptableAlternativeFieldRule(model: ProcedureRuleEquallyAcceptableAlternativeUpdateV1Model,  handle400Locally: boolean = false) : Observable<InsertResponseV1Model>  { 
              let version = this.classVersion;
              return this.http.post<any>(environment.apiRoot + `api/v${version}/procedureTaskSteps/UpdateEquallyAcceptableAlternativeFieldRule`,model, handle400Locally ? httpOptions_Handle400InComponent : httpOptions ) //p => p.Attributes.Count > 0 ----only adds FromBody     
            };
          
      
        
          
              updateConcurrentFieldRule(model: ProcedureRuleConcurrentUpdateV1Model,  handle400Locally: boolean = false) : Observable<UpdateResponseV1Model>  { 
              let version = this.classVersion;
              return this.http.post<any>(environment.apiRoot + `api/v${version}/procedureTaskSteps/UpdateConcurrentFieldRule`,model, handle400Locally ? httpOptions_Handle400InComponent : httpOptions ) //p => p.Attributes.Count > 0 ----only adds FromBody     
            };
          
      
        
          
              updateTimeDependentFieldRule(model: ProcedureRuleTimeDependentUpdateV1Model,  handle400Locally: boolean = false) : Observable<UpdateResponseV1Model>  { 
              let version = this.classVersion;
              return this.http.post<any>(environment.apiRoot + `api/v${version}/procedureTaskSteps/UpdateTimeDependentFieldRule`,model, handle400Locally ? httpOptions_Handle400InComponent : httpOptions ) //p => p.Attributes.Count > 0 ----only adds FromBody     
            };
          
      
        
          
              updateRepeatedFieldRule(model: ProcedureRuleRepeatedUpdateV1Model,  handle400Locally: boolean = false) : Observable<UpdateResponseV1Model>  { 
              let version = this.classVersion;
              return this.http.post<any>(environment.apiRoot + `api/v${version}/procedureTaskSteps/UpdateRepeatedFieldRule`,model, handle400Locally ? httpOptions_Handle400InComponent : httpOptions ) //p => p.Attributes.Count > 0 ----only adds FromBody     
            };
          
      
        
          
              updateContinuousFieldRule(model: ProcedureRuleContinuousUpdateV1Model,  handle400Locally: boolean = false) : Observable<InsertResponseV1Model>  { 
              let version = this.classVersion;
              return this.http.post<any>(environment.apiRoot + `api/v${version}/procedureTaskSteps/UpdateContinuousFieldRule`,model, handle400Locally ? httpOptions_Handle400InComponent : httpOptions ) //p => p.Attributes.Count > 0 ----only adds FromBody     
            };
          
      
        
          
              updateContinuousEndFieldRule(model: ProcedureRuleContinuousEndUpdateV1Model,  handle400Locally: boolean = false) : Observable<InsertResponseV1Model>  { 
              let version = this.classVersion;
              return this.http.post<any>(environment.apiRoot + `api/v${version}/procedureTaskSteps/UpdateContinuousEndFieldRule`,model, handle400Locally ? httpOptions_Handle400InComponent : httpOptions ) //p => p.Attributes.Count > 0 ----only adds FromBody     
            };
          
      
        
          
              insertFieldRuleValue(model: ProcedureRuleValueInsertV1Model,  handle400Locally: boolean = false) : Observable<InsertResponseV1Model>  { 
              let version = this.classVersion;
              return this.http.post<any>(environment.apiRoot + `api/v${version}/procedureTaskSteps/InsertFieldRuleValue`,model, handle400Locally ? httpOptions_Handle400InComponent : httpOptions ) //p => p.Attributes.Count > 0 ----only adds FromBody     
            };
          
      

                        

                  
}

export class ProcedureStepMinV1Model {
    constructor(  
        // Inherited properties 
        
      
        //Properties
        
        public procedureTaskStepId: number | null = null,   
        public procedureTaskId: number | null = null,   
        public taskTypeId: number | null = null,   
        public step: string | null = null,   
        public stepName: string | null = null,   
        public sequential: boolean | null = null,   
        public duration: number | null = null,   
        public holdId: number | null = null,   
        public createdByUserId: number | null = null,   
        public createdDate: Date | null = null,   
        public updatedByUserId: number | null = null,   
        public updatedDate: Date | null = null,   
        public sort: number | null = null,   
        public accountId: number | null = null,   
        public stepType: StepType | null = null,   
        public repeatedCount: number | null = null,   
        public stepNumber: number | null = null,   
        public prerequisiteStep: boolean | null = null,   
      ) { }
}

export class ProcedureStepV1Model {
    constructor(  
        // Inherited properties 
        
      
        //Properties
        
        public procedureTaskStepId: number | null = null,   
        public procedureTaskId: number | null = null,   
        public taskTypeId: number | null = null,   
        public task: string | null = null,   
        public title: string | null = null,   
        public step: string | null = null,   
        public stepName: string | null = null,   
        public sequential: boolean | null = null,   
        public critical: boolean | null = null,   
        public duration: number | null = null,   
        public repeatedCount: number | null = null,   
        public holdId: number | null = null,   
        public holdName: string | null = null,   
        public createdByUserId: number | null = null,   
        public createdDate: Date | null = null,   
        public updatedByUserId: number | null = null,   
        public updatedDate: Date | null = null,   
        public accountId: number | null = null,   
        public stepNumber: number | null = null,   
        public totalSteps: number | null = null,   
        public stepType: StepType | null = null,   
        public sort: number | null = null,   
        public prerequisiteStep: boolean | null = null,   
        public durationTolerance: number | null = null,   
        public endStep: boolean | null = null,   
        public failureStep: boolean | null = null,   
        public instructions: ProcedureStepInstructionV1Model[] | null = null,   
        public fields: ProcedureTaskStepFieldV1Model[] | null = null,   
      ) { }
}

export class ProcedureStepInstructionV1Model {
    constructor(  
        // Inherited properties 
        
      
        //Properties
        
        public procedureTaskStepInstructionId: number | null = null,   
        public instruction: string | null = null,   
        public procedureTaskStepId: number | null = null,   
        public sort: number | null = null,   
      ) { }
}

export class ProcedureTaskStepFieldV1Model {
    constructor(  
        // Inherited properties 
        
      
        //Properties
        
        public procedureTaskStepFieldId: number | null = null,   
        public fieldLabel: string | null = null,   
        public postFieldLabel: string | null = null,   
        public procedureTaskStepInstance: number | null = null,   
        public fieldType: number | null = null,   
        public createdByUserId: number | null = null,   
        public updatedByUserId: number | null = null,   
        public createdDate: Date | null = null,   
        public upatedDate: Date | null = null,   
        public rules: ProcedureRuleV1Model[] | null = null,   
        public workOrderFieldValues: WorkOrderFieldValueV1Model[] | null = null,   
      ) { }
}

export class ProcedureRuleV1Model {
    constructor(  
        // Inherited properties 
        
      
        //Properties
        
        public procedureTaskStepRuleId: number | null = null,   
        public procedureTaskStepId: number | null = null,   
        public ruleTypeId: RuleType | null = null,   
        public procedureTaskStepFieldId: number | null = null,   
        public name: string | null = null,   
        public successProcedureTaskStepId: number | null = null,   
        public successProcedureTaskStepName: string | null = null,   
        public failureProcedureTaskStepId: number | null = null,   
        public failureProcedureTaskStepName: string | null = null,   
        public createdByUserId: number | null = null,   
        public createdByUserName: string | null = null,   
        public updatedByUserId: number | null = null,   
        public updatedByUserName: string | null = null,   
        public createdDate: Date | null = null,   
        public updatedDate: Date | null = null,   
        public operand: OperandEnum | null = null,   
        public mathOperator: MathOperatorEnum | null = null,   
        public mathFieldId1: number | null = null,   
        public mathFieldId2: number | null = null,   
        public values: ProcedureRuleValueV1Model[] | null = null,   
      ) { }
}

export class ProcedureRuleValueV1Model {
    constructor(  
        // Inherited properties 
        
      
        //Properties
        
        public procedureTaskStepRuleValueId: number | null = null,   
        public procedureTaskStepRuleId: number | null = null,   
        public successProcedureTaskStepId: number | null = null,   
        public value: string | null = null,   
        public value2: string | null = null,   
        public createdByUserId: number | null = null,   
        public createdDate: Date | null = null,   
        public updatedByUserId: number | null = null,   
        public updatedDate: Date | null = null,   
      ) { }
}

export class WorkOrderFieldValueV1Model {
    constructor(  
        // Inherited properties 
        
      
        //Properties
        
        public workOrderFieldValueId: number | null = null,   
        public workOrderId: number | null = null,   
        public procedureTaskStepFieldId: number | null = null,   
        public procedureTaskStepInstance: number | null = null,   
        public value: string | null = null,   
        public createdByUserId: number | null = null,   
        public createdDate: Date | null = null,   
        public updatedByUserId: number | null = null,   
        public updatedDate: Date | null = null,   
        public createdByUserName: string | null = null,   
        public updatedByUserName: string | null = null,   
        public accountId: number | null = null,   
      ) { }
}

export class ScopingInstructionV1Model {
    constructor(  
        // Inherited properties 
        
      
        //Properties
        
        public scopeDocumentInstructionId: number | null = null,   
        public instruction: string | null = null,   
        public scopeDocumentId: number | null = null,   
        public createdDate: Date | null = null,   
        public updatedDate: Date | null = null,   
      ) { }
}

export class ProcedureStepItemCountV1Model {
    constructor(  
        // Inherited properties 
        
      
        //Properties
        
        public procedureTaskStepId: number | null = null,   
        public instructionCount: number | null = null,   
        public fieldCount: number | null = null,   
        public equipmentCount: number | null = null,   
        public figureCount: number | null = null,   
        public laborCount: number | null = null,   
        public precautionCount: number | null = null,   
        public partCount: number | null = null,   
        public permitCount: number | null = null,   
        public toolCount: number | null = null,   
        public videoCount: number | null = null,   
      ) { }
}

export class ScopingItemCountV1Model {
    constructor(  
        // Inherited properties 
        
      
        //Properties
        
        public scopeDocumentId: number | null = null,   
        public instructionCount: number | null = null,   
        public equipmentCount: number | null = null,   
        public figureCount: number | null = null,   
        public laborCount: number | null = null,   
        public precautionCount: number | null = null,   
        public partCount: number | null = null,   
        public permitCount: number | null = null,   
        public toolCount: number | null = null,   
        public videoCount: number | null = null,   
      ) { }
}

export class InsertResponseV1Model  {
    constructor(  
        // Inherited properties 
        
        public success: boolean | null = null,
        public message: string | null = null,
      
        //Properties
        
        public identityId: number | null = null,   
        public identityIds: number[] | null = null,   
      ) { }
}

export class ProcedureStepInsertV1Model {
    constructor(  
        // Inherited properties 
        
      
        //Properties
        
        public procedureTaskId: number | null = null,   
        public step: string | null = null,   
        public sequential: boolean | null = null,   
        public critical: boolean | null = null,   
        public prerequisiteStep: boolean | null = null,   
        public duration: number | null = null,   
        public stepType: StepType | null = null,   
        public holdId: number | null = null,   
        public repeatedCount: number | null = null,   
        public durationTolerance: number | null = null,   
        public customNextStepId: number | null = null,   
        public instructions: ProcedureStepInstructionInsertV1Model[] | null = null,   
        public endStep: boolean | null = null,   
      ) { }
}

export class ProcedureStepInstructionInsertV1Model {
    constructor(  
        // Inherited properties 
        
      
        //Properties
        
        public instruction: string | null = null,   
        public procedureTaskStepId: number | null = null,   
        public sort: number | null = null,   
      ) { }
}

export class ProcedureStepEquallyAcceptableAlternativeInsertV1Model  {
    constructor(  
        // Inherited properties 
        
        public procedureTaskId: number | null = null,
        public step: string | null = null,
        public sequential: boolean | null = null,
        public critical: boolean | null = null,
        public prerequisiteStep: boolean | null = null,
        public duration: number | null = null,
        public stepType: StepType | null = null,
        public holdId: number | null = null,
        public repeatedCount: number | null = null,
        public durationTolerance: number | null = null,
        public customNextStepId: number | null = null,
        public instructions: ProcedureStepInstructionInsertV1Model[] | null = null,
        public endStep: boolean | null = null,
      
        //Properties
        
        public successStep: number | null = null,   
        public failureStep: number | null = null,   
        public acceptableValues: string[] | null = null,   
      ) { }
}

export class ProcedureStepContinuousInsertV1Model  {
    constructor(  
        // Inherited properties 
        
        public procedureTaskId: number | null = null,
        public step: string | null = null,
        public sequential: boolean | null = null,
        public critical: boolean | null = null,
        public prerequisiteStep: boolean | null = null,
        public duration: number | null = null,
        public stepType: StepType | null = null,
        public holdId: number | null = null,
        public repeatedCount: number | null = null,
        public durationTolerance: number | null = null,
        public customNextStepId: number | null = null,
        public instructions: ProcedureStepInstructionInsertV1Model[] | null = null,
        public endStep: boolean | null = null,
      
        //Properties
        
        public fieldLabel: string | null = null,   
        public successProcedureTaskStepId: number | null = null,   
        public failureProcedureTaskStepId: number | null = null,   
        public range: ProcedureRuleRangeInsertV1Model | null = null,   
      ) { }
}

export class ProcedureRuleRangeInsertV1Model {
    constructor(  
        // Inherited properties 
        
      
        //Properties
        
        public startProcedureTaskStepId: number | null = null,   
        public endProcedureTaskStepId: number | null = null,   
      ) { }
}

export class ProcedureStepConcurrentInsertV1Model  {
    constructor(  
        // Inherited properties 
        
        public procedureTaskId: number | null = null,
        public step: string | null = null,
        public sequential: boolean | null = null,
        public critical: boolean | null = null,
        public prerequisiteStep: boolean | null = null,
        public duration: number | null = null,
        public stepType: StepType | null = null,
        public holdId: number | null = null,
        public repeatedCount: number | null = null,
        public durationTolerance: number | null = null,
        public customNextStepId: number | null = null,
        public instructions: ProcedureStepInstructionInsertV1Model[] | null = null,
        public endStep: boolean | null = null,
      
        //Properties
        
        public ranges: ProcedureRuleRangeInsertV1Model[] | null = null,   
      ) { }
}

export class ProcedureStepTimeDependentInsertV1Model  {
    constructor(  
        // Inherited properties 
        
        public procedureTaskId: number | null = null,
        public step: string | null = null,
        public sequential: boolean | null = null,
        public critical: boolean | null = null,
        public prerequisiteStep: boolean | null = null,
        public duration: number | null = null,
        public stepType: StepType | null = null,
        public holdId: number | null = null,
        public repeatedCount: number | null = null,
        public durationTolerance: number | null = null,
        public customNextStepId: number | null = null,
        public instructions: ProcedureStepInstructionInsertV1Model[] | null = null,
        public endStep: boolean | null = null,
      
        //Properties
        
        public time: number | null = null,   
        public range: ProcedureRuleRangeInsertV1Model | null = null,   
      ) { }
}

export class ProcedureStepRepeatedInsertV1Model  {
    constructor(  
        // Inherited properties 
        
        public procedureTaskId: number | null = null,
        public step: string | null = null,
        public sequential: boolean | null = null,
        public critical: boolean | null = null,
        public prerequisiteStep: boolean | null = null,
        public duration: number | null = null,
        public stepType: StepType | null = null,
        public holdId: number | null = null,
        public repeatedCount: number | null = null,
        public durationTolerance: number | null = null,
        public customNextStepId: number | null = null,
        public instructions: ProcedureStepInstructionInsertV1Model[] | null = null,
        public endStep: boolean | null = null,
      
        //Properties
        
        public range: ProcedureRuleRangeInsertV1Model | null = null,   
      ) { }
}

export class UpdateResponseV1Model {
    constructor(  
        // Inherited properties 
        
      
        //Properties
        
        public totalRowsUpdated: number | null = null,   
      ) { }
}

export class UpdateProcedureStepOrderV1Model {
    constructor(  
        // Inherited properties 
        
      
        //Properties
        
        public procedureRevisionId: number | null = null,   
        public procedureTabId: number | null = null,   
        public procedureTaskId: number | null = null,   
        public procedureTaskStepId: number | null = null,   
        public sort: number | null = null,   
      ) { }
}

export class ProcedureStepNextStepUpdateV1Model {
    constructor(  
        // Inherited properties 
        
      
        //Properties
        
        public procedureTaskStepId: number | null = null,   
        public customNextStepId: number | null = null,   
        public endStep: boolean | null = null,   
      ) { }
}

export class ProcedureStepUpdateV1Model {
    constructor(  
        // Inherited properties 
        
      
        //Properties
        
        public procedureTaskId: number | null = null,   
        public procedureTaskStepId: number | null = null,   
        public step: string | null = null,   
        public sequential: boolean | null = null,   
        public critical: boolean | null = null,   
        public stepType: StepType | null = null,   
        public duration: number | null = null,   
        public holdId: number | null = null,   
        public repeatedCount: number | null = null,   
        public durationTolerance: number | null = null,   
        public instructions: ProcedureStepInstructionInsertV1Model[] | null = null,   
      ) { }
}

export class CommonResponseV1Model {
    constructor(  
        // Inherited properties 
        
      
        //Properties
        
        public success: boolean | null = null,   
        public message: string | null = null,   
      ) { }
}

export class ProcedureStepInstructionSearchV1Model {
    constructor(  
        // Inherited properties 
        
      
        //Properties
        
        public scopeDocumentId: number | null = null,   
        public searchTerm: string | null = null,   
      ) { }
}

export class ScopingInstructionInsertV1Model {
    constructor(  
        // Inherited properties 
        
      
        //Properties
        
        public scopeDocumentId: number | null = null,   
        public instruction: string | null = null,   
      ) { }
}

export class ProceduresUpdateV1Model {
    constructor(  
        // Inherited properties 
        
      
        //Properties
        
        public procedureId: number | null = null,   
        public name: string | null = null,   
        public procedureNumber: string | null = null,   
        public scopeDocumentId: number | null = null,   
      ) { }
}

export class ProcedureStepInstructionUpdateV1Model {
    constructor(  
        // Inherited properties 
        
      
        //Properties
        
        public instruction: string | null = null,   
        public procedureTaskStepInstructionId: number | null = null,   
        public procedureTaskStepId: number | null = null,   
        public sort: number | null = null,   
      ) { }
}

export class ScopingInstructionUpdateV1Model {
    constructor(  
        // Inherited properties 
        
      
        //Properties
        
        public instruction: string | null = null,   
        public scopeDocumentInstructionId: number | null = null,   
        public scopeDocumentId: number | null = null,   
      ) { }
}

export class ProcedureTaskStepFieldInsertV1Model {
    constructor(  
        // Inherited properties 
        
      
        //Properties
        
        public procedureTaskStepId: number | null = null,   
        public fieldLabel: string | null = null,   
        public postFieldLabel: string | null = null,   
        public fieldType: FieldType | null = null,   
      ) { }
}

export class ProcedureTaskStepFieldCalculatedValueInsertV1Model {
    constructor(  
        // Inherited properties 
        
      
        //Properties
        
        public procedureTaskStepId: number | null = null,   
        public fieldLabel: string | null = null,   
        public postFieldLabel: string | null = null,   
        public successProcedureTaskStepId: number | null = null,   
        public failureProcedureTaskStepId: number | null = null,   
        public operand: OperandEnum | null = null,   
        public value: number | null = null,   
        public value2: number | null = null,   
        public mathOperator: MathOperatorEnum | null = null,   
        public mathFieldId1: number | null = null,   
        public mathFieldId2: number | null = null,   
      ) { }
}

export class ProcedureRuleCalculatedValueUpdateV1Model {
    constructor(  
        // Inherited properties 
        
      
        //Properties
        
        public procedureTaskStepRuleId: number | null = null,   
        public procedureTaskStepId: number | null = null,   
        public successProcedureTaskStepId: number | null = null,   
        public failureProcedureTaskStepId: number | null = null,   
        public operand: OperandEnum | null = null,   
        public value: number | null = null,   
        public value2: number | null = null,   
        public mathOperator: MathOperatorEnum | null = null,   
        public mathFieldId1: number | null = null,   
        public mathFieldId2: number | null = null,   
        public name: string | null = null,   
      ) { }
}

export class ProcedureTaskStepFieldUpdateV1Model {
    constructor(  
        // Inherited properties 
        
      
        //Properties
        
        public procedureTaskStepFieldId: number | null = null,   
        public fieldLabel: string | null = null,   
        public postFieldLabel: string | null = null,   
        public fieldType: FieldType | null = null,   
      ) { }
}

export class ProcedureRuleRangeV1Model {
    constructor(  
        // Inherited properties 
        
      
        //Properties
        
        public procedureTaskStepRuleRangeId: number | null = null,   
        public procedureTaskStepRuleId: number | null = null,   
        public startProcedureTaskStepId: number | null = null,   
        public endProcedureTaskStepId: number | null = null,   
        public createdByUserId: number | null = null,   
        public createdDate: Date | null = null,   
        public updatedByUserId: number | null = null,   
        public updatedDate: Date | null = null,   
      ) { }
}

export class ProcedureRuleSuccessFailureInsertV1Model {
    constructor(  
        // Inherited properties 
        
      
        //Properties
        
        public procedureTaskStepFieldId: number | null = null,   
        public ruleTypeId: RuleType | null = null,   
        public name: string | null = null,   
        public successProcedureTaskStepId: number | null = null,   
        public failureProcedureTaskStepId: number | null = null,   
        public operand: OperandEnum | null = null,   
        public value: string | null = null,   
        public value2: string | null = null,   
      ) { }
}

export class ProcedureRuleSuccessFailureUpdateV1Model {
    constructor(  
        // Inherited properties 
        
      
        //Properties
        
        public procedureTaskStepRuleId: number | null = null,   
        public procedureTaskStepFieldId: number | null = null,   
        public ruleTypeId: RuleType | null = null,   
        public name: string | null = null,   
        public successProcedureTaskStepId: number | null = null,   
        public failureProcedureTaskStepId: number | null = null,   
        public operand: OperandEnum | null = null,   
        public procedureTaskStepRuleValueId: number | null = null,   
        public value: string | null = null,   
        public value2: string | null = null,   
      ) { }
}

export class ProcedureRuleRequiredInsertV1Model {
    constructor(  
        // Inherited properties 
        
      
        //Properties
        
        public procedureTaskStepFieldId: number | null = null,   
        public ruleTypeId: RuleType | null = null,   
        public name: string | null = null,   
      ) { }
}

export class ProcedureRuleValidOptionsInsertV1Model {
    constructor(  
        // Inherited properties 
        
      
        //Properties
        
        public procedureTaskStepFieldId: number | null = null,   
        public ruleTypeId: RuleType | null = null,   
        public name: string | null = null,   
        public values: ProcedureRuleValidOptionWithStepInsertV1Model[] | null = null,   
      ) { }
}

export class ProcedureRuleValidOptionWithStepInsertV1Model {
    constructor(  
        // Inherited properties 
        
      
        //Properties
        
        public successProcedureTaskStepId: number | null = null,   
        public value: string | null = null,   
      ) { }
}

export class ProcedureRuleValidOptionsUpdateV1Model {
    constructor(  
        // Inherited properties 
        
      
        //Properties
        
        public procedureTaskStepRuleId: number | null = null,   
        public procedureTaskStepFieldId: number | null = null,   
        public ruleTypeId: RuleType | null = null,   
        public name: string | null = null,   
        public values: ProcedureRuleValueInsertV1Model[] | null = null,   
      ) { }
}

export class ProcedureRuleValueInsertV1Model {
    constructor(  
        // Inherited properties 
        
      
        //Properties
        
        public procedureTaskStepRuleId: number | null = null,   
        public successProcedureTaskStepId: number | null = null,   
        public value: string | null = null,   
        public value2: string | null = null,   
      ) { }
}

export class ProcedureRuleEquallyAcceptableAlternativeUpdateV1Model {
    constructor(  
        // Inherited properties 
        
      
        //Properties
        
        public procedureTaskStepRuleId: number | null = null,   
        public procedureTaskStepFieldId: number | null = null,   
        public ruleTypeId: RuleType | null = null,   
        public name: string | null = null,   
        public successProcedureTaskStepId: number | null = null,   
        public failureProcedureTaskStepId: number | null = null,   
        public acceptableValues: string[] | null = null,   
      ) { }
}

export class ProcedureRuleConcurrentUpdateV1Model {
    constructor(  
        // Inherited properties 
        
      
        //Properties
        
        public procedureTaskStepRuleId: number | null = null,   
        public name: string | null = null,   
        public ranges: ProcedureRuleRangeInsertV1Model[] | null = null,   
      ) { }
}

export class ProcedureRuleTimeDependentUpdateV1Model {
    constructor(  
        // Inherited properties 
        
      
        //Properties
        
        public procedureTaskStepRuleId: number | null = null,   
        public name: string | null = null,   
        public time: number | null = null,   
        public range: ProcedureRuleRangeInsertV1Model | null = null,   
      ) { }
}

export class ProcedureRuleRepeatedUpdateV1Model {
    constructor(  
        // Inherited properties 
        
      
        //Properties
        
        public procedureTaskStepRuleId: number | null = null,   
        public procedureTaskStepId: number | null = null,   
        public name: string | null = null,   
        public repeatedCount: number | null = null,   
        public range: ProcedureRuleRangeInsertV1Model | null = null,   
      ) { }
}

export class ProcedureRuleContinuousUpdateV1Model {
    constructor(  
        // Inherited properties 
        
      
        //Properties
        
        public procedureTaskStepRuleId: number | null = null,   
        public procedureTaskStepFieldId: number | null = null,   
        public ruleTypeId: RuleType | null = null,   
        public name: string | null = null,   
        public successProcedureTaskStepId: number | null = null,   
        public failureProcedureTaskStepId: number | null = null,   
        public range: ProcedureRuleRangeInsertV1Model | null = null,   
      ) { }
}

export class ProcedureRuleContinuousEndUpdateV1Model {
    constructor(  
        // Inherited properties 
        
      
        //Properties
        
        public procedureTaskStepRuleId: number | null = null,   
        public procedureTaskStepFieldId: number | null = null,   
        public name: string | null = null,   
        public successProcedureTaskStepId: number | null = null,   
        public failureProcedureTaskStepId: number | null = null,   
      ) { }
}



export enum StepType
    { 
       Concurrent = 1,
       Continuous = 2,
       EquallyAcceptableAlternative = 3,
       Repeated = 4,
       Standard = 5,
       TimeDependent = 6,
       Equipment = 7,
       LaborResources = 8,
       Parts = 9,
       Tools = 10,
       ContinuousEnd = 11,
    }

export enum RuleType
    { 
       Concurrent = 1,
       Continuous = 2,
       EquallyAcceptableAlternative = 3,
       Repeated = 4,
       Standard = 5,
       TimeDependent = 6,
       SuccessFailure = 7,
       Required = 8,
  //ValidOptions = 9,
       CalculatedValue = 10,
       ContinuousEnd = 11,
       ValidOptionsSuccess = 12,
    }

export enum OperandEnum
    { 
       Equal = 1,
       GreaterThan = 2,
       LessThan = 3,
       GreaterThanOrEqual = 4,
       LessThanOrEqual = 5,
       Between = 6,
    }

export enum MathOperatorEnum
    { 
       Plus = 1,
       Minus = 2,
       Multiply = 3,
       Divide = 4,
    }

export enum FieldType
    { 
       Concurrent = 1,
       Continuous = 2,
       EquallyAcceptableAlternative = 3,
       Repeated = 4,
       TimeDependent = 6,
       Input = 7,
  //Dropdown = 8,
  //Checkbox = 9,
       CalculatedValue = 10,
       ContinuousEnd = 11,
       OptionButtonGroup = 12,
    }



/* 
     Debug Info:
     PrintDebugInfo - put a dollar sign in front of Print to enable this 
*/

