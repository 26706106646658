import { Component, EventEmitter, Input, OnInit, Output, ViewChild } from "@angular/core";
import { FormBuilder, Validators } from "@angular/forms";
import { NgbActiveModal, NgbModal } from "@ng-bootstrap/ng-bootstrap";
import { NgxFileDropEntry, FileSystemFileEntry } from "ngx-file-drop";
import { DomSanitizer } from "@angular/platform-browser";
import { ToastrService } from "ngx-toastr";
import { FileUploadService } from "../../core/services/file-upload.service";
import { DocumentService, DocumentUploadV1Model, DocumentV1Model } from "src/app/api/Document.service";
import { DocumentTypeEnum } from "src/app/models/enums";
import { TagsInsertV1Model, TagsService, TagsV1Model } from "src/app/api/Tags.service";
import { MultiSelectComponent } from "@syncfusion/ej2-angular-dropdowns";
import { cloneDeep } from "lodash";
import { TagsGridComponent } from "../tags-grid/tags-grid.component";
import { AccountV1Model } from "src/app/api/Account.service";
import { environment } from "src/environments/environment";

@Component({
  selector: "app-file-upload-ngx2",
  templateUrl: "./file-upload-ngx2.component.html",
  styleUrls: ["./file-upload-ngx2.component.scss"],
})
export class FileUploadNGX2Component implements OnInit {
  @Input() allowMultipleUploads: boolean = false;
  @Input() hideForm: boolean = false;
  @Input() modalTitle: string = "Upload File";
  @Input() citations: string | null = null;
  @Input() documentType!: DocumentTypeEnum;
  @Input() stepInstance: number | null = null;
  @Input() file: DocumentV1Model = new DocumentV1Model();
  @Input() account: AccountV1Model = new AccountV1Model();
  @Input() isLibrary: boolean = true;
  @Input() workOrderId: number | null = null;
  @Input() procedureTaskStepId: number | null = null;
  @Output() public uploadingCompleteEvent = new EventEmitter<number>();
  apiRoot: string = environment.apiRoot;
  files: NgxFileDropEntry[] = [];
  public selectedTagId: number[] = [];
  //janky way of storing if a file is loading
  public fileLoading: string[] = [];
  pdf: boolean = false;
  isLoading: boolean = false;
  editMode: boolean = false;
  uploadForm = this.formBuilder.group({
    displayName: [""],
    isActive: ["true"],
    citations: [""],
  });
  //allowed file types - pdf and images
  allowedFileTypes: string = ".pdf, .jpg, .jpeg, .png";

  //Multiselect
  hideSelector: boolean = true;
  tagOptions: TagsV1Model[] = [];
  selectedTags: TagsV1Model[] = [];
  selectedTagIds: number[] = [];
  tagsPlaceholder = "Select tags from list...";
  @ViewChild("tagsDropdown")
  public accountDropdown!: MultiSelectComponent;
  public accountDropdownFields: Object = {
    text: "tagName",
    value: "tagId",
  };
  uploadFilesDisplayData: {
    previewUrl: any;
    isPdf: boolean;
  }[] = [];

  constructor(
    private toastService: ToastrService,
    private tagsService: TagsService,
    private fileUploadService: FileUploadService,
    private formBuilder: FormBuilder,
    private activeModal: NgbActiveModal,
    private sanitizer: DomSanitizer,
    private modalService: NgbModal,
    private documentService: DocumentService
  ) {}

  ngOnInit(): void {
    //Edit file upload
    if (this.file.documentGuid != null) {
      this.editMode = true;
      this.uploadForm.controls.displayName.setValue(this.file.displayName);
      this.uploadForm.controls.isActive.setValue(this.file.isActive!.toString());

      this.uploadForm.controls.citations.setValue(this.citations);

      if (this.file.fileName?.endsWith(".pdf")) {
        this.pdf = true;
      }

      this.loadDocumentTags();
    }
    //Account logo upload
    else if (this.account.logoGuid != null) {
      this.editMode = true;
      this.hideForm = true;
      this.file.documentGuid = this.account.logoGuid;
    }
    //New file
    else {
      this.loadAccountTags();
    }
  }

  loadAccountTags(): void {
    this.tagsService.getAccountTags().subscribe((data) => {
      this.tagOptions = data;
      this.hideSelector = false;
    });
  }

  loadDocumentTags(): void {
    this.tagsService.getDocumentTags(this.file.documentId!).subscribe((data) => {
      this.selectedTags = data;

      data.forEach((tag) => {
        this.selectedTagIds.push(tag.tagId!);
      });

      this.loadAccountTags();
    });
  }

  public dropped(files: NgxFileDropEntry[]): void {
    files.forEach((droppedFile, index) => {
      if (droppedFile.fileEntry.isFile) {
        const fileEntry = droppedFile.fileEntry as FileSystemFileEntry;
        fileEntry.file((file: File) => {
          const fileSizeMB = file.size / (1024 * 1024);

          if (fileSizeMB > 50) {
            this.toastService.error("File exceeds 50Mb limit");
            return;
          }

          //Check file type
          if (file.type == "application/pdf" || file.type.startsWith("image")) {
            this.files.push(droppedFile);
            this.fileOverBase(droppedFile, index);
          } else {
            this.toastService.error("Invalid file type. Allowed: " + this.allowedFileTypes);
          }
        });
      }
    });
  }

  //For file preview
  fileOverBase(e: any, index: number): void {
    if (e) {
      const fileEntry = e as NgxFileDropEntry;
      if (fileEntry.fileEntry.isFile) {
        const file = fileEntry.fileEntry as FileSystemFileEntry;
        file.file((fileObj: File) => {
          this.uploadFilesDisplayData.push({ previewUrl: null, isPdf: false });
          const reader = new FileReader();
          reader.onload = () => {
            this.uploadFilesDisplayData[index].previewUrl = this.sanitizer.bypassSecurityTrustUrl(reader.result as string);
          };

          if (this.uploadForm.controls.displayName.value == "") {
            this.uploadForm.controls.displayName.setValue(fileObj.name.split(".")[0]);
          }

          if (fileObj.type.toLowerCase() == "application/pdf") {
            this.uploadFilesDisplayData[index].isPdf = true;

            if (this.hideForm) {
              this.files = [];
              this.toastService.error("Invalid File type");
            }
          } else {
            this.uploadFilesDisplayData[index].isPdf = false;
            reader.readAsDataURL(fileObj);
          }
        });
      }
    }
  }

  onSubmit(): void {
    if (this.uploadForm.invalid) {
      this.toastService.error("Please enter file name");
      return;
    }

    //Handles updates
    else if (this.editMode == true) {
      let model = new DocumentUploadV1Model();

      model.displayName = this.uploadForm.controls.displayName.value;
      model.citations = this.uploadForm.controls.citations.value;
      model.documentType = this.documentType;
      model.isUpdate = true;
      model.documentId = this.file.documentId;
      model.documentTags = this.selectedTags;
      model.accountId = this.account.accountId;
      model.workOrderId = this.workOrderId;
      model.procedureTaskStepInstance = this.stepInstance;
      model.procedureTaskStepId = this.procedureTaskStepId;

      if (model.displayName == null || model.displayName == "") {
        model.displayName = "Logo";
      }
      if (this.uploadForm.controls["isActive"].value == "true") {
        model.isActive = true;
      } else {
        model.isActive = false;
      }

      this.documentService.updateDocument(model).subscribe((data) => {
        this.uploadingCompleteEvent.emit(data.totalRowsUpdated!);
        this.toastService.success(model.displayName + " updated");
        this.isLoading = false;
      });
    }

    //Inserts new file
    else {
      if (this.allowMultipleUploads == false && this.files.length > 1) {
        this.toastService.error("Only one file is allowed to be uploaded.");
        return;
      }
      this.isLoading = true;
      this.files.forEach((droppedFile, index) => {
        // Is it a file?
        if (droppedFile.fileEntry.isFile) {
          const fileEntry = droppedFile.fileEntry as FileSystemFileEntry;
          fileEntry.file((file: File) => {
            // Here you can access the real file
            this.fileLoading.push(file.name);

            this.fileUploadService.blobToBase64(file).then((fileBase64: string) => {
              let model: DocumentUploadV1Model = new DocumentUploadV1Model();
              model.fileBase64 = fileBase64;
              model.fileName = file.name;
              model.displayName =
                this.files.length > 1
                  ? this.uploadForm.controls.displayName.value + " " + (index + 1)
                  : this.uploadForm.controls.displayName.value;

              model.documentType = this.documentType;
              model.documentTags = this.selectedTags;
              model.accountId = this.account.accountId;
              model.citations = this.uploadForm.controls.citations.value;
              model.workOrderId = this.workOrderId;
              model.procedureTaskStepInstance = this.stepInstance;
              model.procedureTaskStepId = this.procedureTaskStepId;

              if (this.uploadForm.controls["isActive"].value == "true") {
                model.isActive = true;
              } else {
                model.isActive = false;
              }

              if (this.file.documentId != null) {
                model.isUpdate = true;
                model.documentId = this.file.documentId;
              } else {
                model.isUpdate = false;
              }

              this.documentService.insert(model).subscribe(
                (data) => {
                  // Success callback
                  this.fileLoading.pop();
                  if (this.fileLoading.length == 0) {
                    this.uploadingCompleteEvent.emit(data.identityId!);
                    this.toastService.success(model.displayName + " uploaded");
                    this.isLoading = false;
                  }
                },
                (error) => {
                  // Error callback
                  this.activeModal.close();
                }
              );
            });
          });
        } else {
          // It was a directory (empty directories are added, otherwise only files)
          const fileEntry = droppedFile.fileEntry as FileSystemDirectoryEntry;
        }
      });
    }
  }

  onDeleteClicked(index: number): void {
    this.files.splice(index, 1);
    this.uploadFilesDisplayData.splice(index, 1);

    if (this.files.length == 0) {
      this.editMode = false;

      if (this.file.documentId == null) {
        this.uploadForm.controls.displayName.setValue("");
      }
    }
  }

  //************ Tag stuff *******************

  onManageTagsClicked(): void {
    const modalRef = this.modalService.open(TagsGridComponent, {
      size: "xl",
    });
    modalRef.componentInstance.deleteCompletedEvent.subscribe(() => {
      this.selectedTagIds = [];
      this.selectedTags = [];
      this.loadAccountTags();
    });
    modalRef.closed.subscribe(() => {
      this.loadAccountTags();
    });
  }

  onSelectedTag(args: any): void {
    //Adding tag that exists
    if (this.tagOptions.length > 0) {
      if (args.itemData != null && args.itemData != undefined) {
        const selectedTag = new TagsV1Model();
        Object.assign(selectedTag, args.itemData);
        this.selectedTags.push(selectedTag);
      }
    }
    //Adding new one when no tags exist to prevent errors
    else {
      let newTag = new TagsInsertV1Model();
      newTag.tagName = args.itemData.tagName;
      this.tagsService.insert(newTag).subscribe((data) => {
        console.log("Tag created");
        const selectedTag = new TagsV1Model();
        selectedTag.tagName = newTag.tagName;
        selectedTag.tagId = data.identityId;
        this.selectedTags.push(selectedTag);
        this.loadAccountTags();
      });
    }
  }

  onRemoveTag(args: any): void {
    const tagToRemove = cloneDeep(args.itemData);
    this.selectedTags = this.selectedTags.filter((tag) => tag.tagId !== tagToRemove.tagId);
  }
}
