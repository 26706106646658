import { ProcedureStepMinV1Model } from "../../../api/ProcedureTaskSteps.service";
import { ProceduresV1Model } from "../../../api/Procedures.service";

export class SelectedOtherProcedureStepInfo {
  procedure: ProceduresV1Model;
  step: ProcedureStepMinV1Model;
  constructor(procedure: ProceduresV1Model, step: ProcedureStepMinV1Model) {
    this.procedure = procedure;
    this.step = step;
  }
}
