import { Component, EventEmitter, Input, Output } from "@angular/core";
import { NgbActiveModal } from "@ng-bootstrap/ng-bootstrap";
import { Observable, tap } from "rxjs";
import { WritingService } from "../../writing/writing.service";

@Component({
  selector: "app-modal",
  templateUrl: "./modal.component.html",
  styleUrls: ["./modal.component.scss"],
})
export class ModalComponent {
  @Input() modalTitle: string = "";
  @Input() headerCssClass: string = "";
  @Input() bodyCssClass: string = "";
  @Input() modalCssClass: string = "";
  @Input() closeText: string = "Close";
  @Input() submitText: string = "Submit";
  @Input() bodyText: string = "";
  @Input() closeButtonCss: string = "btn btn-secondary ms-auto me-2";
  @Input() submitButtonCss: string = "btn btn-success";
  @Input() submitDisabled: boolean = false;
  @Input() closeOnSubmit: boolean = true;
  @Input() closeOnCancel: boolean = true;
  @Input() showSubmitButton: boolean = true;
  @Input() showCloseButton: boolean = true;
  @Output() public onSubmitClickedEvent = new EventEmitter();
  @Output() public onCancelClickedEvent = new EventEmitter();
  isDraftChanged!: Observable<boolean>;
  constructor(public activeModal: NgbActiveModal,
    private writingService: WritingService,
    ) {}

  ngOnInit(): void {
    this.isDraftChanged = this.writingService.isDraft$.pipe(tap((value) => {}));
  }

  onCloseClicked(): void {
    this.onCancelClickedEvent.emit();
    this.activeModal.close();
  }

  onSubmitClicked(): void {
    this.onSubmitClickedEvent.emit();
    if (this.closeOnSubmit) {
      this.activeModal.close();
    }
  }
}
