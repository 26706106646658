import { Injectable, Injector } from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";
import * as moment from "moment";
import { BehaviorSubject, Subject } from "rxjs";
import { environment } from "../../../../environments/environment";
import { AuthParam, AuthSecContext, TokenService, UserSecV1Model } from "../../../api/Token.service";
import { UserSettingV1Model, UserService } from "../../../api/User.service";
import { permissionType } from "../../../models/enums";
import { ProgressSaveBypassService } from "./progress-save-bypass.service";
import { ExecutionService } from "src/app/api/Execution.service";

@Injectable({
  providedIn: "root",
})
export class AuthService {
  //private userManager: UserManager; // From oidc-client - provides methods to handle token and auth
  public user: UserSecV1Model | null = null;
  private authContext: AuthSecContext | null = null; // Stores the user context data (permissions)
  userSettingList: UserSettingV1Model[] = [];
  private login_path: string = "/login";
  private home: string = "/home";
  homeObservable: Subject<string> = new Subject<string>();
  isLoggedIn$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  orgLogo$: Subject<string> = new Subject<string>();
  private section: Subject<string> = new Subject<string>();
  redirectUrl: string | null = null;

  //public ContextSubject = new BehaviorSubject<ContextV1Model>(this.Context); // Stores the current menu items based on the xml and current AspContext
  //public ContextSubject$ = this.ContextSubject.asObservable(); // Observable of the menu items
  private initialLoadComplete = false;

  constructor(
    private tokenService: TokenService,
    private userService: UserService,
    private injector: Injector,
    private route: ActivatedRoute,
    private progressSaveBypassService: ProgressSaveBypassService,
    private executionService: ExecutionService
  ) {
    //console.log("AuthService constructor starting");
    //LOCALSTORAGE ACCEPTABLE DUE DO SERVERSIDE
  }

  public get router(): Router {
    return this.injector.get(Router);
  }

  // This method is run as a part of application startup.  The bootstrapping process will not continue until it completes.
  initializeApp(): Promise<any> {
    return new Promise((resolve, reject) => {
      try {
        //throw new RangeError();
        //console.log("app init - getting user");
        let token = localStorage.getItem("tokenRW"); // Check if token exists in local storage
        if (token && !this.isLoggedIn()) {
          //token found
          //console.log('app init - checking for token');
          this.tokenService.refreshToken().subscribe((result) => {
            //console.log('app init - token success');
            if (result) {
              //console.log('result: ' + result);
              //console.log(result);
              if (result.isAuthenticated) {
                //console.log('app init - isAuthenticated');
                if (result?.context?.user) {
                  this.user = result.context.user;
                  this.authContext = result;
                  this.setHome(this.user.currentAccountCode + "/home");
                  this.initTokenCheck();
                  this.userService.getUserSettings().subscribe((result) => {
                    this.userSettingList = result;
                    resolve(true);
                  });
                  this.isLoggedIn$.next(true);
                }
              }
            }
            if (!this.isLoggedIn()) {
              //console.log("app init - redirect to login");
              this.autoRedirectToLogin();
              resolve(true);
            }
          });
        } else if (!this.isLoggedIn()) {
          //console.log("app init - redirect to login");
          if (window.location.href.indexOf("QRCode") > 0) {
            this.redirectUrl = window.location.href;
          }

          this.autoRedirectToLogin();
          resolve(true);
        }
      } catch (e) {
        //console.error("Startup Error: " + e);
        this.showAppBootstrappingError("General error starting the application.");
        resolve(true);
      }
    });
  }

  getCurrentGuid(): string {
    let url = window.location.href;
    let urlSplit = url.split("/");
    let guid = urlSplit[3];
    return guid;
  }

  //private getContext(userId: number, siteFromAspString: string): Promise<any> {
  //  return new Promise((resolve, reject) => {
  //    this.presentationService.getUserContext(userId, siteFromAspString).subscribe(context => {

  //      this.authContext = context; // Save context

  //      // Save the current site to local storage to allow it to persist
  //      if (context.currentSite != null) {
  //        localStorage.setItem('currentSiteCode', context.currentSite.code);

  //      resolve();
  //    });
  //  });
  //}

  refresh() {
    this.tokenService.refreshToken().subscribe((result) => {
      if (result) {
        if (result.isAuthenticated) {
          if (result?.context?.user) {
            this.user = result.context.user;
            this.authContext = result;
            this.isLoggedIn$.next(true);
            //console.log(result);
          }
        } else {
          this.logout();
        }
      }
    });
  }

  /**
   * Checks in every set frequency to renew token
   * @param url
   * @param {number} frequency - in milliseconds, defaults to 5 minutes
   */

  public initTokenCheck(frequency = 1000 * 60 * 5) {
    setInterval(() => {
      if (this.isLoggedIn()) {
        let next_check: Date = moment(new Date())
          .add(frequency / 1000, "s")
          .toDate();
        if (this.authContext?.expires != null) {
          if (new Date(this.authContext.expires) < next_check) {
            this.renew();
          } else {
            //console.log('not renewing yet ' + new Date());
          }
        }
      }
    }, frequency);
  }

  //impersonateUser(userId: number): Promise<any> {
  //  return new Promise((resolve, reject) => {
  //    this.userService.impersonateUser(userId).subscribe(result => {
  //      this.user = result.context.user;
  //      this.authContext = result;
  //      localStorage.removeItem('token');
  //      localStorage.setItem('token', this.authContext.token);
  //      this.isLoggedIn$.next(true);
  //      resolve();
  //    }, error => { reject(); });
  //  });
  //}

  //unImpersonateUser(): Promise<any> {
  //  return new Promise((resolve, reject) => {
  //    this.userService.unImpersonateUser().subscribe(result => {
  //      this.user = result.context.user;
  //      this.authContext = result;
  //      localStorage.removeItem('token');
  //      localStorage.setItem('token', this.authContext.token);
  //      this.isLoggedIn$.next(true);
  //      resolve();
  //    }, error => { reject(); });
  //  });
  //}

  getCurrentContext(): AuthSecContext | null {
    return this.authContext;
  }

  login(username: string, password: string): Promise<any> {
    return new Promise((resolve, reject) => {
      var param = new AuthParam();
      param.clientid = environment.clientId;
      param.clientsecret = environment.clientSecret;
      param.username = username;
      param.password = password;
      this.tokenService.processToken(param).subscribe(
        (result) => {
          this.setUser(result);
          resolve(true);
        },
        (error) => {
          reject(error);
        }
      );
    });
  }

  setUser(result: AuthSecContext) {
    if (result?.context?.user != null && result.isAuthenticated) {
      this.user = result.context.user;
      this.authContext = result;
      this.setHome(this.user.currentAccountCode + "/home");
      localStorage.setItem("tokenRW", this.authContext?.token ?? "");
      this.isLoggedIn$.next(true);
      this.initTokenCheck();
    }
  }

  logout() {
    console.log("Log out");
    //Closes open logs when signing out in execution
    if (window.location.href.includes("execution/procedure")) {
      this.executionService.closeOpenLogsForUser().subscribe((data) => {
        this.expireToken();
      });
    } else {
      this.expireToken();
    }
  }

  expireToken(): void {
    this.tokenService.expireToken().subscribe((data) => {
      this.progressSaveBypassService.setBypass(true); //Override progress save
      this.user = null;
      this.authContext = null;
      localStorage.removeItem("tokenRW");
      this.isLoggedIn$.next(false);
      this.redirectToLogin();
    });
  }

  autoRedirectToLogin() {
    this.redirectToLogin();
  }

  redirectToLogin() {
    this.router.navigateByUrl(this.login_path);
  }

  isLoggedIn(): boolean {
    if (this.user == null) {
      return false;
    } else if (this.authContext == null) {
      return false;
    } else {
      return this.user != null && this.authContext!.token!.length > 0 && !this.authContext!.isExpired;
    }
  }

  getAccessToken(): string {
    if (this.authContext?.token) {
      return this.authContext.token;
    }
    const token = localStorage.getItem("tokenRW");
    return token != null ? token : "";
  }

  renew(): any {
    this.tokenService.renewToken().subscribe((result) => {
      if (result != null) {
        this.authContext = result.context;
        localStorage.setItem("tokenRW", this.authContext?.token ?? "");
        //console.log(result.context.expires);
      } else {
        this.logout();
      }
    });
  }

  getUserId(): number | null {
    if (this.user != null) {
      return this.user.currentUserId;
    } else {
      return null;
    }
  }

  getUserDisplayName(): string | null {
    if (this.user != null) {
      return this.user.name;
    } else {
      return "";
    }
  }

  getAuthContext(): AuthSecContext | null {
    return this.authContext;
  }

  hasPermission(perm: permissionType) {
    if (this.authContext) {
      if (
        this.authContext.context?.user?.permissions &&
        this.authContext.context.user.permissions.find((permission) => {
          return permission.apiPermissionId === perm;
        })
      ) {
        return true;
      }
    } else {
      //console.log("hasPermission - user has no context");
    }
    //console.log("AuthService.hasPermission - User does not have permission: " + perm);
    return false;
  }

  hasAnyOfPermissions(permissionsToCheckFor: permissionType[]) {
    if (this.authContext) {
      if (this.authContext.context?.user?.permissions) {
        for (let permissionToCheckFor of permissionsToCheckFor) {
          if (this.authContext.context.user.permissions.findIndex((x) => x.apiPermissionId == permissionToCheckFor) >= 0) {
            return true;
          }
        }
      }
    }

    return false;
  }

  private showAppBootstrappingError(errorMessage: string) {
    let messageDiv = document.getElementById("loading-error-message") as HTMLElement;
    messageDiv.style.display = "table";
    messageDiv.innerHTML = "<span>" + errorMessage + "</span>";

    let loadingGraphic = document.getElementById("loading-box-area") as HTMLElement;
    loadingGraphic.innerHTML = "";
  }

  public setHome(home: string, login: string | undefined = undefined) {
    this.homeObservable.next(home);
    this.home = home;
    if (login) this.login_path = login;
  }

  public getHome() {
    return this.home;
  }

  public setSectionTitle(title: string) {
    this.section.next(title);
  }

  public getSectionTitle(): Subject<string> {
    return this.section;
  }
}
