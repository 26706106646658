// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.lightbox {
  z-index: 1;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.8);
  color: white;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}

#videoPlayer {
  width: 100%;
}

/* Increase the size of the control buttons */
#videoPlayer::-webkit-media-controls {
  width: 10em;
  height: 10em;
}

#videoPlayer::-webkit-media-controls-play-button,
#videoPlayer::-webkit-media-controls-pause-button {
  width: 10em;
  height: 10em;
}

#videoPlayer::-webkit-media-controls-volume-slider {
  width: 3em;
  height: 3em;
}`, "",{"version":3,"sources":["webpack://./src/app/modules/shared/video-player/video-player.component.scss"],"names":[],"mappings":"AAAA;EACE,UAAA;EACA,MAAA;EACA,OAAA;EACA,WAAA;EACA,YAAA;EACA,oCAAA;EACA,YAAA;EACA,aAAA;EACA,mBAAA;EACA,uBAAA;EACA,eAAA;AACF;;AAEA;EACE,WAAA;AACF;;AAEA,6CAAA;AACA;EACE,WAAA;EACA,YAAA;AACF;;AAEA;;EAEE,WAAA;EACA,YAAA;AACF;;AAEA;EACE,UAAA;EACA,WAAA;AACF","sourcesContent":[".lightbox {\n  z-index: 1;\n  top: 0;\n  left: 0;\n  width: 100%;\n  height: 100%;\n  background-color: rgba(0, 0, 0, 0.8);\n  color: white;\n  display: flex;\n  align-items: center;\n  justify-content: center;\n  cursor: pointer;\n}\n\n#videoPlayer {\n  width: 100%;\n}\n\n/* Increase the size of the control buttons */\n#videoPlayer::-webkit-media-controls {\n  width: 10em;\n  height: 10em;\n}\n\n#videoPlayer::-webkit-media-controls-play-button,\n#videoPlayer::-webkit-media-controls-pause-button {\n  width: 10em;\n  height: 10em;\n}\n\n#videoPlayer::-webkit-media-controls-volume-slider {\n  width: 3em;\n  height: 3em;\n}\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
