import { Component, OnInit, TemplateRef, ViewChild } from "@angular/core";
import { EventType, Router } from "@angular/router";
import { AuthService } from "../../core/services/auth.service";
import { filter, interval } from "rxjs";
import { permissionType } from "src/app/models/enums";
import { UserIdleService } from "wspowerpack";
import { NgbModal, NgbModalRef } from "@ng-bootstrap/ng-bootstrap";
import { AccountLicenseV1Model, AccountService, AccountV1Model } from "src/app/api/Account.service";
import { environment } from "src/environments/environment";

@Component({
  selector: "app-navbar",
  templateUrl: "./navbar.component.html",
  styleUrls: ["./navbar.component.scss"],
})
export class NavbarComponent implements OnInit {
  @ViewChild("timeout", { static: true }) timeoutDialog!: TemplateRef<any>;
  title = "Material Certification";
  showMobileMenu: boolean = false;
  dialogRef: NgbModalRef | null = null;
  count: number = 30;
  allowUsers: boolean = false;
  isAdmin: boolean = false;
  hasModify: boolean = false;
  isLoggedIn: boolean = false;
  welcome: string | null = null;
  isReady: boolean = false; //this is just to hide features we are not ready for and should be removed eventually
  showWriting: boolean = false;
  showExecution: boolean = false;
  showScoping: boolean = false;
  showAdministration: boolean = false;
  showScheduling: boolean = false;
  showReporting: boolean = false;
  showAnalytics: boolean = false;
  showLibraries: boolean = false;
  showAdministrationUser: boolean = false;
  showAdministrationAccount: boolean = false;
  currentAccount: AccountV1Model = new AccountV1Model();
  isLoading: boolean = true;
  apiRoot: string = environment.apiRoot;
  accountLicenses: AccountLicenseV1Model = new AccountLicenseV1Model();
  guid: string | null | undefined = "act";

  constructor(
    private userIdle: UserIdleService,
    private router: Router,
    private authService: AuthService,
    private modalService: NgbModal,
    private accountService: AccountService
  ) {
    this.guid = this.authService.getCurrentGuid();

    this.isAdmin = this.authService.hasPermission(permissionType.CreateAccounts);
  }

  ngOnInit(): void {
    this.isLoggedIn = this.authService.isLoggedIn();
    this.welcome = this.authService.getUserDisplayName();

    this.authService.isLoggedIn$.subscribe((l) => {
      this.welcome = this.authService.getUserDisplayName();
      if (l) {
        this.setIdle();
        this.setNavbarPermissions();
      } else {
        this.stop();
        this.stopWatching();
      }
    });

    this.authService.renew(); //renew on first refresh and then every 10 minutes

    interval(600000).subscribe(() => {
      console.log("Renew");
      this.authService.renew();
    });
  }

  setNavbarPermissions(): void {
    this.loadCurrentAccount();

    this.accountService.getAccountLicensesById(this.authService.user?.currentAccountId! || 0).subscribe((data) => {
      this.accountLicenses = data;

      this.showWriting =
        this.authService.hasAnyOfPermissions([permissionType.ManageProcedures, permissionType.CreateAccounts]) &&
        this.accountLicenses.licensesWriting! > 0;

      this.showExecution =
        this.authService.hasAnyOfPermissions([permissionType.ExecuteProcedures, permissionType.CreateAccounts]) &&
        this.accountLicenses.licensesExecution! > 0;

      this.showScoping =
        this.authService.hasAnyOfPermissions([permissionType.ManageProcedures, permissionType.CreateAccounts]) &&
        this.accountLicenses.licensesScoping! > 0;

      this.showAdministration = this.authService.hasAnyOfPermissions([
        permissionType.CreateAccounts,
        permissionType.ManageAccounts,
        permissionType.ManageUsers,
        permissionType.ManageDelayCodes,
        permissionType.ManageLaborResources,
        permissionType.ManageParts,
        permissionType.ManageTools,
        permissionType.ManageHazards,
        permissionType.ManageFigures,
        permissionType.ManageEquipment,
        permissionType.ManageHazards,
      ]);

      this.showAdministrationUser = this.authService.hasAnyOfPermissions([
        permissionType.CreateAccounts,
        permissionType.ManageUsers,
      ]);

      this.showAdministrationAccount = this.authService.hasAnyOfPermissions([
        permissionType.CreateAccounts,
        permissionType.ManageAccounts,
      ]);

      this.showAnalytics = this.authService.hasAnyOfPermissions([permissionType.CreateAccounts]);
      //&& this.accountLicenses.licensesAnalytics! > 0;
      // permissionType.ExecuteProcedures,
      // permissionType.Analytics, HIDING ANALYTICS FOR NOW

      this.showReporting =
        this.authService.hasAnyOfPermissions([
          permissionType.CreateAccounts,
          permissionType.ExecuteProcedures,
          permissionType.ManageProcedures,
        ]) && this.accountLicenses.licensesAnalytics! > 0;

      this.showScheduling =
        this.authService.hasAnyOfPermissions([permissionType.CreateAccounts, permissionType.ManageProcedures]) &&
        this.accountLicenses.licensesScheduling! > 0;

      this.showLibraries = this.authService.hasAnyOfPermissions([
        permissionType.CreateAccounts,
        permissionType.ManageParts,
        permissionType.ManageTools,
        permissionType.ManageDelayCodes,
        permissionType.ManageLaborResources,
        permissionType.ManageHazards,
        permissionType.ManageFigures,
        permissionType.ManageEquipment,
        permissionType.ManageHazards,
      ]);
    });
  }

  loadCurrentAccount(): void {
    if (this.authService.user?.currentAccountId != this.currentAccount.accountId) {
      this.accountService.getById(this.authService.user?.currentAccountId || 0).subscribe((data) => {
        this.currentAccount = data;
        this.guid = data.accountCode;
        this.isLoading = false;
      });
    }
  }

  // helper function to determine if the active class should be added to the administration dropdown link
  addActiveClassToAdminDropdown(): boolean {
    let url = this.router.url;
    //if it has administration in it return true
    if (url.indexOf("admin") != -1) {
      return true;
    } else {
      //otherwise return false to not add active class
      return false;
    }
  }

  addActiveClassToLibraryDropdown(): boolean {
    let url = this.router.url;
    //if it has library in it return true
    if (url.indexOf("library/procedures") != -1) {
      return false;
    } else if (url.indexOf("library") != -1) {
      return true;
    } else {
      //otherwise return false to not add active class
      return false;
    }
  }

  onSignout(): void {
    this.authService.logout();
  }

  onShowMobileMenu(): void {
    this.showMobileMenu = !this.showMobileMenu;
  }

  //*********** IDLE TIMEOUT //***********
  setIdle(): any {
    //console.log("Setup Idle");
    //Start watching for user inactivity.
    this.userIdle.startWatching();
    this.userIdle.resetTimer();
    //Start watching when user idle is starting.
    this.userIdle.onTimerStart().subscribe((count) => {
      this.count = 30 - count;
      if (this.dialogRef == null && count != null && count < 30) {
        this.dialogRef = this.modalService.open(this.timeoutDialog, {
          backdrop: "static",
        });
      }
      if (count == 30) {
        if (this.dialogRef != null) this.dialogRef.close("");
      }
    });
    // dialogRef.afterClosed().subscribe(result => {
    //  this.setupLogDS();
    // });

    //Start watch when time is up.
    this.userIdle.onTimeout().subscribe(() => {
      console.log("Time is up!");
      if (this.dialogRef != null) this.dialogRef.close("");
      this.authService.logout();
      this.stop();
      this.stopWatching();
      this.dialogRef = null;
    });

    //Ping
    this.userIdle.ping$.subscribe(() => {
      if (this.authService.isLoggedIn()) {
        this.authService.renew();
      }
    });
  }

  stop() {
    this.userIdle.stopTimer();
  }

  stopWatching() {
    this.userIdle.stopWatching();
  }

  startWatching() {
    this.userIdle.startWatching();
  }

  restart() {
    this.userIdle.resetTimer();
  }

  onContinue() {
    this.dialogRef?.close("");
    this.restart();
    this.startWatching();
    this.setIdle();
    this.dialogRef = null;
  }

  onManualLogout() {
    this.dialogRef?.close();
    this.authService.logout();
    this.stop();
    this.stopWatching();
    this.dialogRef = null;
  }
}
