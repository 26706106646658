import { Injectable } from "@angular/core";
import { Subject } from "rxjs";

@Injectable({
  providedIn: "root",
})
export class ProgressSaveBypassService {
  private bypassSubject = new Subject<boolean>();

  bypassGuard$ = this.bypassSubject.asObservable();

  // Set bypass to false by default
  private bypass = true;

  setBypass(bypass: boolean) {
    this.bypass = bypass;
    this.bypassSubject.next(this.bypass);
  }

  getBypass(): boolean {
    return this.bypass;
  }
}
