import { Injectable } from '@angular/core';
import { ToastService } from './toast.service';
import { FormGroup } from '@angular/forms';
import { UserService, UserSettingV1Model } from '../../../api/User.service';
import { AuthService } from './auth.service';
import { Observable, of, Observer } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class UserSettingsService {
  //userSettingList: UserSettingV1Model[] = [];
  constructor(
    private toastService: ToastService,
    private userService: UserService,
    private autheService: AuthService
  ) {}

  // Update user setting value
  updateUserSetting(setting: UserSettingV1Model) {
    let settingList: UserSettingV1Model[] = [];
    settingList.push(setting);

    this.userService.updateUserSettings(settingList).subscribe((data) => {
      this.toastService.success('User settings saved!');
    });
  }

  // Update user setting values
  updateUserSettings(settingsList: UserSettingV1Model[]) {
    this.userService.updateUserSettings(settingsList).subscribe((data) => {
      this.toastService.success('User settings saved!');
      this.getUserSettings();
    });
  }

  // Gets the user specified default settings
  getUserSettings() {
    this.userService.getUserSettings().subscribe((result) => {
      this.autheService.userSettingList = result;
    });
  }

  // Gets the user specified default form values and applies it to the form controls
  getdefaultFormValuesFromUserSettings(
    form: FormGroup,
    userSettingBase: string
  ) {
    for (const field of Object.keys(form.controls)) {
      // 'field' is a string
      this.getUserSetting(userSettingBase + '.' + field).subscribe(
        (fieldSetting) => {
          if (fieldSetting.settingValue != null) {
            //const control = this.userForm.get(field); // 'control' is a FormControl

            if (fieldSetting.settingValue == 'true') {
              form.patchValue({ [field]: true });
            } else if (fieldSetting.settingValue == 'false') {
              form.patchValue({ [field]: false });
            } else {
              form.patchValue({ [field]: fieldSetting.settingValue });
            }
          }
        }
      );
    }
  }

  // Saves user defaults for the form values in the group to the specified setting prefix
  saveFormFieldUserDefaults(form: FormGroup, userSettingBase: string) {
    // Build setting list from the formGroup
    let settingList: UserSettingV1Model[] = [];
    for (const field of Object.keys(form.controls)) {
      // 'field' is a string
      this.getUserSetting(userSettingBase + '.' + field).subscribe(
        (fieldSetting) => {
          var formFieldValue = form.get(field)?.value;
          fieldSetting.settingValue = formFieldValue;
          settingList.push(fieldSetting);
        }
      );
    }

    // Save the settings
    this.userService.updateUserSettings(settingList).subscribe((data) => {
      this.toastService.success('User settings saved!');
    });
  }

  getUserSetting(settingKey: string): Observable<UserSettingV1Model> {
    return Observable.create((observer: Observer<UserSettingV1Model>) => {
      if (this.autheService.userSettingList.length == 0) {
        this.userService.getUserSettings().subscribe((result) => {
          this.autheService.userSettingList = result;
          let userSetting = this.findUserSetting(settingKey);
          if (userSetting !== null && userSetting !== undefined) {
            observer.next(userSetting);
          }
        });
      } else {
        let userSetting = this.findUserSetting(settingKey);
        if (userSetting !== null && userSetting !== undefined) {
          observer.next(userSetting);
        }
      }
      observer.complete();
    });
  }

  findUserSetting(settingKey: any): UserSettingV1Model | undefined {
    if (
      this.autheService.userSettingList.find(
        (i) => i.settingKey === settingKey
      ) != null
    ) {
      return this.autheService.userSettingList.find(
        (i) => i.settingKey === settingKey
      );
    } else {
      let setting = new UserSettingV1Model();
      setting.userId = this.autheService.getUserId();
      setting.settingKey = settingKey;
      return setting;
    }
  }

  // Update user setting value
  updateUserSetting_Silent(setting: UserSettingV1Model) {
    let settingList: UserSettingV1Model[] = [];
    settingList.push(setting);

    this.userService.updateUserSettings(settingList).subscribe((data) => {
      this.getUserSettings();
    });
  }
}
