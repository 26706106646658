import { Component, EventEmitter, Input, Output } from "@angular/core";
import { NgbActiveModal } from "@ng-bootstrap/ng-bootstrap";
import { ToastrService } from "ngx-toastr";
import { ToolsInsertV1Model, ToolsService } from "src/app/api/Tools.service";
import { AuthService } from "src/app/modules/core/services/auth.service";
import { FormRepoService } from "src/app/modules/shared/services/form-repo.service";

@Component({
  selector: "app-new-tool-modal",
  templateUrl: "./new-tool-modal.component.html",
  styleUrls: ["./new-tool-modal.component.scss"],
})
export class NewToolModalComponent {
  @Output() public submitCompletedEvent = new EventEmitter();
  newToolForm = this.formRepoService.newToolForm();
  @Input() isLibrary: boolean = true;

  constructor(
    public activeModal: NgbActiveModal,
    private toastService: ToastrService,
    private formRepoService: FormRepoService,
    private toolsService: ToolsService
  ) {}

  ngOnInit(): void {}

  onSubmit(): void {
    let model = new ToolsInsertV1Model();

    model.name = this.newToolForm.controls["name"].value;
    model.shortName = this.newToolForm.controls["shortName"].value;
    model.toolNumber = this.newToolForm.controls["toolNumber"].value;
    if (this.newToolForm.controls["isActive"].value == "true") {
      model.isActive = true;
    } else {
      model.isActive = false;
    }

    this.toolsService.insert(model).subscribe((data) => {
      this.toastService.success(model.name + " inserted");
      this.submitCompletedEvent.emit(data.identityId);
      this.activeModal.close();
    },
    (error) => {
      
    });
  }

  onCancel(): void {
    this.activeModal.close();
  }
}
