// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.hideText::ng-deep svg text {
  display: none !important;
}`, "",{"version":3,"sources":["webpack://./src/app/modules/shared/qr-code-view-modal/qr-code-view-modal.component.scss"],"names":[],"mappings":"AAEA;EACI,wBAAA;AADJ","sourcesContent":["\n//Hide Text from qrCodes\n.hideText::ng-deep svg text {\n    display: none !important;\n  }"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
