import { Component, EventEmitter, Input, Output } from "@angular/core";
import { NgbActiveModal } from "@ng-bootstrap/ng-bootstrap";
import { DocumentTypeEnum } from "src/app/models/enums";

@Component({
  selector: "app-new-parts-modal",
  templateUrl: "./new-permit-modal.component.html",
  styleUrls: ["./new-permit-modal.component.scss"],
})
export class NewPermitModalComponent {
  @Output() public submitCompletedEvent = new EventEmitter();
  documentType: DocumentTypeEnum = DocumentTypeEnum.Permits;
  constructor(public activeModal: NgbActiveModal) {}

  ngOnInit(): void {}

  submitCompleted(identityId: number): void {
    this.submitCompletedEvent.emit(identityId);
    this.activeModal.close();
  }
}
