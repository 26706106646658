import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { NotFoundComponent } from "./not-found/not-found.component";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { UnauthorizedComponent } from "./unauthorized/unauthorized.component";
import { NavbarComponent } from "./navbar/navbar.component";
import { RouterModule } from "@angular/router";
import { environment } from "src/environments/environment";
import {
  LoadingIndicatorComponent,
  LoadingIndicatorModule,
  WSSyncFusionGridConfig,
  WSSyncFusionGridModule,
} from "wspowerpack";
import { ModalComponent } from "./modal/modal.component";
import { AlertModalComponent } from "./alert-modal/alert-modal.component";
import { PageHeaderComponent } from "./page-header/page-header.component";
import { SidebarComponent } from "./sidebar/sidebar.component";
import { FormLabelComponent } from "./form-label/form-label.component";
import { RedAsteriskComponent } from "./red-asterisk/red-asterisk.component";
import { ButtonComponent } from "./button/button.component";
import { AlertComponent } from "./alert/alert.component";
import { NgxFileDropModule } from "ngx-file-drop";
import { FileUploadNGX2Component } from "./file-upload-ngx2/file-upload-ngx2.component";
import { NgbModule } from "@ng-bootstrap/ng-bootstrap";
import { ComboBoxModule, DropDownListModule, ListBoxAllModule, MultiSelectModule } from "@syncfusion/ej2-angular-dropdowns";
import { ImageLightboxComponent } from "./image-lightbox/image-lightbox.component";
import { ImageThumbnailComponent } from "./image-thumbnail/image-thumbnail.component";
import { ExcelImportModalComponent } from "./excel-import-modal/excel-import-modal.component";
import { UploaderModule } from "@syncfusion/ej2-angular-inputs";
import { NgxPaginationModule } from "ngx-pagination";
import { TagsGridComponent } from "./tags-grid/tags-grid.component";
import { NewTagModalComponent } from "./tags-grid/new-tag-modal/new-tag-modal.component";
import { EditTagModalComponent } from "./tags-grid/edit-tag-modal/edit-tag-modal.component";
import { TreeViewModule } from "@syncfusion/ej2-angular-navigations";
import { RichTextEditorModule } from "@syncfusion/ej2-angular-richtexteditor";
import { EditPrecautionModalComponent } from "./edit-precaution-modal/edit-precaution-modal.component";
import { NewPrecautionModalComponent } from "./new-precaution-modal/new-precaution-modal.component";
import { EditProcedureModalComponent } from "./edit-procedure-modal/edit-procedure-modal.component";
import { NewProcedureModalComponent } from "./new-procedure-modal/new-procedure-modal.component";
import { RuleTypeDisplayPipe } from "./pipes/rule-type-display.pipe";
import { StepTypeDisplayPipe } from "./pipes/step-type-display.pipe";
import { VideoUploadNGX2Component } from "./video-upload-ngx2/video-upload-ngx2.component";
import { NewHarzardModalComponent } from "./new-harzard-modal/new-harzard-modal.component";
import { NewEquipmentModalComponent } from "./new-equipment-modal/new-equipment-modal.component";
import { NewFigureModalComponent } from "./new-figure-modal/new-figure-modal.component";
import { NewLaborModalComponent } from "./new-labor-modal/new-labor-modal.component";
import { NewPartsModalComponent } from "./new-parts-modal/new-parts-modal.component";
import { NewPermitModalComponent } from "./new-permits-modal/new-permit-modal.component";
import { NewToolModalComponent } from "./new-tool-modal/new-tool-modal.component";
import { NewVideoModalComponent } from "./new-video-modal/new-video-modal.component";
import { SanitizeHtmlPipe } from "./pipes/safe-html.pipe";
import {
  BarcodeGeneratorAllModule,
  QRCodeGeneratorAllModule,
  DataMatrixGeneratorAllModule,
} from "@syncfusion/ej2-angular-barcode-generator";
import { ResetPasswordComponent } from "./reset-password/reset-password.component";
import { EditRevisionModalComponent } from "./edit-revision-modal/edit-revision-modal.component";
import { FieldTypeDisplayPipe } from "./pipes/field-type-display.pipe";
import { TruncateTextPipe } from "./pipes/truncate-text.pipe";
import { SelectStepFromOtherProcedureModalComponent } from "./select-step-from-other-procedure-modal/select-step-from-other-procedure-modal.component";
import { StepSelectComponent } from "./step-select/step-select.component";
import { FormControlPipePipe } from "./pipes/form-control-pipe.pipe";
import { ProgressSaveModalComponent } from "./progress-save-modal/progress-save-modal.component";
import { ExecutionIconColorPipe } from "./pipes/execution-icon-color.pipe";
import { ExecutionTrackLineColorPipe } from "./pipes/execution-track-line-color.pipe";
import { ExecutionIconClassPipe } from "./pipes/execution-icon-class.pipe";
import { PrerequisiteFiguresComponent } from "./prerequisite-figures/prerequisite-figures.component";
import { PrerequisiteVideosComponent } from "./prerequisite-videos/prerequisite-videos.component";
import { PrerequisitePermitsComponent } from "./prerequisite-permits/prerequisite-permits.component";
import { QrCodeViewModalComponent } from "./qr-code-view-modal/qr-code-view-modal.component";
import { DateRangePickerModule } from "@syncfusion/ej2-angular-calendars";
import { NumberToCharacterPipe } from "./pipes/number-to-character.pipe";
import { ConfirmationModalComponent } from "./confirmation-modal/confirmation-modal.component";
import { TimeFormatPipe } from "./pipes/time-format.pipe";
import { VideoPlayerComponent } from "./video-player/video-player.component";
import { VideoPreviewComponent } from "./video-preview/video-preview.component";
import { DropDownButtonModule, SplitButtonModule } from "@syncfusion/ej2-angular-splitbuttons";
import { GanttModule } from "@syncfusion/ej2-angular-gantt";
import { LaborIconsComponent } from "./labor-icons/labor-icons.component";
import { HazardIconsComponent } from "./hazard-icons/hazard-icons.component";
import { ExecutionStatusPipe } from "./pipes/execution-status.pipe";
import { CopyrightInfoComponent } from "./copyright-info/copyright-info.component";

const GRID_CONFIG: WSSyncFusionGridConfig = {
  apiRoot: environment.apiRoot,
};
@NgModule({
  declarations: [
    NotFoundComponent,
    UnauthorizedComponent,
    NavbarComponent,
    ModalComponent,
    ConfirmationModalComponent,
    PageHeaderComponent,
    SidebarComponent,
    FormLabelComponent,
    RedAsteriskComponent,
    ButtonComponent,
    AlertComponent,
    FileUploadNGX2Component,
    ImageLightboxComponent,
    ImageThumbnailComponent,
    ExcelImportModalComponent,
    TagsGridComponent,
    NewTagModalComponent,
    EditTagModalComponent,
    EditPrecautionModalComponent,
    NewPrecautionModalComponent,
    EditProcedureModalComponent,
    NewProcedureModalComponent,
    RuleTypeDisplayPipe,
    StepTypeDisplayPipe,
    VideoUploadNGX2Component,
    NewHarzardModalComponent,
    NewEquipmentModalComponent,
    NewFigureModalComponent,
    NewLaborModalComponent,
    NewPartsModalComponent,
    NewPermitModalComponent,
    NewToolModalComponent,
    NewVideoModalComponent,
    SanitizeHtmlPipe,
    ResetPasswordComponent,
    EditRevisionModalComponent,
    FieldTypeDisplayPipe,
    TruncateTextPipe,
    SelectStepFromOtherProcedureModalComponent,
    StepSelectComponent,
    FormControlPipePipe,
    ProgressSaveModalComponent,
    ExecutionIconColorPipe,
    ExecutionTrackLineColorPipe,
    ExecutionIconClassPipe,
    PrerequisiteFiguresComponent,
    PrerequisitePermitsComponent,
    PrerequisiteVideosComponent,
    QrCodeViewModalComponent,
    NumberToCharacterPipe,
    TimeFormatPipe,
    AlertModalComponent,
    VideoPlayerComponent,
    VideoPreviewComponent,
    LaborIconsComponent,
    HazardIconsComponent,
    ExecutionStatusPipe,
    CopyrightInfoComponent,
  ],
  imports: [
    CommonModule,
    NgxPaginationModule,
    ReactiveFormsModule,
    RouterModule,
    WSSyncFusionGridModule.forRoot(GRID_CONFIG),
    LoadingIndicatorModule,
    NgxFileDropModule,
    NgbModule,
    MultiSelectModule,
    TreeViewModule,
    FormsModule,
    UploaderModule,
    RichTextEditorModule,
    ComboBoxModule,
    ListBoxAllModule,
    BarcodeGeneratorAllModule,
    QRCodeGeneratorAllModule,
    DataMatrixGeneratorAllModule,
    DateRangePickerModule,
    DropDownListModule,
    DropDownButtonModule,
    GanttModule,
  ],
  exports: [
    NotFoundComponent,
    UnauthorizedComponent,
    NgxPaginationModule,
    TreeViewModule,
    NavbarComponent,
    ReactiveFormsModule,
    WSSyncFusionGridModule,
    ModalComponent,
    ConfirmationModalComponent,
    PageHeaderComponent,
    SidebarComponent,
    NgbModule,
    FormLabelComponent,
    RedAsteriskComponent,
    ButtonComponent,
    CommonModule,
    AlertComponent,
    LoadingIndicatorComponent,
    NgxFileDropModule,
    FileUploadNGX2Component,
    MultiSelectModule,
    ImageLightboxComponent,
    ImageThumbnailComponent,
    UploaderModule,
    TagsGridComponent,
    RichTextEditorModule,
    EditPrecautionModalComponent,
    NewPrecautionModalComponent,
    EditProcedureModalComponent,
    NewProcedureModalComponent,
    RuleTypeDisplayPipe,
    StepTypeDisplayPipe,
    VideoUploadNGX2Component,
    NewHarzardModalComponent,
    NewEquipmentModalComponent,
    NewFigureModalComponent,
    NewLaborModalComponent,
    NewPartsModalComponent,
    NewPermitModalComponent,
    NewToolModalComponent,
    NewVideoModalComponent,
    ComboBoxModule,
    ListBoxAllModule,
    SanitizeHtmlPipe,
    BarcodeGeneratorAllModule,
    QRCodeGeneratorAllModule,
    DataMatrixGeneratorAllModule,
    ResetPasswordComponent,
    EditRevisionModalComponent,
    FieldTypeDisplayPipe,
    TruncateTextPipe,
    SelectStepFromOtherProcedureModalComponent,
    StepSelectComponent,
    FormControlPipePipe,
    ExecutionIconColorPipe,
    ExecutionTrackLineColorPipe,
    ExecutionIconClassPipe,
    PrerequisiteFiguresComponent,
    PrerequisitePermitsComponent,
    PrerequisiteVideosComponent,
    QrCodeViewModalComponent,
    DateRangePickerModule,
    NumberToCharacterPipe,
    TimeFormatPipe,
    AlertModalComponent,
    VideoPlayerComponent,
    VideoPreviewComponent,
    DropDownListModule,
    DropDownButtonModule,
    GanttModule,
    LaborIconsComponent,
    HazardIconsComponent,
    ExecutionStatusPipe,
    CopyrightInfoComponent,
  ],
})
export class SharedModule {}
