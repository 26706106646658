import { Component, EventEmitter, Input, Output } from "@angular/core";
import { NgbActiveModal } from "@ng-bootstrap/ng-bootstrap";
import { HazardsV1Model, HazardsService } from "src/app/api/Hazards.service";
import {
  PrecautionsService,
  PrecautionsV1Model,
  PrecautionsUpdateV1Model,
} from "src/app/api/Precautions.service";
import { FormRepoService } from "src/app/modules/shared/services/form-repo.service";

@Component({
  selector: "app-edit-precaution-modal",
  templateUrl: "./edit-precaution-modal.component.html",
  styleUrls: ["./edit-precaution-modal.component.scss"],
})
export class EditPrecautionModalComponent {
  @Input() public precaution: PrecautionsV1Model = new PrecautionsV1Model();
  @Input() isLibrary: boolean = true;
  @Output() public submitCompletedEvent = new EventEmitter();
  editPrecautionForm = this.formRepoService.newPrecautionForm();
  isLoading: boolean = true;
  precautionLevel: string | null = null;
  precautionLevels: string[] = ["CAUTION", "LIMITATION", "NOTE", "WARNING"];

  constructor(
    private precautionService: PrecautionsService,
    private hazardService: HazardsService,
    public activeModal: NgbActiveModal,
    private formRepoService: FormRepoService
  ) {}

  ngOnInit(): void {
    this.setupForm();
  }

  setupForm(): void {
    console.log(this.precaution);
    this.editPrecautionForm.controls["description"].setValue(
      this.precaution.description
    );
    this.precautionLevel = this.precaution.precautionLevel;
    this.editPrecautionForm.controls["isActive"].setValue(
      this.precaution.isActive!.toString()
    );

    this.isLoading = false;
  }

  onSubmit(): void {
    let model = new PrecautionsUpdateV1Model();

    model.description = this.editPrecautionForm.controls["description"].value;
    model.hazardId = this.precaution.hazardId;
    model.precautionLevel = this.precautionLevel;

    if (this.editPrecautionForm.controls["isActive"].value == "false") {
      model.isActive = false;
    } else {
      model.isActive = true;
    }
    model.precautionId = this.precaution.precautionId;

    console.log(model);
    this.precautionService.update(model).subscribe((data) => {
      this.submitCompletedEvent.emit();
      this.activeModal.close();
    });
  }

  onCancel(): void {
    this.activeModal.close();
  }
}
