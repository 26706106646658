export const environment = {
  production: false,

  apiRoot: 'https://dev-api.jobplanpro.com/', // Base URL for the api
  stsAuthority: 'https://dev-api.jobplanpro.com/', // Base URL for the token service
  clientId: 'RW_SecureAccount', // Client ID of this application on the token server
  clientRoot: 'https://dev.jobplanpro.com/', // Base URL of the client application
  clientSecret: '67c2a8cc-42ab-4569-9d61-7937as2d33a2',
  logErrorsToAPI: false,
  requireExportPassword: true,
  version: '1.0.0.2',
  idle: 28800, //1740
};
