import { Pipe, PipeTransform } from "@angular/core";
import { ExecutionStatus } from "src/app/api/Execution.service";

@Pipe({
  name: "executionStatus",
})
export class ExecutionStatusPipe implements PipeTransform {
  transform(status: ExecutionStatus): string {
    switch (status) {
      case ExecutionStatus.NotStarted:
        return "Not Started";
      case ExecutionStatus.InProgressOnTime:
        return "In-Progress";
      case ExecutionStatus.InProgressLate:
        return "In-Progress Late";
      case ExecutionStatus.CompletedLate:
        return "Completed Late";
      case ExecutionStatus.CompletedOnTime:
        return "Completed On-Time";
      case ExecutionStatus.Skipped:
        return "Skipped";
      case ExecutionStatus.Cancelled:
        return "Cancelled";
      default:
        return "N/A";
    }
  }
}
