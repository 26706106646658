import { Injectable } from '@angular/core';
import {
  HttpEvent,
  HttpInterceptor,
  HttpHandler,
  HttpRequest,
  HttpErrorResponse,
} from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { Router } from '@angular/router';
import { tap, catchError } from 'rxjs/operators';
import { ToastService } from '../services/toast.service';

export const InterceptorHandle400InComponent = 'I400Local';

@Injectable()
export class Http500Interceptor implements HttpInterceptor {
  constructor(private router: Router, private toastService: ToastService) {}

  intercept(
    req: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    return next.handle(req).pipe(
      catchError((error: HttpErrorResponse) => {
        //console.log(error);
        // For any 500 error, show the following message
        if (error.status == 500) {
          //console.log(error);
          this.toastService.error('Error communicating with the server.');
        } else if (error.status == 403) {
          //console.log(error);
          this.toastService.error(
            'Error you do not have permission to complete this action.'
          );
        } else if (error.status == 400) {
          if (!req.headers.has(InterceptorHandle400InComponent)) {
            const data = error.error.errors;
            const fields = Object.keys(error.error.errors || {});

            fields.forEach((field) => {
              data[field].forEach((e: any) => {
                if (field == 'Error' || field == 'errors') {
                  this.toastService.error(e);
                } else {
                  this.toastService.error(field + ': ' + e);
                }
              });
            });
          }
        }
        // Send the error down the observable stream - allow for custom handling by the component.
        return throwError(error);
      })
    );
  }
}
