// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.e-input-group {
  height: 38px;
}`, "",{"version":3,"sources":["webpack://./src/app/modules/shared/file-upload-ngx2/file-upload-ngx2.component.scss"],"names":[],"mappings":"AAAE;EACG,YAAA;AACL","sourcesContent":["  .e-input-group{\n     height: 38px\n  }"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
