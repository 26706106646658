 
   
   import { Injectable } from '@angular/core';
   import { HttpClient, HttpHeaders, HttpErrorResponse } from '@angular/common/http';

   import { Observable, of, throwError  } from 'rxjs';

   import { environment } from '../../environments/environment';
   //import { LoggerService } from '../../modules/core/logger.service'
   import { GenericSearchResultV1Model } from '../../app/models/GenericSearchResultV1Model';
   import { PageableListV1Model } from '../../app/models/PageableListV1Model';

   const httpOptions = {
     headers: new HttpHeaders({ 'Content-Type': 'application/json' }),
     withCredentials: true
   };

   const httpOptions_Handle400InComponent = {
     headers: new HttpHeaders({ 'Content-Type': 'application/json', 'I400Local': '' }),
     withCredentials: true
   }
    
   @Injectable({
     providedIn: 'root'
   })
   export class UserService {

   
        private classVersion = "1.0";
        
         
      constructor(private http: HttpClient) { }
      
        
          
              getUser( handle400Locally: boolean = false) : Observable<UserV1Model>  { 
              let version = this.classVersion;
              return this.http.get<any>(environment.apiRoot + `api/v${version}/user/User`, handle400Locally ? httpOptions_Handle400InComponent : httpOptions )       
            };
          
      
        
          
              getUserId( handle400Locally: boolean = false) : Observable<number>  { 
              let version = this.classVersion;
              return this.http.get<any>(environment.apiRoot + `api/v${version}/user/id`, handle400Locally ? httpOptions_Handle400InComponent : httpOptions )       
            };
          
      
        
          
              getByID(user_id: number,  handle400Locally: boolean = false) : Observable<UserV1Model>  { 
              let version = this.classVersion;
              return this.http.get<any>(environment.apiRoot + `api/v${version}/user/${user_id}`, handle400Locally ? httpOptions_Handle400InComponent : httpOptions )       
            };
          
      
        
          
              getUsers( handle400Locally: boolean = false) : Observable<UserV1Model[]>  { 
              let version = this.classVersion;
              return this.http.get<any>(environment.apiRoot + `api/v${version}/user/users`, handle400Locally ? httpOptions_Handle400InComponent : httpOptions )       
            };
          
      
        
          
              getUserSettings( handle400Locally: boolean = false) : Observable<UserSettingV1Model[]>  { 
              let version = this.classVersion;
              return this.http.get<any>(environment.apiRoot + `api/v${version}/user/UserSettings`, handle400Locally ? httpOptions_Handle400InComponent : httpOptions )       
            };
          
      

            


                  
        
          
              search(model: UserSearchV1Model,  handle400Locally: boolean = false) : Observable<PageableListV1Model<UserV1Model>>  { 
              let version = this.classVersion;
              return this.http.post<any>(environment.apiRoot + `api/v${version}/user/Search`,model, handle400Locally ? httpOptions_Handle400InComponent : httpOptions ) //p => p.Attributes.Count > 0 ----only adds FromBody     
            };
          
      
        
          
              insertUser(model: UserInsertV1Model,  handle400Locally: boolean = false) : Observable<InsertResponseV1Model>  { 
              let version = this.classVersion;
              return this.http.post<any>(environment.apiRoot + `api/v${version}/user/Insert`,model, handle400Locally ? httpOptions_Handle400InComponent : httpOptions ) //p => p.Attributes.Count > 0 ----only adds FromBody     
            };
          
      
        
          
              getUserAccounts(userId: number,  handle400Locally: boolean = false) : Observable<UserAccountV1Model[]>  { 
              let version = this.classVersion;
              return this.http.post<any>(environment.apiRoot + `api/v${version}/user/GetUserAccounts`,userId, handle400Locally ? httpOptions_Handle400InComponent : httpOptions ) //p => p.Attributes.Count > 0 ----only adds FromBody     
            };
          
      
        
          
              getUserAccountsWithoutCorporate(userId: number,  handle400Locally: boolean = false) : Observable<UserAccountV1Model[]>  { 
              let version = this.classVersion;
              return this.http.post<any>(environment.apiRoot + `api/v${version}/user/GetUserAccounts/NoCorporate`,userId, handle400Locally ? httpOptions_Handle400InComponent : httpOptions ) //p => p.Attributes.Count > 0 ----only adds FromBody     
            };
          
      
        
          
              insertUserAccounts(models: UserAccountInsertV1Model[], userId: number,  handle400Locally: boolean = false) : Observable<InsertResponseV1Model>  { 
              let version = this.classVersion;
              return this.http.post<any>(environment.apiRoot + `api/v${version}/user/InsertUserAccounts?userId=${userId}`,models, handle400Locally ? httpOptions_Handle400InComponent : httpOptions ) //p => p.Attributes.Count > 0 ----only adds FromBody     
            };
          
      
        
          
              updateUser(model: UserUpdateV1Model,  handle400Locally: boolean = false) : Observable<UpdateResponseV1Model>  { 
              let version = this.classVersion;
              return this.http.post<any>(environment.apiRoot + `api/v${version}/user/Update`,model, handle400Locally ? httpOptions_Handle400InComponent : httpOptions ) //p => p.Attributes.Count > 0 ----only adds FromBody     
            };
          
      
        
          
              deleteUser(userId: number,  handle400Locally: boolean = false) : Observable<UpdateResponseV1Model>  { 
              let version = this.classVersion;
              return this.http.post<any>(environment.apiRoot + `api/v${version}/user/Delete`,userId, handle400Locally ? httpOptions_Handle400InComponent : httpOptions ) //p => p.Attributes.Count > 0 ----only adds FromBody     
            };
          
      
        
          
              updateUserSetting(setting: UserSettingV1Model,  handle400Locally: boolean = false) : Observable<UpdateResponseV1Model>  { 
              let version = this.classVersion;
              return this.http.post<any>(environment.apiRoot + `api/v${version}/user/UpdateUserSetting`,setting, handle400Locally ? httpOptions_Handle400InComponent : httpOptions ) //p => p.Attributes.Count > 0 ----only adds FromBody     
            };
          
      
        
          
              updateUserSettings(settings: UserSettingV1Model[],  handle400Locally: boolean = false) : Observable<UpdateResponseV1Model>  { 
              let version = this.classVersion;
              return this.http.post<any>(environment.apiRoot + `api/v${version}/user/UpdateUserSettings`,settings, handle400Locally ? httpOptions_Handle400InComponent : httpOptions ) //p => p.Attributes.Count > 0 ----only adds FromBody     
            };
          
      

                        

                  
}

export class UserV1Model {
    constructor(  
        // Inherited properties 
        
      
        //Properties
        
        public userId: number | null = null,   
        public firstName: string | null = null,   
        public lastName: string | null = null,   
        public name: string | null = null,   
        public jobTitle: string | null = null,   
        public email: string | null = null,   
        public country: string | null = null,   
        public address: string | null = null,   
        public address2: string | null = null,   
        public city: string | null = null,   
        public state: string | null = null,   
        public zip: string | null = null,   
        public phone: string | null = null,   
        public createdDate: Date | null = null,   
        public createdByUserId: number | null = null,   
        public updatedDate: Date | null = null,   
        public updatedByUserId: number | null = null,   
        public notes: string | null = null,   
        public isActive: boolean | null = null,   
        public roles: RoleM1V1Model[] | null = null,   
        public lastLogin: Date | null = null,   
        public resetCode: string | null = null,   
        public resetDate: Date | null = null,   
        public lastAccountId: number | null = null,   
        public isGlobal: boolean | null = null,   
        public accountId: number | null = null,   
      ) { }
}

export class RoleM1V1Model {
    constructor(  
        // Inherited properties 
        
      
        //Properties
        
        public roleId: number | null = null,   
        public role: string | null = null,   
        public isGlobal: boolean | null = null,   
      ) { }
}

export class UserSearchV1Model  {
    constructor(  
        // Inherited properties 
        
        public pageNumber: number | null = null,
        public pageSize: number | null = null,
        public sortField: string | null = null,
        public sortDirection: string | null = null,
      
        //Properties
        
        public name: string | null = null,   
        public email: string | null = null,   
        public isActive: boolean | null = null,   
      ) { }
}

export class InsertResponseV1Model  {
    constructor(  
        // Inherited properties 
        
        public success: boolean | null = null,
        public message: string | null = null,
      
        //Properties
        
        public identityId: number | null = null,   
        public identityIds: number[] | null = null,   
      ) { }
}

export class UserInsertV1Model {
    constructor(  
        // Inherited properties 
        
      
        //Properties
        
        public firstName: string | null = null,   
        public lastName: string | null = null,   
        public jobTitle: string | null = null,   
        public country: string | null = null,   
        public address: string | null = null,   
        public address2: string | null = null,   
        public city: string | null = null,   
        public state: string | null = null,   
        public zip: string | null = null,   
        public phone: string | null = null,   
        public email: string | null = null,   
        public notes: string | null = null,   
        public isActive: boolean | null = null,   
        public password: string | null = null,   
        public roles: number[] | null = null,   
      ) { }
}

export class UserAccountV1Model {
    constructor(  
        // Inherited properties 
        
      
        //Properties
        
        public accountId: number | null = null,   
        public accountName: string | null = null,   
        public userId: number | null = null,   
      ) { }
}

export class UserAccountInsertV1Model {
    constructor(  
        // Inherited properties 
        
      
        //Properties
        
        public accountId: number | null = null,   
        public accountName: string | null = null,   
        public userId: number | null = null,   
      ) { }
}

export class UpdateResponseV1Model {
    constructor(  
        // Inherited properties 
        
      
        //Properties
        
        public totalRowsUpdated: number | null = null,   
      ) { }
}

export class UserUpdateV1Model {
    constructor(  
        // Inherited properties 
        
      
        //Properties
        
        public userId: number | null = null,   
        public firstName: string | null = null,   
        public lastName: string | null = null,   
        public jobTitle: string | null = null,   
        public country: string | null = null,   
        public address: string | null = null,   
        public address2: string | null = null,   
        public city: string | null = null,   
        public state: string | null = null,   
        public zip: string | null = null,   
        public phone: string | null = null,   
        public email: string | null = null,   
        public notes: string | null = null,   
        public isActive: boolean | null = null,   
        public roles: number[] | null = null,   
        public labor: number[] | null = null,   
      ) { }
}

export class UserSettingV1Model {
    constructor(  
        // Inherited properties 
        
      
        //Properties
        
        public settingId: number | null = null,   
        public userId: number | null = null,   
        public settingKey: string | null = null,   
        public settingValue: string | null = null,   
        public lastModifiedById: number | null = null,   
        public lastModifiedDate: Date | null = null,   
      ) { }
}





/* 
     Debug Info:
     PrintDebugInfo - put a dollar sign in front of Print to enable this 
*/

