import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'executionTrackLineColor'
})
export class ExecutionTrackLineColorPipe implements PipeTransform {

  transform(status: number | null): object {
    switch (status){
      case null:
        return { 'background-color': 'gray' };
      case 1:
        return { 'background-color': 'black' };
      case 2:
        return { 'background-color': 'green' };
      case 3:
        return { 'background-color': 'red' };
      case 4:
        return { 'background-color': 'green' };
      case 5:
        return { 'background-color': 'red' };
      case 6:
        return { 'background-color': 'black' };
      default:
        return { 'background-color': 'black' }
    }
  }

}
