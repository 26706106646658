// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.patent-info {
  font-size: 10px;
  color: rgb(82, 82, 82);
  margin-top: 10px;
}`, "",{"version":3,"sources":["webpack://./src/app/modules/shared/copyright-info/copyright-info.component.scss"],"names":[],"mappings":"AAAA;EACE,eAAA;EACA,sBAAA;EACA,gBAAA;AACF","sourcesContent":[".patent-info {\n  font-size: 10px;\n  color: rgb(82, 82, 82);\n  margin-top: 10px;\n}\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
