import { Component, EventEmitter, Input, Output } from "@angular/core";
import { NgbActiveModal } from "@ng-bootstrap/ng-bootstrap";
import { ToastrService } from "ngx-toastr";
import {
  TagsInsertV1Model,
  TagsService,
  TagsUpdateV1Model,
  TagsV1Model,
} from "src/app/api/Tags.service";
import { FormRepoService } from "../../services/form-repo.service";

@Component({
  selector: "app-edit-tag-modal",
  templateUrl: "./edit-tag-modal.component.html",
  styleUrls: ["./edit-tag-modal.component.scss"],
})
export class EditTagModalComponent {
  @Input() public tag!: TagsV1Model;
  @Output() public submitCompletedEvent = new EventEmitter();
  editTagForm = this.formRepoService.newTagForm();

  constructor(
    public activeModal: NgbActiveModal,
    public tagsService: TagsService,
    private formRepoService: FormRepoService,
    private toastService: ToastrService
  ) {}

  ngOnInit(): void {
    this.editTagForm.controls["tagName"].setValue(this.tag.tagName!);
  }

  onSubmit(): void {
    let newTag = new TagsUpdateV1Model();

    newTag.tagName = this.editTagForm.controls["tagName"].value;
    newTag.tagId = this.tag.tagId;

    this.tagsService.update(newTag).subscribe((data) => {
      this.toastService.success(newTag.tagName + " updated");
      this.submitCompletedEvent.emit();
    });
  }

  onCancel(): void {
    this.activeModal.close();
  }
}
