import { Injectable } from '@angular/core';
import { CanActivate, Router, ActivatedRouteSnapshot } from '@angular/router';
import { AuthService } from '../services/auth.service';
import { permissionType } from '../../../models/enums';

@Injectable()
export class AuthorizationGuard implements CanActivate {
  constructor(protected router: Router, protected authService: AuthService) {}

  canActivate(route: ActivatedRouteSnapshot): Promise<boolean> | boolean {
    return new Promise<boolean>((resolve, reject) => {
      //console.log(route.data['auth']);
      this.hasAnyOfRequiredPermission(route.data['auth'])
        .then((returnVal) => {
          // console.log("CanActivate then");
          if (returnVal == true) {
            //console.log("CanActivate true");
            resolve(true);
          } else {
            //console.log("CanActivate false");
            this.router.navigate(['/unauthorized']);
            resolve(false);
          }
        })
        .catch(() => {
          //console.log("canActivate error handler");
          resolve(false);
        });
    });
  }

  protected hasAnyOfRequiredPermission(
    authGroup: permissionType[]
  ): Promise<boolean> {
    if (this.authService.getCurrentContext()) {
      // console.log("Permissions previously loaded");
      return new Promise((resolve, reject) => {
        resolve(this.authService.hasAnyOfPermissions(authGroup));
      });
      // this.router.navigate(['/unauthorized']);
    } else {
      //console.log("If auth context runs before bootstrap, this should never happen.");
      this.router.navigate(['/unauthorized']);
    }

    return new Promise<boolean>((resolve, reject) => {
      resolve(false);
    });
  }

  protected hasRequiredPermission(authGroup: permissionType): Promise<boolean> {
    //console.log("AuthGuard - Checking for permission: " + authGroup);
    // If user’s permissions already retrieved from the API

    if (authGroup == permissionType.Authenticated) {
      if (!this.authService.isLoggedIn()) {
        this.authService.redirectToLogin();
      } else {
        return new Promise((resolve, reject) => {
          resolve(true);
        });
      }
    }

    if (this.authService.getCurrentContext()) {
      // console.log("Permissions previously loaded");
      return new Promise((resolve, reject) => {
        resolve(this.authService.hasPermission(authGroup));
      });
      // this.router.navigate(['/unauthorized']);
    } else {
      //console.log("If auth context runs before bootstrap, this should never happen.");
      this.router.navigate(['/unauthorized']);
      // The code below, and related function in authService, were built to allow the app to bootstrap prior to the user context being retrieved.
      //  It basically worked, but not sure we want it to work that way.
      //  If there is no user token, why load the app at all?  Kick out to token server faster.
      //    If we have the token, why build a mechanism to show a basically non-functional app for the short window of time before the context comes back?
      //  May need to be revisited.

      //  // Otherwise, must request permissions from the API first
      //  // Does this make sense?
      //  console.log("AuthGuard - No permissions exist.  Calling API.")
      //  return new Promise<boolean>((resolve, reject) => {
      //       this.authService.initializePermissions()
      //       .then(() => {
      //             console.log("AuthGuard -then");
      //          if (authGroup) {
      //                console.log("AuthGuard - permissions retrieved");
      //               resolve(this.authService.hasPermission(authGroup));
      //          } else {
      //                 console.log("AuthGuard - huh?");
      //               resolve(this.authService.hasPermission(null));
      //          }

      //       }).catch(() => {
      //           console.log("Error getting permissions");
      //           resolve(false);
      //       });
      //     });
    }

    return new Promise<boolean>((resolve, reject) => {
      resolve(false);
    });
  }
}
