 
   
   import { Injectable } from '@angular/core';
   import { HttpClient, HttpHeaders, HttpErrorResponse } from '@angular/common/http';

   import { Observable, of, throwError  } from 'rxjs';

   import { environment } from '../../environments/environment';
   //import { LoggerService } from '../../modules/core/logger.service'
   import { GenericSearchResultV1Model } from '../../app/models/GenericSearchResultV1Model';
   import { PageableListV1Model } from '../../app/models/PageableListV1Model';

   const httpOptions = {
     headers: new HttpHeaders({ 'Content-Type': 'application/json' }),
     withCredentials: true
   };

   const httpOptions_Handle400InComponent = {
     headers: new HttpHeaders({ 'Content-Type': 'application/json', 'I400Local': '' }),
     withCredentials: true
   }
    
   @Injectable({
     providedIn: 'root'
   })
   export class PermitsService {

   
        private classVersion = "1.0";
        
         
      constructor(private http: HttpClient) { }
      

            


                  
        
          
              getPermitsByID(permitId: number,  handle400Locally: boolean = false) : Observable<PermitsV1Model>  { 
              let version = this.classVersion;
              return this.http.post<any>(environment.apiRoot + `api/v${version}/permits/GetById`,permitId, handle400Locally ? httpOptions_Handle400InComponent : httpOptions ) //p => p.Attributes.Count > 0 ----only adds FromBody     
            };
          
      
        
          
              getProcedureStepPermits(procedureTaskStepId: number,  handle400Locally: boolean = false) : Observable<ProcedureStepPermitV1Model[]>  { 
              let version = this.classVersion;
              return this.http.post<any>(environment.apiRoot + `api/v${version}/permits/GetProcedureStepPermits`,procedureTaskStepId, handle400Locally ? httpOptions_Handle400InComponent : httpOptions ) //p => p.Attributes.Count > 0 ----only adds FromBody     
            };
          
      
        
          
              getScopingPermits(scopeDocumentId: number,  handle400Locally: boolean = false) : Observable<ScopingPermitV1Model[]>  { 
              let version = this.classVersion;
              return this.http.post<any>(environment.apiRoot + `api/v${version}/permits/GetScopingPermits`,scopeDocumentId, handle400Locally ? httpOptions_Handle400InComponent : httpOptions ) //p => p.Attributes.Count > 0 ----only adds FromBody     
            };
          
      
        
          
              searchProcedureStepScopePermits(model: PermitsScopingSearchV1Model,  handle400Locally: boolean = false) : Observable<PermitsV1Model[]>  { 
              let version = this.classVersion;
              return this.http.post<any>(environment.apiRoot + `api/v${version}/permits/SearchProcedureStepScopePermits`,model, handle400Locally ? httpOptions_Handle400InComponent : httpOptions ) //p => p.Attributes.Count > 0 ----only adds FromBody     
            };
          
      
        
          
              getRevisionPermits(procedureRevisionId: number,  handle400Locally: boolean = false) : Observable<RevisionPermitV1Model[]>  { 
              let version = this.classVersion;
              return this.http.post<any>(environment.apiRoot + `api/v${version}/permits/GetRevisionPermits`,procedureRevisionId, handle400Locally ? httpOptions_Handle400InComponent : httpOptions ) //p => p.Attributes.Count > 0 ----only adds FromBody     
            };
          
      
        
          
              insert(model: PermitsInsertV1Model,  handle400Locally: boolean = false) : Observable<InsertResponseV1Model>  { 
              let version = this.classVersion;
              return this.http.post<any>(environment.apiRoot + `api/v${version}/permits/Insert`,model, handle400Locally ? httpOptions_Handle400InComponent : httpOptions ) //p => p.Attributes.Count > 0 ----only adds FromBody     
            };
          
      
        
          
              insertProcedurePermit(model: ProcedureStepPermitInsertV1Model,  handle400Locally: boolean = false) : Observable<InsertResponseV1Model>  { 
              let version = this.classVersion;
              return this.http.post<any>(environment.apiRoot + `api/v${version}/permits/InsertProcedurePermit`,model, handle400Locally ? httpOptions_Handle400InComponent : httpOptions ) //p => p.Attributes.Count > 0 ----only adds FromBody     
            };
          
      
        
          
              insertScopingPermit(model: ScopingPermitInsertV1Model,  handle400Locally: boolean = false) : Observable<InsertResponseV1Model>  { 
              let version = this.classVersion;
              return this.http.post<any>(environment.apiRoot + `api/v${version}/permits/InsertScopingPermit`,model, handle400Locally ? httpOptions_Handle400InComponent : httpOptions ) //p => p.Attributes.Count > 0 ----only adds FromBody     
            };
          
      
        
          
              update(model: PermitsUpdateV1Model,  handle400Locally: boolean = false) : Observable<UpdateResponseV1Model>  { 
              let version = this.classVersion;
              return this.http.post<any>(environment.apiRoot + `api/v${version}/permits/Update`,model, handle400Locally ? httpOptions_Handle400InComponent : httpOptions ) //p => p.Attributes.Count > 0 ----only adds FromBody     
            };
          
      
        
          
              delete(model: DocumentDeleteV1Model,  handle400Locally: boolean = false) : Observable<CommonResponseV1Model>  { 
              let version = this.classVersion;
              return this.http.post<any>(environment.apiRoot + `api/v${version}/permits/Delete`,model, handle400Locally ? httpOptions_Handle400InComponent : httpOptions ) //p => p.Attributes.Count > 0 ----only adds FromBody     
            };
          
      
        
          
              removeProcedurePermit(procedureTaskStepPermitId: number,  handle400Locally: boolean = false) : Observable<CommonResponseV1Model>  { 
              let version = this.classVersion;
              return this.http.post<any>(environment.apiRoot + `api/v${version}/permits/RemoveProcedurePermit`,procedureTaskStepPermitId, handle400Locally ? httpOptions_Handle400InComponent : httpOptions ) //p => p.Attributes.Count > 0 ----only adds FromBody     
            };
          
      
        
          
              removeScopingPermit(scopeDocumentPermitId: number,  handle400Locally: boolean = false) : Observable<CommonResponseV1Model>  { 
              let version = this.classVersion;
              return this.http.post<any>(environment.apiRoot + `api/v${version}/permits/RemoveScopingPermit`,scopeDocumentPermitId, handle400Locally ? httpOptions_Handle400InComponent : httpOptions ) //p => p.Attributes.Count > 0 ----only adds FromBody     
            };
          
      
        
          
              search(model: PermitsSearchV1Model,  handle400Locally: boolean = false) : Observable<PermitsV1Model[]>  { 
              let version = this.classVersion;
              return this.http.post<any>(environment.apiRoot + `api/v${version}/permits/Search`,model, handle400Locally ? httpOptions_Handle400InComponent : httpOptions ) //p => p.Attributes.Count > 0 ----only adds FromBody     
            };
          
      
        
          
              searchScopingPermit(model: PermitsScopingSearchV1Model,  handle400Locally: boolean = false) : Observable<PermitsV1Model[]>  { 
              let version = this.classVersion;
              return this.http.post<any>(environment.apiRoot + `api/v${version}/permits/SearchScopingPermit`,model, handle400Locally ? httpOptions_Handle400InComponent : httpOptions ) //p => p.Attributes.Count > 0 ----only adds FromBody     
            };
          
      
        
          
              permitInactive(permitId: number,  handle400Locally: boolean = false) : Observable<UpdateResponseV1Model>  { 
              let version = this.classVersion;
              return this.http.post<any>(environment.apiRoot + `api/v${version}/permits/PermitInactive`,permitId, handle400Locally ? httpOptions_Handle400InComponent : httpOptions ) //p => p.Attributes.Count > 0 ----only adds FromBody     
            };
          
      

                        

                  
}

export class PermitsV1Model {
    constructor(  
        // Inherited properties 
        
      
        //Properties
        
        public permitId: number | null = null,   
        public fileName: string | null = null,   
        public displayName: string | null = null,   
        public documentGuid: string | null = null,   
        public isActive: boolean | null = null,   
        public createdByUserId: number | null = null,   
        public createdDate: Date | null = null,   
        public updatedByUserId: number | null = null,   
        public updatedDate: Date | null = null,   
        public createdByUserName: string | null = null,   
        public updatedByUserName: string | null = null,   
        public accountId: number | null = null,   
      ) { }
}

export class ProcedureStepPermitV1Model {
    constructor(  
        // Inherited properties 
        
      
        //Properties
        
        public procedureTaskStepPermitId: number | null = null,   
        public procedureTaskStepId: number | null = null,   
        public permitId: number | null = null,   
        public documentId: number | null = null,   
        public accountId: number | null = null,   
        public fileName: string | null = null,   
        public documentGuid: string | null = null,   
        public fileBase64: string | null = null,   
        public contentType: string | null = null,   
        public displayName: string | null = null,   
        public createdDate: Date | null = null,   
      ) { }
}

export class ScopingPermitV1Model {
    constructor(  
        // Inherited properties 
        
      
        //Properties
        
        public scopeDocumentPermitId: number | null = null,   
        public scopeDocumentId: number | null = null,   
        public permitId: number | null = null,   
        public documentId: number | null = null,   
        public accountId: number | null = null,   
        public fileName: string | null = null,   
        public documentGuid: string | null = null,   
        public fileBase64: string | null = null,   
        public contentType: string | null = null,   
        public displayName: string | null = null,   
        public createdDate: Date | null = null,   
      ) { }
}

export class PermitsScopingSearchV1Model {
    constructor(  
        // Inherited properties 
        
      
        //Properties
        
        public searchTerm: string | null = null,   
        public scopeDocumentId: number | null = null,   
        public procedureTaskStepId: number | null = null,   
      ) { }
}

export class RevisionPermitV1Model {
    constructor(  
        // Inherited properties 
        
      
        //Properties
        
        public permitId: number | null = null,   
        public fileName: string | null = null,   
        public displayName: string | null = null,   
        public documentGuid: string | null = null,   
      ) { }
}

export class InsertResponseV1Model  {
    constructor(  
        // Inherited properties 
        
        public success: boolean | null = null,
        public message: string | null = null,
      
        //Properties
        
        public identityId: number | null = null,   
        public identityIds: number[] | null = null,   
      ) { }
}

export class PermitsInsertV1Model {
    constructor(  
        // Inherited properties 
        
      
        //Properties
        
        public fileName: string | null = null,   
        public documentGuid: string | null = null,   
        public stepLevel: number | null = null,   
        public isActive: boolean | null = null,   
      ) { }
}

export class ProcedureStepPermitInsertV1Model {
    constructor(  
        // Inherited properties 
        
      
        //Properties
        
        public procedureTaskStepId: number | null = null,   
        public permitId: number | null = null,   
      ) { }
}

export class ScopingPermitInsertV1Model {
    constructor(  
        // Inherited properties 
        
      
        //Properties
        
        public scopeDocumentId: number | null = null,   
        public permitId: number | null = null,   
      ) { }
}

export class UpdateResponseV1Model {
    constructor(  
        // Inherited properties 
        
      
        //Properties
        
        public totalRowsUpdated: number | null = null,   
      ) { }
}

export class PermitsUpdateV1Model {
    constructor(  
        // Inherited properties 
        
      
        //Properties
        
        public permitId: number | null = null,   
        public fileName: string | null = null,   
        public documentGuid: string | null = null,   
        public stepLevel: number | null = null,   
        public isActive: boolean | null = null,   
      ) { }
}

export class CommonResponseV1Model {
    constructor(  
        // Inherited properties 
        
      
        //Properties
        
        public success: boolean | null = null,   
        public message: string | null = null,   
      ) { }
}

export class DocumentDeleteV1Model {
    constructor(  
        // Inherited properties 
        
      
        //Properties
        
        public documentId: number | null = null,   
        public tablename: string | null = null,   
      ) { }
}

export class PermitsSearchV1Model {
    constructor(  
        // Inherited properties 
        
      
        //Properties
        
        public searchTerm: string | null = null,   
        public procedureRevisionId: number | null = null,   
        public procedureTaskStepId: number | null = null,   
      ) { }
}





/* 
     Debug Info:
     PrintDebugInfo - put a dollar sign in front of Print to enable this 
*/

