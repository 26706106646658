import { Component } from "@angular/core";
import { Router } from "@angular/router";
import { AuthService } from "../../core/services/auth.service";
import { Location } from "@angular/common";
@Component({
  selector: "app-not-found",
  templateUrl: "./not-found.component.html",
  styleUrls: ["./not-found.component.scss"],
})
export class NotFoundComponent {
  constructor(
    private router: Router,
    private authService: AuthService,
    private location: Location
  ) {}
  ngOnInit(): void {}
  onGoBackClicked(): void {
    this.location.back();
  }
}
