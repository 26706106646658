import { Pipe, PipeTransform } from "@angular/core";
import { RuleType } from "src/app/api/ProcedureTasks.service";

@Pipe({
  name: "ruleTypeDisplay",
})
export class RuleTypeDisplayPipe implements PipeTransform {
  transform(value: number): string {
    const ruleTypeName = RuleType[value]; // Get the enum text

    // Convert camelCase to spaced text
    const spacedText = ruleTypeName
      .replace(/([a-z])([A-Z])/g, "$1 $2")
      .replace(/([A-Z])([A-Z][a-z])/g, "$1 $2");

    return spacedText;
  }
}
