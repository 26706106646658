import { Injectable } from "@angular/core";
import { CommandClickEventArgs } from "@syncfusion/ej2-grids";

@Injectable({
  providedIn: "root",
})
//Service for working with CommandClickEventArgs from a CommandClick event on a syncfusion grid.
export class GridCommandService {
  constructor() {}

  //helper for getting the row data from a CommandClickEventArgs and casting it to a type
  getCommandRowData<T>(args: CommandClickEventArgs): T {
    return args.rowData as T;
  }

  //helper for getting the command text from a CommandClickEventArgs and comparing it to a value
  getCommandText(
    textToSearchFor: string,
    args: CommandClickEventArgs
  ): boolean {
    var commandText = args.commandColumn?.buttonOption?.content;
    if (commandText == textToSearchFor) {
      return true;
    }
    return false;
  }
}
