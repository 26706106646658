import { Component, EventEmitter, Output, ViewChild } from "@angular/core";
import { PredicateService, WSSyncFusionGridComponent } from "wspowerpack";
import {
  CommandModel,
  ToolbarItems,
  CommandClickEventArgs,
} from "@syncfusion/ej2-grids";
import { environment } from "src/environments/environment";
import { FormBuilder } from "@angular/forms";
import { NgbModal } from "@ng-bootstrap/ng-bootstrap";
import { ToastrService } from "ngx-toastr";
import { Query, Predicate } from "@syncfusion/ej2-data";
import { AuthService } from "../../core/services/auth.service";
import { GridCommandService } from "../services/grid-command.service";
import { TagsService, TagsV1Model } from "src/app/api/Tags.service";
import { NewTagModalComponent } from "./new-tag-modal/new-tag-modal.component";
import { EditTagModalComponent } from "./edit-tag-modal/edit-tag-modal.component";
import { ConfirmationModalComponent } from "../confirmation-modal/confirmation-modal.component";

@Component({
  selector: "app-tags-grid",
  templateUrl: "./tags-grid.component.html",
  styleUrls: ["./tags-grid.component.scss"],
})
export class TagsGridComponent {
  @ViewChild(WSSyncFusionGridComponent)
  public sharedGrid!: WSSyncFusionGridComponent;
  @Output() public deleteCompletedEvent = new EventEmitter();
  accessToken: string = this.authService.getAccessToken();
  apiRoot: string = environment.apiRoot;
  commands: CommandModel[] = [
    {
      buttonOption: {
        content: "<i class='fa-solid fa-pencil'></i>",
        cssClass: "e-outline-primary",
      },
      title: "Update Tag",
    },
    {
      buttonOption: {
        content: "<i class='fa-solid fa-trash-can'></i>",
        cssClass: "e-outline-danger",
      },
      title: "Delete Tag",
    },
  ];
  toolbarOptions: ToolbarItems[] | object = ["ColumnChooser"];

  constructor(
    private authService: AuthService,
    private formBuilder: FormBuilder,
    private predicateService: PredicateService,
    private modalService: NgbModal,
    private toastService: ToastrService,
    private gridCommandService: GridCommandService,
    private tagsService: TagsService
  ) {}

  ngOnInit(): void {}

  onCreateClicked(): void {
    const modalRef = this.modalService.open(NewTagModalComponent, {
      size: "sm",
    });
    modalRef.componentInstance.submitCompletedEvent.subscribe(() => {
      this.sharedGrid.grid.query = new Query();
      this.sharedGrid.grid.refresh();
    });
  }

  onCommandClick(args: CommandClickEventArgs): void {
    let rowData = this.gridCommandService.getCommandRowData<TagsV1Model>(args);
    if (
      this.gridCommandService.getCommandText(
        "<i class='fa-solid fa-pencil'></i>",
        args
      )
    ) {
      const modalRef = this.modalService.open(EditTagModalComponent, {
        size: "sm",
      });
      modalRef.componentInstance.tag = rowData;
      modalRef.componentInstance.submitCompletedEvent.subscribe(() => {
        this.sharedGrid.grid.query = new Query();
        this.sharedGrid.grid.refresh();
      });
    } else if (
      this.gridCommandService.getCommandText(
        "<i class='fa-solid fa-trash-can'></i>",
        args
      )
    ) {
      const modalRef = this.modalService.open(ConfirmationModalComponent, {});
      modalRef.componentInstance.modalText =
        "Are you sure you want to delete <b>" + rowData.tagName + "</b>?";
      modalRef.componentInstance.actionConfirmedEvent.subscribe(() => {
        this.tagsService.delete(rowData.tagId!).subscribe((data) => {
          this.toastService.success(rowData.tagName + " deleted");
          this.deleteCompletedEvent.emit();
          this.sharedGrid.grid.query = new Query();
          this.sharedGrid.grid.refresh();
        });
      });
    }
  }
}
