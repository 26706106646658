import { Component, Input } from "@angular/core";
import {
  FiguresService,
  RevisionFigureV1Model,
} from "src/app/api/Figures.service";
import { environment } from "src/environments/environment";

@Component({
  selector: "app-prerequisite-figures",
  templateUrl: "./prerequisite-figures.component.html",
  styleUrls: ["./prerequisite-figures.component.scss"],
})
export class PrerequisiteFiguresComponent {
  @Input() public procedureRevisionId: number = 0;
  @Input() public smallPreview: boolean = true;
  figures: RevisionFigureV1Model[] = [];
  apiRoot: string = environment.apiRoot;
  isLoading: boolean = true;

  constructor(private figuresService: FiguresService) {}

  ngOnInit(): void {
    this.figuresService
      .getRevisionFigures(this.procedureRevisionId)
      .subscribe((data) => {
        this.figures = data;
        this.isLoading = false;
      });
  }

  onFileClicked(documentGuid: any): void {
    window.open(
      this.apiRoot + "api/v1/documents/download/" + documentGuid,
      "_blank"
    );
  }
}
