 
   
   import { Injectable } from '@angular/core';
   import { HttpClient, HttpHeaders, HttpErrorResponse } from '@angular/common/http';

   import { Observable, of, throwError  } from 'rxjs';

   import { environment } from '../../environments/environment';
   //import { LoggerService } from '../../modules/core/logger.service'
   import { GenericSearchResultV1Model } from '../../app/models/GenericSearchResultV1Model';
   import { PageableListV1Model } from '../../app/models/PageableListV1Model';

   const httpOptions = {
     headers: new HttpHeaders({ 'Content-Type': 'application/json' }),
     withCredentials: true
   };

   const httpOptions_Handle400InComponent = {
     headers: new HttpHeaders({ 'Content-Type': 'application/json', 'I400Local': '' }),
     withCredentials: true
   }
    
   @Injectable({
     providedIn: 'root'
   })
   export class AccountService {

   
        private classVersion = "1.0";
        
         
      constructor(private http: HttpClient) { }
      
        
          
              getById(accountID: number,  handle400Locally: boolean = false) : Observable<AccountV1Model>  { 
              let version = this.classVersion;
              return this.http.get<any>(environment.apiRoot + `api/v${version}/account/${accountID}`, handle400Locally ? httpOptions_Handle400InComponent : httpOptions )       
            };
          
      
        
          
              getAll( handle400Locally: boolean = false) : Observable<AccountV1Model[]>  { 
              let version = this.classVersion;
              return this.http.get<any>(environment.apiRoot + `api/v${version}/account/GetAll`, handle400Locally ? httpOptions_Handle400InComponent : httpOptions )       
            };
          
      
        
          
              changeAccount(accountCode: string,  handle400Locally: boolean = false) : Observable<AuthSecContext>  { 
              let version = this.classVersion;
              return this.http.get<any>(environment.apiRoot + `api/v${version}/account/ChangeAccount/${encodeURIComponent(accountCode)}`, handle400Locally ? httpOptions_Handle400InComponent : httpOptions )       
            };
          
      
        
          
              getAccountLicensesInUse( handle400Locally: boolean = false) : Observable<AccountLicenseInUseV1Model[]>  { 
              let version = this.classVersion;
              return this.http.get<any>(environment.apiRoot + `api/v${version}/account/GetAccountLicensesInUse`, handle400Locally ? httpOptions_Handle400InComponent : httpOptions )       
            };
          
      

            


                  
        
          
              getByAccountCode(accountCode: string,  handle400Locally: boolean = false) : Observable<AccountV1Model>  { 
              let version = this.classVersion;
              return this.http.post<any>(environment.apiRoot + `api/v${version}/account/GetByAccountCode`,accountCode, handle400Locally ? httpOptions_Handle400InComponent : httpOptions ) //p => p.Attributes.Count > 0 ----only adds FromBody     
            };
          
      
        
          
              insert(model: AccountInsertV1Model,  handle400Locally: boolean = false) : Observable<InsertResponseV1Model>  { 
              let version = this.classVersion;
              return this.http.post<any>(environment.apiRoot + `api/v${version}/account/Insert`,model, handle400Locally ? httpOptions_Handle400InComponent : httpOptions ) //p => p.Attributes.Count > 0 ----only adds FromBody     
            };
          
      
        
          
              update(model: AccountUpdateV1Model,  handle400Locally: boolean = false) : Observable<UpdateResponseV1Model>  { 
              let version = this.classVersion;
              return this.http.post<any>(environment.apiRoot + `api/v${version}/account/Update`,model, handle400Locally ? httpOptions_Handle400InComponent : httpOptions ) //p => p.Attributes.Count > 0 ----only adds FromBody     
            };
          
      
        
          
              delete(accountId: number,  handle400Locally: boolean = false) : Observable<CommonResponseV1Model>  { 
              let version = this.classVersion;
              return this.http.post<any>(environment.apiRoot + `api/v${version}/account/Delete`,accountId, handle400Locally ? httpOptions_Handle400InComponent : httpOptions ) //p => p.Attributes.Count > 0 ----only adds FromBody     
            };
          
      
        
          
              getAccountLicensesById(accountId: number,  handle400Locally: boolean = false) : Observable<AccountLicenseV1Model>  { 
              let version = this.classVersion;
              return this.http.post<any>(environment.apiRoot + `api/v${version}/account/GetAccountLicensesById`,accountId, handle400Locally ? httpOptions_Handle400InComponent : httpOptions ) //p => p.Attributes.Count > 0 ----only adds FromBody     
            };
          
      
        
          
              updateAccountLicenses(model: AccountLicenseUpdateV1Model,  handle400Locally: boolean = false) : Observable<UpdateResponseV1Model>  { 
              let version = this.classVersion;
              return this.http.post<any>(environment.apiRoot + `api/v${version}/account/UpdateAccountLicenses`,model, handle400Locally ? httpOptions_Handle400InComponent : httpOptions ) //p => p.Attributes.Count > 0 ----only adds FromBody     
            };
          
      

                        

                  
}

export class AccountV1Model {
    constructor(  
        // Inherited properties 
        
      
        //Properties
        
        public accountId: number | null = null,   
        public fileBase64: string | null = null,   
        public contentType: string | null = null,   
        public name: string | null = null,   
        public ownerName: string | null = null,   
        public accountTypeId: number | null = null,   
        public accountType: string | null = null,   
        public accountNumber: string | null = null,   
        public licensedHolder: string | null = null,   
        public country: string | null = null,   
        public address: string | null = null,   
        public city: string | null = null,   
        public state: string | null = null,   
        public zip: string | null = null,   
        public accountPhone: string | null = null,   
        public accountEmail: string | null = null,   
        public accountCode: string | null = null,   
        public logoGuid: string | null = null,   
        public parentAccountId: number | null = null,   
        public parentAccountName: string | null = null,   
        public childCount: number | null = null,   
        public isActive: boolean | null = null,   
        public createdByUserId: number | null = null,   
        public createdDate: Date | null = null,   
        public updatedByUserId: number | null = null,   
        public updatedDate: Date | null = null,   
        public childLicensesScoping: number | null = null,   
        public childLicensesWriting: number | null = null,   
        public childLicensesExecution: number | null = null,   
        public childLicensesScheduling: number | null = null,   
        public childLicensesAnalytics: number | null = null,   
      ) { }
}

export class InsertResponseV1Model  {
    constructor(  
        // Inherited properties 
        
        public success: boolean | null = null,
        public message: string | null = null,
      
        //Properties
        
        public identityId: number | null = null,   
        public identityIds: number[] | null = null,   
      ) { }
}

export class AccountInsertV1Model {
    constructor(  
        // Inherited properties 
        
      
        //Properties
        
        public name: string | null = null,   
        public accountTypeId: number | null = null,   
        public ownerName: string | null = null,   
        public accountNumber: string | null = null,   
        public country: string | null = null,   
        public address: string | null = null,   
        public city: string | null = null,   
        public state: string | null = null,   
        public zip: string | null = null,   
        public accountPhone: string | null = null,   
        public accountEmail: string | null = null,   
        public accountCode: string | null = null,   
        public isActive: boolean | null = null,   
        public parentAccountId: number | null = null,   
      ) { }
}

export class UpdateResponseV1Model {
    constructor(  
        // Inherited properties 
        
      
        //Properties
        
        public totalRowsUpdated: number | null = null,   
      ) { }
}

export class AccountUpdateV1Model {
    constructor(  
        // Inherited properties 
        
      
        //Properties
        
        public accountId: number | null = null,   
        public name: string | null = null,   
        public ownerName: string | null = null,   
        public accountTypeId: number | null = null,   
        public accountNumber: string | null = null,   
        public country: string | null = null,   
        public address: string | null = null,   
        public city: string | null = null,   
        public state: string | null = null,   
        public zip: string | null = null,   
        public accountPhone: string | null = null,   
        public accountEmail: string | null = null,   
        public accountCode: string | null = null,   
        public isActive: boolean | null = null,   
        public parentAccountId: number | null = null,   
      ) { }
}

export class CommonResponseV1Model {
    constructor(  
        // Inherited properties 
        
      
        //Properties
        
        public success: boolean | null = null,   
        public message: string | null = null,   
      ) { }
}

export class AuthSecContext {
    constructor(  
        // Inherited properties 
        
      
        //Properties
        
        public context: SecurityContextV1Model | null = null,   
        public isAuthenticated: boolean | null = null,   
        public token: string | null = null,   
        public licenseError: boolean | null = null,   
        public licenseErrorMessage: string | null = null,   
        public expires: Date | null = null,   
        public isExpired: boolean | null = null,   
        public mfastatus: MFAStatus | null = null,   
      ) { }
}

export class SecurityContextV1Model {
    constructor(  
        // Inherited properties 
        
      
        //Properties
        
        public user: UserSecV1Model | null = null,   
      ) { }
}

export class UserSecV1Model {
    constructor(  
        // Inherited properties 
        
      
        //Properties
        
        public currentUserId: number | null = null,   
        public currentUserIdForAudit: number | null = null,   
        public currentAccessGuid: string | null = null,   
        public name: string | null = null,   
        public email: string | null = null,   
        public permissions: APIPermissionModel[] | null = null,   
        public accountIds: number[] | null = null,   
        public accountCodes: string[] | null = null,   
        public currentAccountId: number | null = null,   
        public currentAccountCode: string | null = null,   
        public isGlobal: boolean | null = null,   
      ) { }
}

export class APIPermissionModel {
    constructor(  
        // Inherited properties 
        
      
        //Properties
        
        public apiPermissionId: number | null = null,   
        public apiPermission: string | null = null,   
      ) { }
}

export class AccountLicenseV1Model {
    constructor(  
        // Inherited properties 
        
      
        //Properties
        
        public accountId: number | null = null,   
        public name: string | null = null,   
        public licensesScoping: number | null = null,   
        public licensesWriting: number | null = null,   
        public licensesExecution: number | null = null,   
        public licensesScheduling: number | null = null,   
        public licensesAnalytics: number | null = null,   
      ) { }
}

export class AccountLicenseUpdateV1Model {
    constructor(  
        // Inherited properties 
        
      
        //Properties
        
        public accountId: number | null = null,   
        public licensesScoping: number | null = null,   
        public licensesWriting: number | null = null,   
        public licensesExecution: number | null = null,   
        public licensesScheduling: number | null = null,   
        public licensesAnalytics: number | null = null,   
      ) { }
}

export class AccountLicenseInUseV1Model {
    constructor(  
        // Inherited properties 
        
      
        //Properties
        
        public userId: number | null = null,   
        public name: string | null = null,   
        public totalScoping: number | null = null,   
        public totalWriting: number | null = null,   
        public totalExecution: number | null = null,   
        public totalScheduling: number | null = null,   
        public totalAnalytics: number | null = null,   
      ) { }
}



export enum MFAStatus
    { 
       SetupRequired = 1,
       NeedsAuthenticated = 2,
       Authenticated = 3,
    }



/* 
     Debug Info:
     PrintDebugInfo - put a dollar sign in front of Print to enable this 
*/

