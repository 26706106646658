import { Component, OnInit } from "@angular/core";
import { FormBuilder, Validators } from "@angular/forms";
import { AuthService } from "../../core/services/auth.service";
import { UserSettingsService } from "../../core/services/user-settings.service";
import { Router } from "@angular/router";
import { environment } from "src/environments/environment";
import { ResetPasswordComponent } from "../../shared/reset-password/reset-password.component";
import { NgbModal } from "@ng-bootstrap/ng-bootstrap";

@Component({
  selector: "app-login",
  templateUrl: "./login.component.html",
  styleUrls: ["./login.component.scss"],
})
export class LoginComponent implements OnInit {
  loginForm = this.formBuilder.group({
    login_username: ["", [Validators.required]],
    login_password: ["", [Validators.required]],
  });
  isLocalHost: boolean = environment.apiRoot.includes("localhost");
  isPasswordVisible: boolean = false;
  login_error: string = "";

  constructor(
    private formBuilder: FormBuilder,
    private modalService: NgbModal,
    private authService: AuthService,
    private userSettingsService: UserSettingsService,
    private router: Router
  ) {}

  ngOnInit(): void {
    if (this.authService.isLoggedIn()) {
      this.router.navigate([this.authService.getHome()]);
    }

    this.loginForm.controls.login_password.valueChanges.subscribe((value) => {
      if (value == "") {
        this.isPasswordVisible = true;
      } else {
        this.isPasswordVisible = false;
      }
    });
  }

  async submit() {
    this.login_error = "";

    if (this.loginForm.invalid) {
      this.login_error = "Enter email and password";
      return;
    }

    let loginUsername = this.loginForm.controls["login_username"].value;
    let loginPassword = this.loginForm.controls["login_password"].value;
    if (loginUsername != null && loginPassword != null) {
      await this.authService.login(loginUsername, loginPassword).then(
        () => {},
        (error: any) => {
          console.log(error);
          if (error.error.licenseError) {
            this.login_error = error.error.licenseErrorMessage;
            return;
          } else {
            this.login_error = "You've entered wrong username or password.";
          }
        }
      );
    }

    if (this.authService.isLoggedIn()) {
      this.userSettingsService.getUserSettings();

      if (this.authService.redirectUrl != null)
        this.router.navigateByUrl(this.authService.redirectUrl.replace(environment.clientRoot, ""));
      else this.router.navigate([this.authService.getHome()]);
    }
  }

  onHelpClicked(): void {
    this.router.navigateByUrl("/contact");
  }

  onForgotPassword(): void {
    const modalRef = this.modalService.open(ResetPasswordComponent, {
      size: "lg",
    });
    modalRef.componentInstance.email = this.loginForm.controls["login_username"].value;
  }

  togglePasswordVisibility(): void {
    this.isPasswordVisible = !this.isPasswordVisible;
  }
}
