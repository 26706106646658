// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.e-upload .e-file-select-wrap .e-btn,
.e-upload .e-upload-actions .e-btn,
.e-bigger.e-small .e-upload .e-file-select-wrap .e-btn,
.e-bigger.e-small .e-upload .e-upload-actions .e-btn {
  height: 40px;
  background-color: #26883c;
  border-color: #28a745;
  color: #fff;
  border-radius: 10px;
}

.e-upload .e-file-select-wrap .e-btn:hover,
.e-upload .e-upload-actions .e-btn:hover,
.e-bigger.e-small .e-upload .e-file-select-wrap .e-btn:hover,
.e-bigger.e-small .e-upload .e-upload-actions .e-btn:hover {
  background-color: #1f9e3a;
  border-color: #218838;
  color: #fff;
}

.e-upload .e-file-select-wrap .e-btn:focus,
.e-upload .e-upload-actions .e-btn:focus,
.e-bigger.e-small .e-upload .e-file-select-wrap .e-btn:focus,
.e-bigger.e-small .e-upload .e-upload-actions .e-btn:focus {
  background-color: #1e7e34;
  border-color: #1e7e34;
  color: #fff;
}

.e-upload.e-control-wrapper,
.e-bigger.e-small .e-upload.e-control-wrapper {
  border: 0px dashed;
}

.e-upload.e-control-wrapper:hover,
.e-bigger.e-small .e-upload.e-control-wrapper:hover {
  border-color: black;
}

.drag-over {
  background-color: lightgray !important;
  border: 2px dashed #1e7e34;
}

.drop-area {
  padding: 10%;
  border: 2px dashed #007bff;
  background-color: #ffffff;
  color: #000000;
}`, "",{"version":3,"sources":["webpack://./src/app/modules/shared/excel-import-modal/excel-import-modal.component.scss"],"names":[],"mappings":"AAAA;;;;EAIE,YAAA;EACA,yBAAA;EACA,qBAAA;EACA,WAAA;EACA,mBAAA;AACF;;AAEA;;;;EAIE,yBAAA;EACA,qBAAA;EACA,WAAA;AACF;;AAEA;;;;EAIE,yBAAA;EACA,qBAAA;EACA,WAAA;AACF;;AAEA;;EAEE,kBAAA;AACF;;AAEA;;EAEI,mBAAA;AACJ;;AAEA;EACE,sCAAA;EACA,0BAAA;AACF;;AAEA;EACE,YAAA;EACA,0BAAA;EACA,yBAAA;EACA,cAAA;AACF","sourcesContent":[".e-upload .e-file-select-wrap .e-btn,\n.e-upload .e-upload-actions .e-btn,\n.e-bigger.e-small .e-upload .e-file-select-wrap .e-btn,\n.e-bigger.e-small .e-upload .e-upload-actions .e-btn {\n  height: 40px;\n  background-color: #26883c;\n  border-color: #28a745;\n  color: #fff;\n  border-radius: 10px; \n}\n\n.e-upload .e-file-select-wrap .e-btn:hover,\n.e-upload .e-upload-actions .e-btn:hover,\n.e-bigger.e-small .e-upload .e-file-select-wrap .e-btn:hover,\n.e-bigger.e-small .e-upload .e-upload-actions .e-btn:hover {\n  background-color: #1f9e3a;\n  border-color: #218838;\n  color: #fff;\n}\n\n.e-upload .e-file-select-wrap .e-btn:focus,\n.e-upload .e-upload-actions .e-btn:focus,\n.e-bigger.e-small .e-upload .e-file-select-wrap .e-btn:focus,\n.e-bigger.e-small .e-upload .e-upload-actions .e-btn:focus {\n  background-color: #1e7e34;\n  border-color: #1e7e34;\n  color: #fff;\n}\n\n.e-upload.e-control-wrapper,\n.e-bigger.e-small .e-upload.e-control-wrapper {\n  border: 0px dashed;\n}\n\n.e-upload.e-control-wrapper:hover,\n.e-bigger.e-small .e-upload.e-control-wrapper:hover {\n    border-color: black;\n}\n\n.drag-over  {\n  background-color: lightgray !important; \n  border: 2px dashed #1e7e34;\n}\n\n.drop-area{\n  padding: 10%;\n  border: 2px dashed #007bff; \n  background-color: #ffffff; \n  color: #000000; \n}\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
