import { Component, Input } from "@angular/core";

@Component({
  selector: "app-alert",
  templateUrl: "./alert.component.html",
  styleUrls: ["./alert.component.scss"],
})
export class AlertComponent {
  @Input() alertColor: string = "primary";
  @Input() alertHeading: string | null = null;
  @Input() alertText: string | null = null;
}
