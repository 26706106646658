export enum permissionType {
  Authenticated = 1,
  CreateAccounts = 2,
  ManageAccounts = 3,
  ManageUsers = 4,
  ManageParts = 5,
  ManageTools = 6,
  ManageHoldCodes = 7,
  ManageDelayCodes = 8,
  ManageLaborResources = 9,
  ManageHazards = 10,
  ManagePermits = 11,
  ManageFigures = 12,
  ManageProcedures = 13,
  ManageEquipment = 14,
  ExecuteProcedures = 15,
  ViewAccounts = 16,
  ViewUsers = 17,
  ViewParts = 18,
  ViewTools = 19,
  ViewHoldCodes = 20,
  ViewDelayCodes = 21,
  ViewLaborResources = 22,
  ViewHazards = 23,
  ViewPermits = 24,
  ViewFigures = 25,
  ViewProcedures = 26,
  ViewEquipment = 27,
  ApproveProcedureChanges = 28,
  ApproveProcedure = 29,
  ManageVideos = 30,
  Scoping = 31,
  Scheduling = 32,
  Analytics = 33,
  ApproveCompletedWork = 34,
  NotCurrentlyUsed = 35,
}

export enum UserSettingsBaseEnum {
  user_list = "user.list",
  master_vehicle_list = "master.vehicle.list",
}

export enum WhereToUpload {}

export enum DocumentTypeEnum {
  Figures = 0,
  Permits = 1,
  Logo = 2,
  Execution = 3,
}

export enum ImportType {
  Tools = 1,
  Parts = 2,
  Equipments = 3,
  QRCodes = 4,
  WorkOrders = 5,
}

export enum ScreenLayout {
  Centered = 1,
  SideBar = 2,
  FullScreen = 3,
}
