import { Component, EventEmitter, Input, Output } from "@angular/core";

@Component({
  selector: "app-button",
  templateUrl: "./button.component.html",
  styleUrls: ["./button.component.scss"],
})
export class ButtonComponent {
  @Input() buttonText: string = "";
  @Input() buttonColor: string = "primary";
  @Input() isOutline: boolean = false;
  @Input() showIcon: boolean = false;
  @Input() iconClass: string = "";
  @Input() isDisabled: boolean = false;
  @Input() buttonSize: "lg" | "sm" | null = null;
  @Input() customCssClass: string | null = null;
  @Output() public onClickedEvent = new EventEmitter();
  computedCssClass: string = "btn";
  ngOnInit(): void {
    if (this.isOutline) {
      this.computedCssClass = `${this.computedCssClass} btn-outline-${this.buttonColor}`;
    } else {
      this.computedCssClass = `${this.computedCssClass} btn-${this.buttonColor}`;
    }

    if (this.buttonSize) {
      this.computedCssClass = `${this.computedCssClass} btn-${this.buttonSize}`;
    }

    if (this.customCssClass) {
      this.computedCssClass = `${this.computedCssClass} ${this.customCssClass}`;
    }
  }

  onClicked(): void {
    this.onClickedEvent.emit();
  }
}
