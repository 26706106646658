import { Component, EventEmitter, Input, Output, ViewChild } from "@angular/core";
import { NgbActiveModal, NgbModal } from "@ng-bootstrap/ng-bootstrap";
import {
  CustomValueEventArgs,
  MultiSelectComponent,
} from "@syncfusion/ej2-angular-dropdowns";
import { ToastrService } from "ngx-toastr";
import { TagsV1Model } from "src/app/api/Document.service";
import { PartsInsertV1Model, PartsService } from "src/app/api/Parts.service";
import { TagsInsertV1Model, TagsService } from "src/app/api/Tags.service";
import { FormRepoService } from "src/app/modules/shared/services/form-repo.service";
import { TagsGridComponent } from "src/app/modules/shared/tags-grid/tags-grid.component";
import { cloneDeep } from "lodash";

@Component({
  selector: "app-new-parts-modal",
  templateUrl: "./new-parts-modal.component.html",
  styleUrls: ["./new-parts-modal.component.scss"],
})
export class NewPartsModalComponent {
  @Output() public submitCompletedEvent = new EventEmitter();
  newPartsForm = this.formRepoService.newPartsForm();
  @Input() isLibrary: boolean = true;

  //Multiselect
  hideSelector: boolean = true;
  tagOptions: TagsV1Model[] = [];
  selectedTags: TagsV1Model[] = [];
  selectedTagIds: number[] = [];
  tagsPlaceholder = "Select tags from list...";
  @ViewChild("tagsDropdown")
  public accountDropdown!: MultiSelectComponent;
  public accountDropdownFields: Object = {
    text: "tagName",
    value: "tagId",
  };

  constructor(
    private partService: PartsService,
    private tagsService: TagsService,
    public activeModal: NgbActiveModal,
    private toastService: ToastrService,
    private modalService: NgbModal,
    private formRepoService: FormRepoService
  ) {}

  ngOnInit(): void {
    this.loadAccountTags();
  }

  loadAccountTags(): void {
    this.tagsService.getAccountTags().subscribe((data) => {
      this.tagOptions = data;

      this.hideSelector = false;
    });
  }

  onSubmit(): void {
    let model = new PartsInsertV1Model();

    model.name = this.newPartsForm.controls["name"].value;
    model.shortName = this.newPartsForm.controls["shortName"].value;
    model.idNumber = this.newPartsForm.controls["idNumber"].value;
    model.uom = this.newPartsForm.controls["uom"].value;
    if (this.newPartsForm.controls["isActive"].value == "true") {
      model.isActive = true;
    } else {
      model.isActive = false;
    }
    model.partTags = this.selectedTags;

    this.partService.insert(model).subscribe((data) => {
      this.toastService.success(model.name + " created");
      this.submitCompletedEvent.emit(data.identityId);
      this.activeModal.close();
    },
    (error) => {
      
    });
  }

  onCancel(): void {
    this.activeModal.close();
  }

  //************ Tag stuff *******************

  onManageTagsClicked(): void {
    const modalRef = this.modalService.open(TagsGridComponent, {
      size: "xl",
    });
    modalRef.componentInstance.deleteCompletedEvent.subscribe(() => {
      this.selectedTagIds = [];
      this.selectedTags = [];
      this.loadAccountTags();
    });
    modalRef.closed.subscribe(() => {
      this.loadAccountTags();
    });
  }

  onSelectedTag(args: any): void {
    //Adding tag that exists
    if (this.tagOptions.length > 0) {
      if (args.itemData != null && args.itemData != undefined) {
        const selectedTag = new TagsV1Model();
        Object.assign(selectedTag, args.itemData);
        this.selectedTags.push(selectedTag);
      }
    }
    //Adding new one when no tags exist to prevent errors
    else {
      let newTag = new TagsInsertV1Model();
      newTag.tagName = args.itemData.tagName;
      this.tagsService.insert(newTag).subscribe((data) => {
        console.log("Tag created");
        const selectedTag = new TagsV1Model();
        selectedTag.tagName = newTag.tagName;
        selectedTag.tagId = data.identityId;
        this.selectedTags.push(selectedTag);
        this.loadAccountTags();
      });
    }
  }

  onRemoveTag(args: any): void {
    const tagToRemove = cloneDeep(args.itemData);
    this.selectedTags = this.selectedTags.filter(
      (tag) => tag.tagId !== tagToRemove.tagId
    );
  }
}
