 
   
   import { Injectable } from '@angular/core';
   import { HttpClient, HttpHeaders, HttpErrorResponse } from '@angular/common/http';

   import { Observable, of, throwError  } from 'rxjs';

   import { environment } from '../../environments/environment';
   //import { LoggerService } from '../../modules/core/logger.service'
   import { GenericSearchResultV1Model } from '../../app/models/GenericSearchResultV1Model';
   import { PageableListV1Model } from '../../app/models/PageableListV1Model';

   const httpOptions = {
     headers: new HttpHeaders({ 'Content-Type': 'application/json' }),
     withCredentials: true
   };

   const httpOptions_Handle400InComponent = {
     headers: new HttpHeaders({ 'Content-Type': 'application/json', 'I400Local': '' }),
     withCredentials: true
   }
    
   @Injectable({
     providedIn: 'root'
   })
   export class ToolsService {

   
        private classVersion = "1.0";
        
         
      constructor(private http: HttpClient) { }
      

            


                  
        
          
              search(model: ToolsSearchV1Model,  handle400Locally: boolean = false) : Observable<ToolsV1Model[]>  { 
              let version = this.classVersion;
              return this.http.post<any>(environment.apiRoot + `api/v${version}/tools/Search`,model, handle400Locally ? httpOptions_Handle400InComponent : httpOptions ) //p => p.Attributes.Count > 0 ----only adds FromBody     
            };
          
      
        
          
              searchScopingTool(model: ToolsScopingSearchV1Model,  handle400Locally: boolean = false) : Observable<ToolsV1Model[]>  { 
              let version = this.classVersion;
              return this.http.post<any>(environment.apiRoot + `api/v${version}/tools/SearchScopingTool`,model, handle400Locally ? httpOptions_Handle400InComponent : httpOptions ) //p => p.Attributes.Count > 0 ----only adds FromBody     
            };
          
      
        
          
              getToolsByID(toolId: number,  handle400Locally: boolean = false) : Observable<ToolsV1Model>  { 
              let version = this.classVersion;
              return this.http.post<any>(environment.apiRoot + `api/v${version}/tools/GetById`,toolId, handle400Locally ? httpOptions_Handle400InComponent : httpOptions ) //p => p.Attributes.Count > 0 ----only adds FromBody     
            };
          
      
        
          
              getProcedureStepTools(procedureTaskStepId: number,  handle400Locally: boolean = false) : Observable<ProcedureStepToolsV1Model[]>  { 
              let version = this.classVersion;
              return this.http.post<any>(environment.apiRoot + `api/v${version}/tools/GetProcedureStepTools`,procedureTaskStepId, handle400Locally ? httpOptions_Handle400InComponent : httpOptions ) //p => p.Attributes.Count > 0 ----only adds FromBody     
            };
          
      
        
          
              getScopingTools(scopeDocumentId: number,  handle400Locally: boolean = false) : Observable<ScopingToolV1Model[]>  { 
              let version = this.classVersion;
              return this.http.post<any>(environment.apiRoot + `api/v${version}/tools/GetScopingTools`,scopeDocumentId, handle400Locally ? httpOptions_Handle400InComponent : httpOptions ) //p => p.Attributes.Count > 0 ----only adds FromBody     
            };
          
      
        
          
              searchProcedureStepScopeTools(model: ToolsScopingSearchV1Model,  handle400Locally: boolean = false) : Observable<ToolsV1Model[]>  { 
              let version = this.classVersion;
              return this.http.post<any>(environment.apiRoot + `api/v${version}/tools/SearchProcedureStepScopeTools`,model, handle400Locally ? httpOptions_Handle400InComponent : httpOptions ) //p => p.Attributes.Count > 0 ----only adds FromBody     
            };
          
      
        
          
              getRevisionTools(procedureRevisionId: number,  handle400Locally: boolean = false) : Observable<RevisionToolV1Model[]>  { 
              let version = this.classVersion;
              return this.http.post<any>(environment.apiRoot + `api/v${version}/tools/GetRevisionTools`,procedureRevisionId, handle400Locally ? httpOptions_Handle400InComponent : httpOptions ) //p => p.Attributes.Count > 0 ----only adds FromBody     
            };
          
      
        
          
              insert(model: ToolsInsertV1Model,  handle400Locally: boolean = false) : Observable<InsertResponseV1Model>  { 
              let version = this.classVersion;
              return this.http.post<any>(environment.apiRoot + `api/v${version}/tools/Insert`,model, handle400Locally ? httpOptions_Handle400InComponent : httpOptions ) //p => p.Attributes.Count > 0 ----only adds FromBody     
            };
          
      
        
          
              insertProcedureTool(model: ProcedureStepToolInsertV1Model,  handle400Locally: boolean = false) : Observable<InsertResponseV1Model>  { 
              let version = this.classVersion;
              return this.http.post<any>(environment.apiRoot + `api/v${version}/tools/InsertProcedureTool`,model, handle400Locally ? httpOptions_Handle400InComponent : httpOptions ) //p => p.Attributes.Count > 0 ----only adds FromBody     
            };
          
      
        
          
              insertScopingTool(model: ScopingToolInsertV1Model,  handle400Locally: boolean = false) : Observable<InsertResponseV1Model>  { 
              let version = this.classVersion;
              return this.http.post<any>(environment.apiRoot + `api/v${version}/tools/InsertScopingTool`,model, handle400Locally ? httpOptions_Handle400InComponent : httpOptions ) //p => p.Attributes.Count > 0 ----only adds FromBody     
            };
          
      
        
          
              updateProcedureTool(model: ProcedureStepToolUpdateV1Model,  handle400Locally: boolean = false) : Observable<UpdateResponseV1Model>  { 
              let version = this.classVersion;
              return this.http.post<any>(environment.apiRoot + `api/v${version}/tools/UpdateProcedureTool`,model, handle400Locally ? httpOptions_Handle400InComponent : httpOptions ) //p => p.Attributes.Count > 0 ----only adds FromBody     
            };
          
      
        
          
              updateScopingTool(model: ScopingToolUpdateV1Model,  handle400Locally: boolean = false) : Observable<UpdateResponseV1Model>  { 
              let version = this.classVersion;
              return this.http.post<any>(environment.apiRoot + `api/v${version}/tools/UpdateScopingTool`,model, handle400Locally ? httpOptions_Handle400InComponent : httpOptions ) //p => p.Attributes.Count > 0 ----only adds FromBody     
            };
          
      
        
          
              update(model: ToolsUpdateV1Model,  handle400Locally: boolean = false) : Observable<UpdateResponseV1Model>  { 
              let version = this.classVersion;
              return this.http.post<any>(environment.apiRoot + `api/v${version}/tools/Update`,model, handle400Locally ? httpOptions_Handle400InComponent : httpOptions ) //p => p.Attributes.Count > 0 ----only adds FromBody     
            };
          
      
        
          
              delete(toolId: number,  handle400Locally: boolean = false) : Observable<CommonResponseV1Model>  { 
              let version = this.classVersion;
              return this.http.post<any>(environment.apiRoot + `api/v${version}/tools/Delete`,toolId, handle400Locally ? httpOptions_Handle400InComponent : httpOptions ) //p => p.Attributes.Count > 0 ----only adds FromBody     
            };
          
      
        
          
              removeProcedureStepTool(procedureTaskStepToolId: number,  handle400Locally: boolean = false) : Observable<CommonResponseV1Model>  { 
              let version = this.classVersion;
              return this.http.post<any>(environment.apiRoot + `api/v${version}/tools/RemoveProcedureStepTool`,procedureTaskStepToolId, handle400Locally ? httpOptions_Handle400InComponent : httpOptions ) //p => p.Attributes.Count > 0 ----only adds FromBody     
            };
          
      
        
          
              removeScopingTool(scopeDocumentToolId: number,  handle400Locally: boolean = false) : Observable<CommonResponseV1Model>  { 
              let version = this.classVersion;
              return this.http.post<any>(environment.apiRoot + `api/v${version}/tools/RemoveScopingTool`,scopeDocumentToolId, handle400Locally ? httpOptions_Handle400InComponent : httpOptions ) //p => p.Attributes.Count > 0 ----only adds FromBody     
            };
          
      
        
          
              toolInactive(toolId: number,  handle400Locally: boolean = false) : Observable<UpdateResponseV1Model>  { 
              let version = this.classVersion;
              return this.http.post<any>(environment.apiRoot + `api/v${version}/tools/ToolInactive`,toolId, handle400Locally ? httpOptions_Handle400InComponent : httpOptions ) //p => p.Attributes.Count > 0 ----only adds FromBody     
            };
          
      

                        

                  
}

export class ToolsV1Model {
    constructor(  
        // Inherited properties 
        
      
        //Properties
        
        public toolId: number | null = null,   
        public name: string | null = null,   
        public shortName: string | null = null,   
        public toolNumber: string | null = null,   
        public isActive: boolean | null = null,   
        public createdByUserId: number | null = null,   
        public createdDate: Date | null = null,   
        public updatedByUserId: number | null = null,   
        public updatedDate: Date | null = null,   
        public createdByUserName: string | null = null,   
        public updatedByUserName: string | null = null,   
        public accountId: number | null = null,   
      ) { }
}

export class ToolsSearchV1Model {
    constructor(  
        // Inherited properties 
        
      
        //Properties
        
        public searchTerm: string | null = null,   
        public procedureRevisionId: number | null = null,   
        public procedureTaskStepId: number | null = null,   
      ) { }
}

export class ToolsScopingSearchV1Model {
    constructor(  
        // Inherited properties 
        
      
        //Properties
        
        public searchTerm: string | null = null,   
        public scopeDocumentId: number | null = null,   
        public procedureTaskStepId: number | null = null,   
      ) { }
}

export class ProcedureStepToolsV1Model {
    constructor(  
        // Inherited properties 
        
      
        //Properties
        
        public procedureTaskStepToolId: number | null = null,   
        public procedureTaskStepId: number | null = null,   
        public toolId: number | null = null,   
        public quantity: number | null = null,   
        public name: string | null = null,   
        public shortName: string | null = null,   
        public toolNumber: string | null = null,   
        public createdDate: Date | null = null,   
        public updatedDate: Date | null = null,   
        public accountId: number | null = null,   
      ) { }
}

export class ScopingToolV1Model {
    constructor(  
        // Inherited properties 
        
      
        //Properties
        
        public scopeDocumentToolId: number | null = null,   
        public scopeDocumentId: number | null = null,   
        public toolId: number | null = null,   
        public quantity: number | null = null,   
        public name: string | null = null,   
        public shortName: string | null = null,   
        public toolNumber: string | null = null,   
        public createdDate: Date | null = null,   
        public updatedDate: Date | null = null,   
        public accountId: number | null = null,   
      ) { }
}

export class RevisionToolV1Model {
    constructor(  
        // Inherited properties 
        
      
        //Properties
        
        public toolId: number | null = null,   
        public name: string | null = null,   
        public shortName: string | null = null,   
        public toolNumber: string | null = null,   
        public quantity: number | null = null,   
      ) { }
}

export class InsertResponseV1Model  {
    constructor(  
        // Inherited properties 
        
        public success: boolean | null = null,
        public message: string | null = null,
      
        //Properties
        
        public identityId: number | null = null,   
        public identityIds: number[] | null = null,   
      ) { }
}

export class ToolsInsertV1Model {
    constructor(  
        // Inherited properties 
        
      
        //Properties
        
        public name: string | null = null,   
        public shortName: string | null = null,   
        public toolNumber: string | null = null,   
        public isActive: boolean | null = null,   
      ) { }
}

export class ProcedureStepToolInsertV1Model {
    constructor(  
        // Inherited properties 
        
      
        //Properties
        
        public procedureTaskStepId: number | null = null,   
        public toolId: number | null = null,   
        public quantity: number | null = null,   
      ) { }
}

export class ScopingToolInsertV1Model {
    constructor(  
        // Inherited properties 
        
      
        //Properties
        
        public scopeDocumentId: number | null = null,   
        public toolId: number | null = null,   
        public quantity: number | null = null,   
      ) { }
}

export class UpdateResponseV1Model {
    constructor(  
        // Inherited properties 
        
      
        //Properties
        
        public totalRowsUpdated: number | null = null,   
      ) { }
}

export class ProcedureStepToolUpdateV1Model {
    constructor(  
        // Inherited properties 
        
      
        //Properties
        
        public procedureTaskStepToolId: number | null = null,   
        public quantity: number | null = null,   
      ) { }
}

export class ScopingToolUpdateV1Model {
    constructor(  
        // Inherited properties 
        
      
        //Properties
        
        public scopeDocumentToolId: number | null = null,   
        public quantity: number | null = null,   
      ) { }
}

export class ToolsUpdateV1Model {
    constructor(  
        // Inherited properties 
        
      
        //Properties
        
        public toolId: number | null = null,   
        public name: string | null = null,   
        public shortName: string | null = null,   
        public toolNumber: string | null = null,   
        public isActive: boolean | null = null,   
      ) { }
}

export class CommonResponseV1Model {
    constructor(  
        // Inherited properties 
        
      
        //Properties
        
        public success: boolean | null = null,   
        public message: string | null = null,   
      ) { }
}





/* 
     Debug Info:
     PrintDebugInfo - put a dollar sign in front of Print to enable this 
*/

