import { Pipe, PipeTransform } from "@angular/core";
import {
  ExecutionLaneNodeV1Model,
  ProcedureStepV1Model,
} from "../../../api/Execution.service";

@Pipe({
  name: "executionIconClass",
})
export class ExecutionIconClassPipe implements PipeTransform {
  transform(
    node: ExecutionLaneNodeV1Model,
    currentStep: ProcedureStepV1Model | null,
    stepInstance: number | null
  ): string {
    //if it's the current step
    if (
      currentStep &&
      node.procedureTaskStepId === currentStep.procedureTaskStepId &&
      stepInstance == node.procedureTaskStepInstance
    ) {
      return node.decisionNode
        ? "fas fa-star progress-step-current"
        : "fas fa-star progress-step-current";
    }
    //if it's an end step
    else if (node.endStep == true) {
      return node.decisionNode ? "fas fa-square" : "fas fa-square";
    }
    //If the step is not current but inprogress
    else if (node.executionStatus == 2 || node.executionStatus == 3) {
      return node.decisionNode
        ? "fa-solid fa-circle-half-stroke inprogress-step"
        : "fa-solid fa-circle-half-stroke inprogress-step";
    } else {
      return node.decisionNode ? "fas fa-diamond" : "fas fa-circle";
    }
  }
}
