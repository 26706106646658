import { Component, EventEmitter, Input, Output } from "@angular/core";
import { NgbActiveModal } from "@ng-bootstrap/ng-bootstrap";
import { ToastrService } from "ngx-toastr";
import {
  HazardsInsertV1Model,
  HazardsService,
} from "src/app/api/Hazards.service";

import { FormRepoService } from "src/app/modules/shared/services/form-repo.service";

@Component({
  selector: "app-new-harzard-modal",
  templateUrl: "./new-harzard-modal.component.html",
  styleUrls: ["./new-harzard-modal.component.scss"],
})
export class NewHarzardModalComponent {
  @Input() isLibrary: boolean = true;
  @Output() public submitCompletedEvent = new EventEmitter();
  newHarzardForm = this.formRepoService.newHazardForm();
  customValue: boolean = false;
  hazardIcon: string | null = null;
  hazardIcons: string[] = [
    "Biohazard",
    "Chemical",
    "Confined Space",
    "Contamination",
    "Corrosive",
    "Electrical",
    "Fall",
    "Flammable",
    "Heavy Lift",
    "Holds",
    "Hot Work",
    "Inhalation",
    "Poison",
    "Pressure",
    "Radioactive",
    "Roof Access",
    "Temperature",
  ];
  constructor(
    private harzardService: HazardsService,
    public activeModal: NgbActiveModal,
    private toastService: ToastrService,
    private formRepoService: FormRepoService
  ) {}

  ngOnInit(): void {}

  onCustomValueEntered(): void {
    this.customValue = true;
  }

  onDefaultSelected(): void {
    this.customValue = false;
  }

  onSubmit(): void {
    let model = new HazardsInsertV1Model();

    model.hazardClass = this.hazardIcon;
    model.description = this.newHarzardForm.controls["description"].value;
    if (this.newHarzardForm.controls["isActive"].value == "true") {
      model.isActive = true;
    } else {
      model.isActive = false;
    }
    if (this.customValue == false) {
      model.hazardIcon = this.hazardIcon;
    } else {
      model.hazardIcon = "Danger";
    }
    this.harzardService.insert(model).subscribe((data) => {
      this.toastService.success(model.hazardClass + " created");
      this.submitCompletedEvent.emit();
      this.activeModal.close();
    });
  }

  onCancel(): void {
    this.activeModal.close();
  }
}
