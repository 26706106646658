import { Component, EventEmitter, Input, Output } from "@angular/core";
import { FormBuilder, Validators } from "@angular/forms";
import { NgbActiveModal } from "@ng-bootstrap/ng-bootstrap";
import { ToastrService } from "ngx-toastr";
import { AccountService } from "src/app/api/Account.service";
import {
  ProceduresInsertV1Model,
  ProceduresService,
} from "src/app/api/Procedures.service";
import { ScopingService, ScopingV1Model } from "src/app/api/Scoping.service";

import { AuthService } from "src/app/modules/core/services/auth.service";
import { FormRepoService } from "src/app/modules/shared/services/form-repo.service";

@Component({
  selector: "app-new-procedure-modal",
  templateUrl: "./new-procedure-modal.component.html",
  styleUrls: ["./new-procedure-modal.component.scss"],
})
export class NewProcedureModalComponent {
  @Output() public submitCompletedEvent = new EventEmitter();
  newProcedureForm = this.formRepoService.newProcedureForm();
  scopeDocuments: ScopingV1Model[] = [];
  constructor(
    private procedureService: ProceduresService,
    public activeModal: NgbActiveModal,
    private toastService: ToastrService,
    private formRepoService: FormRepoService,
    private scopeService: ScopingService,
  ) {}

  ngOnInit(): void {
    this.scopeService.getScopingDocument().subscribe((data) => {
      this.scopeDocuments = data;
    })
  }

  onSubmit(): void {
    let model = new ProceduresInsertV1Model();
    Object.assign(model, this.newProcedureForm.value);
    model.name = this.newProcedureForm.controls["name"].value;
    model.procedureNumber =
      this.newProcedureForm.controls["procedureNumber"].value;

    this.procedureService.insert(model).subscribe((data) => {
      this.toastService.success(model.name + " created");
      this.submitCompletedEvent.emit();
      this.activeModal.close();
    });
  }

  onCancel(): void {
    this.activeModal.close();
  }
}
