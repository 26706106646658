import { Component, EventEmitter, Output } from "@angular/core";
import { NgbActiveModal } from "@ng-bootstrap/ng-bootstrap";
import { ProcedureStepMinV1Model, ProcedureTaskStepsService } from "../../../api/ProcedureTaskSteps.service";
import { FormBuilder, Validators } from "@angular/forms";
import { ToastrService } from "ngx-toastr";
import { WritingService } from "../../writing/writing.service";
import { ProceduresService, ProceduresV1Model } from "../../../api/Procedures.service";
import { Observable, distinctUntilChanged, map, of, switchMap, tap } from "rxjs";
import { SelectedOtherProcedureStepInfo } from "../step-select/selected-other-procedure-step-info";

@Component({
  selector: "app-select-step-from-other-procedure-modal",
  templateUrl: "./select-step-from-other-procedure-modal.component.html",
  styleUrls: ["./select-step-from-other-procedure-modal.component.scss"],
})
export class SelectStepFromOtherProcedureModalComponent {
  @Output() public submitCompletedEvent = new EventEmitter<SelectedOtherProcedureStepInfo | null>();
  procedures: ProceduresV1Model[] = [];
  steps: ProcedureStepMinV1Model[] = [];
  isProceduresLoading: boolean = true;
  isStepsLoading: boolean = false;
  selectedProcedureId: number | null = null;
  selectedProcedureMaxRevisionId: number | null = null;
  stepFromOtherProcedureForm = this.formBuilder.group({
    procedureId: ["", [Validators.required]],
    procedureTaskStepId: ["", [Validators.required]],
  });
  isDraftChanged!: Observable<boolean>;
  constructor(
    public activeModal: NgbActiveModal,
    private procedureTaskStepService: ProcedureTaskStepsService,
    private procedureService: ProceduresService,
    private formBuilder: FormBuilder,
    private toastService: ToastrService,
    private writingService: WritingService
  ) {}

  ngOnInit(): void {
    this.isDraftChanged = this.writingService.isDraft$.pipe(tap(() => {}));

    this.loadProcedures();

    this.stepFromOtherProcedureForm.controls["procedureId"].valueChanges
      .pipe(
        distinctUntilChanged(),
        switchMap((value) => {
          this.isStepsLoading = true;
          if (value != null) {
            this.selectedProcedureId = parseInt(value);
            this.selectedProcedureMaxRevisionId = this.procedures.find(
              (p) => p.procedureId == this.selectedProcedureId
            )!.procedureRevisionId;
            let stepsArray = this.procedureTaskStepService.getAllStepsForProcedureRevision(
              this.selectedProcedureMaxRevisionId!
            );

            let filteredSteps = stepsArray.pipe(
              map((stepsArray) => stepsArray.filter((step) => step.prerequisiteStep == false))
            );
            return filteredSteps;
          } else {
            return of(null);
          }
        })
      )
      .subscribe((data: any) => {
        this.steps = data;
        this.steps.filter((step) => step.prerequisiteStep == false);
        this.isStepsLoading = false;
      });

    // this.stepFromOtherProcedureForm.controls["procedureId"].valueChanges.subscribe(value => {
    //   if (value != null){
    //     this.selectedProcedureId = parseInt(value);
    //     this.selectedProcedureMaxRevisionId = this.procedures.find(p => p.procedureId == this.selectedProcedureId)!.procedureRevisionId;
    //
    //   } else {
    //     this.selectedProcedureId = null;
    //   }
    // });
  }

  loadProcedures(): void {
    this.isProceduresLoading = true;
    this.procedureService.getAll().subscribe((data) => {
      this.procedures = data;
      this.isProceduresLoading = false;

      this.stepFromOtherProcedureForm.controls["procedureId"].setValue(this.writingService.procedureId$.value.toString());
    });
  }

  onSubmit(): void {
    if (this.stepFromOtherProcedureForm.invalid) {
      this.toastService.error("Ensure that form is filled out properly");
      return;
    }
    let stepId = parseInt(this.stepFromOtherProcedureForm.controls.procedureTaskStepId.value!);
    let procedureId = parseInt(this.stepFromOtherProcedureForm.controls.procedureId.value!);
    let step = this.steps.find((s) => s.procedureTaskStepId == stepId);
    let procedure = this.procedures.find((p) => p.procedureId == procedureId);
    let infoModel = new SelectedOtherProcedureStepInfo(procedure!, step!);
    this.submitCompletedEvent.emit(infoModel);
    // this.toastService.success("Rule has been updated.");
    this.activeModal.close();
  }

  onCancel(): void {
    this.activeModal.close();
  }
}
