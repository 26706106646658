import { Component, EventEmitter, Input, OnInit, Output } from "@angular/core";

@Component({
  selector: "app-image-lightbox",
  templateUrl: "./image-lightbox.component.html",
  styleUrls: ["./image-lightbox.component.scss"],
})
export class ImageLightboxComponent implements OnInit {
  @Input() imageSource: string = "";
  @Output() closeEvent = new EventEmitter();
  constructor() {}

  ngOnInit(): void {}

  public close(): void {
    this.closeEvent.emit();
  }
}
