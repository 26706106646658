import { Location } from "@angular/common";
import { Component, Input } from "@angular/core";

@Component({
  selector: "app-page-header",
  templateUrl: "./page-header.component.html",
  styleUrls: ["./page-header.component.scss"],
})
export class PageHeaderComponent {
  @Input() title: string | undefined;
  @Input() showGoBack: boolean = false;
  constructor(private location: Location) {}
  onGoBackClicked(): void {
    this.location.back();
  }
}
