import { Pipe, PipeTransform } from "@angular/core";
import { DomSanitizer, SafeHtml } from "@angular/platform-browser";
import { from } from "rxjs";

@Pipe({ name: "sanitizeHtml" })
export class SanitizeHtmlPipe implements PipeTransform {
  constructor(private _sanitizer: DomSanitizer) {}

  transform(value: string): SafeHtml {
    if (value != null) {
      value = value.replace("<p>", "");
      value = value.replace("</p>", "");
    }

    return this._sanitizer.bypassSecurityTrustHtml(value);
  }
}
