import { Component, EventEmitter, Input, Output } from "@angular/core";
import { NgbActiveModal, NgbModal } from "@ng-bootstrap/ng-bootstrap";
import { ToastrService } from "ngx-toastr";
import {
  ProcedureRevisionService,
  ProcedureRevisionUpdateV1Model,
  ProcedureRevisionV1Model,
} from "src/app/api/ProcedureRevision.service";
import { FormRepoService } from "src/app/modules/shared/services/form-repo.service";
import { AlertModalComponent } from "../alert-modal/alert-modal.component";
import { WritingService } from "../../writing/writing.service";

@Component({
  selector: "app-edit-revision-modal",
  templateUrl: "./edit-revision-modal.component.html",
  styleUrls: ["./edit-revision-modal.component.scss"],
})
export class EditRevisionModalComponent {
  @Input() public revision!: ProcedureRevisionUpdateV1Model;
  @Output() public submitCompletedEvent = new EventEmitter();
  editRevisionForm = this.formRepoService.editRevisionForm();
  procedureRevisionId: number = 0;
  departmentOwner: string | null = null;
  departmentOwners: ProcedureRevisionV1Model[] = [];

  constructor(
    public activeModal: NgbActiveModal,
    private toastService: ToastrService,
    private procedureRevisionService: ProcedureRevisionService,
    private formRepoService: FormRepoService,
    private modalService: NgbModal,
    private writingService: WritingService
  ) {}

  ngOnInit(): void {
    this.setUpForm();
    this.loadDepartmentOwners();
  }

  loadDepartmentOwners(): void {
    this.procedureRevisionService.getAllDepartmentOwners().subscribe((data) => {
      this.departmentOwners = data;
    });
  }

  setUpForm() {
    this.editRevisionForm.controls["levelOfUse"].setValue(this.revision.levelOfUse?.toString());
    this.departmentOwner = this.revision.departmentOwner;
  }

  onSubmit(): void {
    let model = new ProcedureRevisionUpdateV1Model();
    Object.assign(model, this.revision);
    model.levelOfUse = this.editRevisionForm.controls["levelOfUse"].value;
    model.departmentOwner = this.departmentOwner;
    model.procedureRevisionId = this.revision.procedureRevisionId;

    this.procedureRevisionService.update(model, true).subscribe(
      (data) => {
        this.toastService.success("Revision updated");
        this.writingService.changeLevelOfUse(model.levelOfUse!);
        this.submitCompletedEvent.emit();
        this.activeModal.close();
      },
      (error) => {
        this.activeModal.close();
        this.openErrorModal();
      }
    );
  }

  openErrorModal(): void {
    const modalRef = this.modalService.open(AlertModalComponent, {
      centered: true,
    });
    modalRef.componentInstance.modalTitle = "Level of Use Error";
    modalRef.componentInstance.alertText =
      "This procedure contains hold codes. Please remove them before changing the level of use.";
    modalRef.componentInstance.alertColor = "danger";
  }

  onCancel(): void {
    this.activeModal.close();
  }
}
