 
   
   import { Injectable } from '@angular/core';
   import { HttpClient, HttpHeaders, HttpErrorResponse } from '@angular/common/http';

   import { Observable, of, throwError  } from 'rxjs';

   import { environment } from '../../environments/environment';
   //import { LoggerService } from '../../modules/core/logger.service'
   import { GenericSearchResultV1Model } from '../../app/models/GenericSearchResultV1Model';
   import { PageableListV1Model } from '../../app/models/PageableListV1Model';

   const httpOptions = {
     headers: new HttpHeaders({ 'Content-Type': 'application/json' }),
     withCredentials: true
   };

   const httpOptions_Handle400InComponent = {
     headers: new HttpHeaders({ 'Content-Type': 'application/json', 'I400Local': '' }),
     withCredentials: true
   }
    
   @Injectable({
     providedIn: 'root'
   })
   export class TokenService {

   
         
        private classVersion = "1";
        
      constructor(private http: HttpClient) { }
      
        
          
              echo( handle400Locally: boolean = false) : Observable<AuthSecContext>  { 
              let version = this.classVersion;
              return this.http.get<any>(environment.apiRoot + `api/v${version}/token/echo`, handle400Locally ? httpOptions_Handle400InComponent : httpOptions )       
            };
          
      
        
          
              environment( handle400Locally: boolean = false) : Observable<AuthSecContext>  { 
              let version = this.classVersion;
              return this.http.get<any>(environment.apiRoot + `api/v${version}/token/environment`, handle400Locally ? httpOptions_Handle400InComponent : httpOptions )       
            };
          
      
        
          
              expireToken( handle400Locally: boolean = false) : Observable<boolean>  { 
              let version = this.classVersion;
              return this.http.get<any>(environment.apiRoot + `api/v${version}/token/ExpireToken`, handle400Locally ? httpOptions_Handle400InComponent : httpOptions )       
            };
          
      
        
          
              refreshToken( handle400Locally: boolean = false) : Observable<AuthSecContext>  { 
              let version = this.classVersion;
              return this.http.get<any>(environment.apiRoot + `api/v${version}/token/Refresh`, handle400Locally ? httpOptions_Handle400InComponent : httpOptions )       
            };
          
      
        
          
              renewToken( handle400Locally: boolean = false) : Observable<AuthSecContextInt>  { 
              let version = this.classVersion;
              return this.http.get<any>(environment.apiRoot + `api/v${version}/token/Renew`, handle400Locally ? httpOptions_Handle400InComponent : httpOptions )       
            };
          
      

            


                  
        
          
              processToken(authParam: AuthParam,  handle400Locally: boolean = false) : Observable<AuthSecContext>  { 
              let version = this.classVersion;
              return this.http.post<any>(environment.apiRoot + `api/v${version}/token`,authParam, handle400Locally ? httpOptions_Handle400InComponent : httpOptions ) //p => p.Attributes.Count > 0 ----only adds FromBody     
            };
          
      
        
          
              setupMFA(setup: AuthMFASetup,  handle400Locally: boolean = false) : Observable<AuthSecContext>  { 
              let version = this.classVersion;
              return this.http.post<any>(environment.apiRoot + `api/v${version}/token/setup/mfa`,setup, handle400Locally ? httpOptions_Handle400InComponent : httpOptions ) //p => p.Attributes.Count > 0 ----only adds FromBody     
            };
          
      
        
          
              validateMFA(code: string,  handle400Locally: boolean = false) : Observable<AuthSecContext>  { 
              let version = this.classVersion;
              return this.http.post<any>(environment.apiRoot + `api/v${version}/token/mfa`,code, handle400Locally ? httpOptions_Handle400InComponent : httpOptions ) //p => p.Attributes.Count > 0 ----only adds FromBody     
            };
          
      

                        

                  
}

export class AuthSecContext {
    constructor(  
        // Inherited properties 
        
      
        //Properties
        
        public context: SecurityContextV1Model | null = null,   
        public isAuthenticated: boolean | null = null,   
        public token: string | null = null,   
        public licenseError: boolean | null = null,   
        public licenseErrorMessage: string | null = null,   
        public expires: Date | null = null,   
        public isExpired: boolean | null = null,   
        public mfastatus: MFAStatus | null = null,   
      ) { }
}

export class SecurityContextV1Model {
    constructor(  
        // Inherited properties 
        
      
        //Properties
        
        public user: UserSecV1Model | null = null,   
      ) { }
}

export class UserSecV1Model {
    constructor(  
        // Inherited properties 
        
      
        //Properties
        
        public currentUserId: number | null = null,   
        public currentUserIdForAudit: number | null = null,   
        public currentAccessGuid: string | null = null,   
        public name: string | null = null,   
        public email: string | null = null,   
        public permissions: APIPermissionModel[] | null = null,   
        public accountIds: number[] | null = null,   
        public accountCodes: string[] | null = null,   
        public currentAccountId: number | null = null,   
        public currentAccountCode: string | null = null,   
        public isGlobal: boolean | null = null,   
      ) { }
}

export class APIPermissionModel {
    constructor(  
        // Inherited properties 
        
      
        //Properties
        
        public apiPermissionId: number | null = null,   
        public apiPermission: string | null = null,   
      ) { }
}

export class AuthParam {
    constructor(  
        // Inherited properties 
        
      
        //Properties
        
        public clientid: string | null = null,   
        public clientsecret: string | null = null,   
        public username: string | null = null,   
        public password: string | null = null,   
        public scope: string | null = null,   
      ) { }
}

export class AuthMFASetup {
    constructor(  
        // Inherited properties 
        
      
        //Properties
        
        public secret: string | null = null,   
        public code: string | null = null,   
      ) { }
}

export class AuthSecContextInt {
    constructor(  
        // Inherited properties 
        
      
        //Properties
        
        public context: AuthSecContext | null = null,   
      ) { }
}



export enum MFAStatus
    { 
       SetupRequired = 1,
       NeedsAuthenticated = 2,
       Authenticated = 3,
    }



/* 
     Debug Info:
     PrintDebugInfo - put a dollar sign in front of Print to enable this 
*/

