import { Component } from "@angular/core";
import { AuthService } from "./modules/core/services/auth.service";
import { ActivatedRoute, NavigationEnd, Router } from "@angular/router";
import { ScreenLayout } from "./models/enums";

@Component({
  selector: "app-root",
  templateUrl: "./app.component.html",
  styleUrls: ["./app.component.scss"],
})
export class AppComponent {
  isA: boolean = false;
  screenLayout: ScreenLayout = ScreenLayout.Centered;
  constructor(private authService: AuthService, private router: Router) {}

  ngOnInit() {
    this.router.events.subscribe((event) => {
      if (event instanceof NavigationEnd) {
        this.checkIfCentered();
      }
    });

    this.isA = this.authService.isLoggedIn();
    this.authService.isLoggedIn$.subscribe((l) => {
      this.isA = l;
    });
    3;
  }

  checkIfCentered(): void {
    if (window.location.href.includes("/scheduling") || window.location.href.includes("/analytics/procedure-delays")) {
      this.screenLayout = ScreenLayout.FullScreen; // 3
    } else if (
      window.location.href.includes("/home") ||
      window.location.href.includes("/facilities") ||
      window.location.href.includes("/contact") ||
      window.location.href.includes("/login") ||
      window.location.href.includes("/notfound") ||
      window.location.href.includes("/execution") ||
      window.location.href.includes("/QRCode") ||
      window.location.href.includes("/unauthorized") ||
      window.location.href.includes("/scoping") ||
      window.location.href.includes("/analytics")
    ) {
      this.screenLayout = ScreenLayout.Centered; // 1
    } else {
      this.screenLayout = ScreenLayout.SideBar; // 2
    }
  }
}
