import { Component, EventEmitter, Input, Output } from "@angular/core";
import { FormBuilder, Validators } from "@angular/forms";
import { NgbActiveModal } from "@ng-bootstrap/ng-bootstrap";
import { ToastrService } from "ngx-toastr";
import { AccountService } from "src/app/api/Account.service";
import {
  EquipmentsInsertV1Model,
  EquipmentsService,
} from "src/app/api/Equipments.service";
import { AuthService } from "src/app/modules/core/services/auth.service";
import { FormRepoService } from "src/app/modules/shared/services/form-repo.service";

@Component({
  selector: "app-new-equipment-modal",
  templateUrl: "./new-equipment-modal.component.html",
  styleUrls: ["./new-equipment-modal.component.scss"],
})
export class NewEquipmentModalComponent {
  @Output() public submitCompletedEvent = new EventEmitter();
  @Input() isLibrary: boolean = true;
  newEquipmentForm = this.formRepoService.newEquipmentForm();
  constructor(
    private equipmentService: EquipmentsService,
    public activeModal: NgbActiveModal,
    private toastService: ToastrService,
    private formRepoService: FormRepoService
  ) {}

  ngOnInit(): void {}

  onSubmit(): void {
    let model = new EquipmentsInsertV1Model();

    model.name = this.newEquipmentForm.controls["name"].value;
    model.shortName = this.newEquipmentForm.controls["shortName"].value;
    model.idNumber = this.newEquipmentForm.controls["idNumber"].value;
    if (this.newEquipmentForm.controls["isActive"].value == "true") {
      model.isActive = true;
    } else {
      model.isActive = false;
    }

    this.equipmentService.insert(model).subscribe((data) => {
      this.toastService.success(model.name + " updated");
      this.submitCompletedEvent.emit(data.identityId);
      this.activeModal.close();
    },
    (error) => {
      
    });
  }

  onCancel(): void {
    this.activeModal.close();
  }
}
