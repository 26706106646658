import { Component, Input } from "@angular/core";
import { NgbModal } from "@ng-bootstrap/ng-bootstrap";

import { VideoPlayerComponent } from "../video-player/video-player.component";
import { environment } from "src/environments/environment";

@Component({
  selector: "app-video-preview",
  templateUrl: "./video-preview.component.html",
  styleUrls: ["./video-preview.component.scss"],
})
export class VideoPreviewComponent {
  @Input() imageSource: string = "";
  @Input() isInGrid: boolean = false;
  @Input() videoGuid: string = "";
  apiRoot: string = environment.apiRoot;

  constructor(private modalService: NgbModal) {}

  ngOnInit(): void {}

  openVideoPlayer(): void {
    const modalRef = this.modalService.open(VideoPlayerComponent, {
      centered: true,
      backdrop: true,
    });
    modalRef.componentInstance.videoSource =
      this.apiRoot + "api/v1/videos/download/" + this.videoGuid;
  }
}
